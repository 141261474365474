import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import {  star } from "../Imagesjs/Images.js";
import "./Testimonial.css";

function Testimonial(props) {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  }, [slider2,slider1]);

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    arrows: false,
    infinite: true,
    loop: true,
    fade: false,
    asNavFor: ".slider-nav",
    swipe: true,
    autoplay: true,
    speed: 500,
    dots: false,
    autoplaySpeed: 5000,
  };

  const settingsThumbs = {
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: ".slider-for",
    dots: true,
    loop: true,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: "10px",
	  infinite: true,
    speed: 500,
  };

  return (
    <>
      <div className="testimonial-reel">
        <div className="container position-relative">
          <div id="slider-overlay">
            <Slider
              {...settingsMain}
              asNavFor={nav2}
              ref={(slider) => setSlider1(slider)}
            >
              {props.slidesData.map((e, i) => (
                <div className="box slider-for" key={i}>
                  <>
                    <div className="test-component">
                      <div className="testimonial-icon text-center">
                        <Link to="/">
                          <i>
                            <img src={star} alt="star" />
                          </i>
                        </Link>
                        <Link to="/">
                          <i>
                            <img src={star} alt="star" />
                          </i>
                        </Link>
                        <Link to="/">
                          <i>
                            <img src={star} alt="star" />
                          </i>
                        </Link>
                        <Link to="/">
                          <i>
                            <img src={star} alt="star" />
                          </i>
                        </Link>
                        <Link to="/">
                          <i>
                            <img src={star} alt="star" />
                          </i>
                        </Link>
                      </div>
                      <article className="test-content">
                        <p>{e.testimonialbody}</p>
                      </article>
                    </div>
                  </>
                </div>
              ))}
            </Slider>
          </div>
          <div className="slider slider-nav thumb-image">
            <Slider
              {...settingsThumbs}
              asNavFor={nav1}
              ref={(slider) => setSlider2(slider)}
            >
              {props.slidesData.map((e, i) => (
			         <div key={i}>
                <div className="thumbnail-image">
                  <figure>
                    <img src={e.imge} alt="slider-img" className="img-fluid" />
                  </figure>
                </div>
                <div className="title-des">
                <span>
                  <h3 className="mb-0">{e.title}</h3>
                  <small>{e.designation}</small></span>
                </div>
				
				      </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
}

export default Testimonial;

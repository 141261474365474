import React from "react";
import BreadCrumb from "../BreadCrumb/BreadCrumb";
import Newsletter from "../Newsletter/Newsletter";
import "./Careers.css";
import {
  appstack,
  careersbanner,
  certifiedbanner,
  icon12,
  icon13,
  icon15,
  icon20,
  leadershipcontactbanner,
  ourfueltech,
} from "../Imagesjs/Images";
import Contact from "../Contactus/Contact";
import Services from "../Services/Services";
import { Link } from "react-router-dom";
function Careers() {
  const newstype = 3;
  const contactbanner = leadershipcontactbanner;
  const serviceblock = [
    {
      title: "Integrity",
      icon: icon12,
      ItemTitle: "Do the right thing, embrace challenge.",
      ItemDescription:
        "Smiling faces, quality fuel, and unbeatable customer service. We know what it takes to get you and your vehicle on the move.",
    },
    {
      title: "Trust",
      icon: icon13,
      ItemTitle: "Open-minded trust, radical transparency.",
      ItemDescription:
        "Our team is trustworthy and reliable, so you can count on us to get the job done right – every time.",
    },
    {
      title: "Safety",
      icon: icon15,
      ItemTitle: "Safety Fuels Every decision.",
      ItemDescription:
        "Safety is our number one priority. From our top-of-the-line equipment to our well-trained staff, we take every measure to ensure your safety.",
    },
    {
      title: "Disruptive",
      icon: icon20,
      ItemTitle: "Challenge tradition, innovate for tomorrow.",
      ItemDescription:
        "Get fueled at the ease of an app. Skip the boring, mundane tasks of the past and get more out of your life!",
    },
  ];

  const pagename = {
    name: "Careers",
  };
  return (
    <>
      <section className="inner-banner section-banner faq">
        <div className="background-image">
          <img src={careersbanner} alt="careersbanner" />
        </div>
        <div className="container position-relative">
          <div className="row align-items-lg-center align-items-end">
            <div className="col-12  text-center">
              <BreadCrumb pagename={pagename} />
            </div>
            <div className="col-12">
              <div className="home-banner-texter text-center">
                <h1 className="wow fadeInUp" data-wow-delay="0.2s">
                  <span>Careers</span>
                </h1>
                <p className="wow fadeInUp" data-wow-delay="0.3s">
                  If you're interested in joining the Gas It Up team, take a
                  look at our current job <br />
                  openings. We look forward to hearing from you!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all pb-4">
        <div className="container">
          <div className="tracking-details">
            <div className="row align-items-center g-0 flex-row-reverse">
              <div className="col-lg-6 col-12">
                <div className="heading-style text-lg-start text-center px-5 mb-0">
                  <h5
                    className="text-lg-start text-center text-capitalize wow fadeInUp"
                    data-wow-delay="0.1s"
                  >
                    Connect to{" "}
                    <span className="text-orange">
                      Applicant <br />
                      Stack
                    </span>
                  </h5>
                  <a
                    href={`${process.env.React_App_App_stacklink}`}
                    className="btn btn--primary px-5 d-inline-flex mt-4"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Read More
                  </a>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <figure>
                  <img
                    src={appstack}
                    alt="Applicant Stack"
                    className="img-fluid"
                    width="100%"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all pb-4">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-12 col-sm-12 dashboard-listing">
              <div className="row align-items-center justify-content-center listing-list">
                <div className="col-lg-6 col-12 pe-lg-5">
                  <div className="heading-style text-center wow fadeInUp pe-lg-5">
                    <h5 className="text-start text-capitalize">
                      Our Fuel <span className="text-orange">Technicians</span>
                    </h5>
                  </div>
                  <p className="pe-lg-5">
                    Our Fuel Technicians are trained, certified, and rigorously
                    follow all local, state, municipal, and international fire
                    codes. They always wear certified personal protective
                    equipment (PPE), and do not have to come into contact with
                    any individual. Each technician has been trained in working
                    safely around fuel storage tanks and pumps to ensure that
                    they are kept safe during operation.
                  </p>
                </div>
                <div className="col-lg-6 col-12 text-center mb-lg-0 mb-5 wow fadeInRight dots-purple right-circle">
                  <figure>
                    <img
                      src={ourfueltech}
                      alt="Our Fuel Technicians"
                      className="img-fluid border-20 w-100"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="leadershipbusiness">
        <Services
          serviceblock={serviceblock}
          IsHead={false}
          IsSubHead={false}
          IsSubHeadcore={true}
          IsIconHead={true}
        />
      </div>

      <section className="common-section-all bg-purple newsleter_signup mt-md-4">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 wow fadeInLeft">
              <div className="heading-style text-md-start text-center">
                <h5 className="text-md-start text-center">
                  Sign up for our newsletter
                </h5>
                <h4>Stay in the loop with everything you need to know. </h4>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 wow fadeInRight">
              <div
                className="heading-style text-md-start text-center"
                data-wow-delay="0.2s"
              >
                <p>
                  We care about your data in our
                  <span>
                    {" "}
                    <Link
                      to="/privacy-policy"
                      className="text-orange"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      privacy policy
                    </Link>
                  </span>
                </p>
                <Newsletter newstype={newstype} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="common-section-all pt-5 ">
        <div className="container">
          <div className="col-md-12 col-sm-12">
            <div className="row align-items-center justify-content-center flex-row-reverse">
              <div className="col-lg-6 col-12 ps-lg-5 ps-lg-0">
                <div className="heading-style text-center wow fadeInRight mb-0">
                  <h4
                    className="text-lg-start text-center text-white"
                    style={{ color: "#fff", fontFamily: "var(--AvenirHeavy)" }}
                  >
                    <span className="text-orange">Trained, certified,</span> and{" "}
                    <span className="text-orange">rigorously</span>
                    <br /> follow all local, state, municipal, <br />
                    and international fire codes. <br />
                    They always wear certified PPE,
                    <br /> and do not have to come into <br />
                    contact with any individual
                  </h4>
                </div>
              </div>
              <div className="col-lg-6 col-12 text-center mb-lg-0 mb-5 p-md-4 p-3 wow fadeInUp dots-orange certified-banner">
                <figure>
                  <img
                    src={certifiedbanner}
                    alt="certifiedbanner"
                    className="img-fluid border-20 w-100"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all pt-0">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 pb-0">
              <div className="heading-style text-center wow fadeInUp">
                <h5 className="text-capitalize">
                  Get into contact with a{" "}
                  <span className="text-orange">Gas It Up</span> professional
                  that
                  <br /> can get your{" "}
                  <span className="text-orange"> business</span> set up for{" "}
                  <span className="text-orange"> success</span>
                </h5>
              </div>
            </div>
            <Contact contactbanner={contactbanner} />
          </div>
        </div>
      </section>
    </>
  );
}

export default Careers;

import React from "react";
import './AdvisoryBoard.css'
function AdvisoryBoard() {
    const AdvisoryBoard= [
        {
            name: "Que Gaskins",
            designation: "President, THINK450 & Chief Brand",
            desi: "Innovation Officer, NBPA",
            IsNew: true,
        },
        {
            name: "Derrick Coleman",
            designation: "Entrepreneur, Former NBA Star, ",
            desi: "Philanthropist, Community Ambassador,",
            desi2: " Youth Advocate",
            IsNewAgain: true,
            IsNew: true,
        },
        {
            name: "Rob Heinrich",
            designation: "Chief Executive Officer, Global",
            desi: " Fueling systems",
            IsNew: true,
        },
        {
            name: "Anthony Gusumano",
            designation: "Political Consultant",
        },
        {
            name: "Thomas DiGiacomo",
            designation: "Refinery Manager, Egyptian Refining Company",
        },
        {
            name: "Loverture Jonas",
            designation: "President, Blackrock Engineering & Technology",
        },
    ]
  return (
    <>
      <section className="common-section-all teammembers py-0">
        <div className="container">
          <div className="row align-items-center">           
            <div className="col-md-12">
              <div className="heading-style text-start wow fadeInUp">
                <h4 className="text-start" style={{ paddingLeft: "10px" }}>
                    Advisory board
                </h4>
              </div>
              <div className="row advisory">
              {AdvisoryBoard.map((Advisory, i) => (
                <div className="col-md-4 clickcustomform advisoryblock wow fadeInUp" data-wow-delay={".0" + i + "s"} key={i}>
                  <div className="team h-100">
                    <div className="team-des ">
                      <h3>{Advisory.name}</h3>
                      <p>{Advisory.designation}</p>
                      {Advisory.IsNew === true ? (
                        <p>{Advisory.desi}</p>
                      ) : (
                        ""
                      )}                      
                      {Advisory.IsNewAgain === true ? (
                        <p>{Advisory.desi2}</p>
                      ) : (
                        ""
                      )}                      
                    </div>
                  </div>
                </div>
              ))}
            </div>
            </div>
           
          </div>
        </div>
      </section>
    </>
  );
}

export default AdvisoryBoard;

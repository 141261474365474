import React from "react";
import { Link } from "react-router-dom";
import Contact from "../../Contactus/Contact";
import DashboardSliderslide from "../../DashboardSliderslide/DashboardSliderslide";
import DownloadSchedule from "../../DownloadSchedule/DownloadSchedule";
import {
  arrow,
  check,
  crosspurple,
  diseltruck,
  gasstation,
  icon17,
  icon18,
  icon19,
  jobber,
  middletruck,
  nojobber,
  nostations,
  nostorage,
  ourprocesscontactbanner,
  ourprocessefficiency,
  storagetank,
  terminal,
  image6,
  image8,
  image11,
  ios0,
  ios1,
  ios3,
  ios2,
  ios4,
  ios5,
  ios6,
  ios7,
  ios8,
  ios9,
  ios10,
  ios11,
  ios12,
  Speedometerfull,
  Speedometerfullwhite,
  terminalwhite,
  storagetankwhite,
  carrierwhite,
  gasstationwhite,
  diseltruckwhite,
  nostoragewhite,
  nostationswhite,
  nojobberwhite,
} from "../../Imagesjs/Images";

import IphoneSlider from "../../IphoneSlider/IphoneSlider";
import PriceModal from "../../PriceModal/PriceModal";
import Services from "../../Services/Services";
import "./OurProcess.css";

function OurProcess(props) {
  const iphoneimage = [
    { iosimage: ios0 },
    { iosimage: ios1 },
    { iosimage: ios2 },
    { iosimage: ios3 },
    { iosimage: ios4 },
    { iosimage: ios5 },
    { iosimage: ios6 },
    { iosimage: ios7 },
    { iosimage: ios8 },
    { iosimage: ios9 },
    { iosimage: ios10 },
    { iosimage: ios11 },
    { iosimage: ios12 },
  ];

  const contactbanner = ourprocesscontactbanner;
  const type = 2;
  const pricingbox = [
    {
      IsPerMonth: false,
      IsHover: true,
      title: "Non-Subscription",
      subhead: "One Time Delivery Fee of",
      price: "$4.99",
      boxlistng: [
        { list: "One registered vehicle" },
        { list: "$1.99 tire pressure check" },
        { list: "One Time Delivery Fee of $4.99" },
      ],
    },
    {
      IsPerMonth: true,
      IsHover: true,
      title: "Silver",
      subhead: "Most Popular",
      price: "$9.99",
      boxlistng: [
        { list: "Unlimited fuel deliveries" },
        { list: "One registered vehicle" },
      ],
    },
    {
      IsPerMonth: true,
      IsHover: true,
      title: "Gold",
      subhead: "Recommended",
      price: "$14.99",
      boxlistng: [
        { list: "Unlimited fuel deliveries" },
        { list: "Two registered vehicle" },
        { list: "Tire pressure check" },
      ],
    },
    {
      IsPerMonth: true,
      IsHover: true,
      title: "Platinum",
      subhead: "Best Value",
      price: "$19.99",
      boxlistng: [
        { list: "Unlimited fuel deliveries" },
        { list: "Three registered vehicle" },
        { list: "Tire pressure check" },
      ],
    },
  ];
  const serviceblock = [
    {
      icon: icon17,
      ItemTitle: "City & Fire Marshal Approved",
      ItemDescription:
        "We work with local, state, and federal regulators to meet and exceed all requirements for mobile fueling in our active markets.",
    },
    {
      icon: icon18,
      ItemTitle: "Safety is Our Top Priority",
      ItemDescription:
        "Our entire operation is contactless. Users can stay safe, keep their hands clean, and fill up their tank with the touch of a button.",
    },
    {
      icon: icon19,
      ItemTitle: "Here to Take Care of You",
      ItemDescription:
        "We accept all major fuel cards and bring the ultimate fueling experience to you!",
    },
  ];
  const downLoadPros = {
    downloadlist: [],
    IsRewrite: true,
  };
  const deselDefListingefficien = [
    {
      list: "Diesel/ DEF",
      linkurl: "diesel-on-demand",
    },
    {
      list: "Gasoline",
      linkurl: "gasoline",
    },
    {
      list: "Renewable Energy",
      linkurl: "diesel-on-demand",
    },
  ];
  const beyondpump = [
    "Fuel Management Dashboard",
    "Real-Time Fueling Reports",
    "Financial Savings Insight",
    "Carbon Emissions Savings Tracker",
  ];
  const DashboardSliderslid = [
    { image: image11 },
    { image: image6 },
    { image: image8 },
  ];
  return (
    <>
      <section className="inner-banner section-banner geofencing ourprocess pt-0">
        <div className="container h-100">
          <div className="row align-items-center py-0">
            <div className="col-md-6">
              <div className="home-banner-texter text-md-start text-center">
                <h1 className="wow fadeInUp" data-wow-delay="0.2s">
                  <span
                    className="wow fadeInUp text-white"
                    data-wow-delay="0.4s"
                  >
                    Fuel At Your
                  </span>{" "}
                  <br />
                  <span
                    className="wow fadeInUp text-orange"
                    data-wow-delay="0.4s"
                  >
                    Fingertips
                  </span>
                </h1>
                <p className="pt-2">
                  Our process allows you to easily register your <br />
                  vehicle(s), select a time or recurring schedule for your{" "}
                  <br />
                  desired fueling, and pay for fuel. This seamless process{" "}
                  <br />
                  helps individuals and fleets save time, save money, <br />
                  and stay safe by bringing the gas pump to them.
                </p>
              </div>
            </div>
            <div className="col-md-6 position-relative">
              <DashboardSliderslide DashboardSliderslid={DashboardSliderslid} />
              <div className="iphone-tab">
                <IphoneSlider iphoneimage={iphoneimage} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="common-section-all pb-4">
        <div className="container">
          <div className="col-md-12 procesupply">
            <div className="heading-style text-center mb-0">
              <h5 className="wow fadeInUp mb-lg-5 mb-3" data-wow-delay="0.2s" style={{'textTransform':'none'}}>
                Traditional vs.<span className="text-orange"> Gas It Up</span>
              </h5>
              <h4 className="position-relative" style={{ zIndex: "9" }}>
                <span className="d-inline-block px-4">
                  Traditional Supply Chain
                </span>
              </h4>
            </div>
            <div className="">
              <ul className="d-flex align-items-center justify-content-between arrowblock">
                <li>
                  <div className="wow fadeIn" data-wow-delay="0.1s">
                    <i>
                      <img
                        src={props.theme === "dark" ? terminal : terminalwhite}
                        alt="terminal"
                        className="img-fluid"
                        width={100}
                        height={100}
                      />
                    </i>
                    <br />
                    <span>Terminal</span>
                  </div>
                </li>
                <li>
                  <div className="wow fadeIn" data-wow-delay="0.2s">
                    <i>
                      <img
                        src={props.theme === "dark" ? jobber : carrierwhite}
                        alt="Carrier"
                        className="img-fluid"
                        width={100}
                        height={100}
                      />
                    </i>
                    <br />
                    <span>Carrier</span>
                  </div>
                </li>
                <li>
                  <div className="wow fadeIn" data-wow-delay="0.3s">
                    <i>
                      <img
                        src={
                          props.theme === "dark"
                            ? storagetank
                            : storagetankwhite
                        }
                        alt="storagetank"
                        className="img-fluid"
                        width={100}
                        height={100}
                      />
                    </i>
                    <br />
                    <span>
                      Underground <br />
                      Storage Tank
                    </span>
                  </div>
                </li>
                <li>
                  <div className="wow fadeIn" data-wow-delay="0.4s">
                    <i>
                      <img
                        src={
                          props.theme === "dark" ? gasstation : gasstationwhite
                        }
                        alt="gasstation"
                        className="img-fluid"
                        width={100}
                        height={100}
                      />
                    </i>
                    <br />
                    <span>Gas Station</span>
                  </div>
                </li>
                <li>
                  <div className="wow fadeIn" data-wow-delay="0.5s">
                    <i>
                      <img
                        src={
                          props.theme === "dark" ? diseltruck : diseltruckwhite
                        }
                        alt="diseltruck"
                        className="img-fluid"
                        width={100}
                        height={100}
                      />
                    </i>
                    <br />
                    <span>
                      Diesel <br />
                      Truck / Car
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-12 listing-list">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <ul>
                  <li className="wow fadeIn" data-wow-delay="0.1s">
                    <i className="me-lg-3 me-2">
                      <img src={crosspurple} alt="crosspurple" width={30} height={30} />
                    </i>
                    Vapor Emissions
                  </li>
                  <li className="wow fadeIn" data-wow-delay="0.2s">
                    <i className="me-lg-3 me-2">
                      <img src={crosspurple} alt="crosspurple" width={30} height={30} />
                    </i>
                    Water Contamination & Land Contamination
                  </li>
                </ul>
              </div>
              <div className="col-lg-6">
                <ul>
                  <li className="wow fadeIn" data-wow-delay="0.3s">
                    <i className="me-lg-3 me-2">
                      <img src={crosspurple} alt="crosspurple" width={30} height={30} />
                    </i>
                    Vapor Emissions Hazard Risk
                  </li>
                  <li className="wow fadeIn" data-wow-delay="0.4s">
                    <i className="me-lg-3 me-2">
                      <img src={crosspurple} alt="crosspurple" width={30} height={30} />
                    </i>
                    Fire Risk - Nozzle Break Away Potential Vapor Emissions
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all pt-5 pb-0">
        <div className="container reinvented-fuel">
          <div className="col-md-12">
            <div className="heading-style text-center">
              <h5
                className="wow fadeInUp text-capitalize"
                data-wow-delay="0.2s"
              >
                We Reinvented A Cleaner Energy Delivery Service
              </h5>
            </div>
          </div>
          <div className="col-md-12 supplychain">
            <ul className="row g-0">
              <li className="text-center">
                <div className="mainimg wow fadeIn" data-wow-delay="0.1s">
                  <h3>1. Terminal</h3>
                  <div className="main-img">
                    <img
                      src={props.theme === "dark" ? terminal : terminalwhite}
                      className="img-fluid"
                      alt="terminal"
                    />
                  </div>
                </div>
                <div className="subcontent">
                  <div className="sub-img">
                    <img
                      width="106"
                      height="106"
                      src={props.theme === "dark" ? nojobber : nojobberwhite}
                      className="img-fluid"
                      alt="No Carriers"
                    />
                  </div>
                  <span className="d-block h3">No Carriers</span>
                  <p>
                    Reduces the need for
                    <br /> 18 wheelers and
                    <br /> the emissions they cause
                  </p>
                </div>
              </li>
              <li className="text-center">
                <div className="mainimg wow fadeIn" data-wow-delay="0.2s">
                  <h3>2. Gas It Up</h3>
                  <div className="main-img">
                    <img
                      src={middletruck}
                      className="img-fluid"
                      alt="middletruck"
                    />
                  </div>
                </div>
                <div className="subcontent">
                  <div className="sub-img">
                    <img
                      src={props.theme === "dark" ? nostations : nostoragewhite}
                      className="img-fluid"
                      width="106"
                      height="106"
                      alt="nostations"
                    />
                  </div>

                  <span className="d-block h3">No Storage</span>
                  <p>
                    Eliminates any risk of leakage <br />
                    into water and soil
                  </p>
                </div>
              </li>
              <li className="text-center">
                <div className="mainimg wow fadeIn" data-wow-delay="0.3s">
                  <h3>3. customer</h3>
                  <div className="main-img">
                    <img
                      src={
                        props.theme === "dark" ? diseltruck : diseltruckwhite
                      }
                      className="img-fluid"
                      width="106"
                      height="106"
                      alt="diseltruck"
                    />
                  </div>
                </div>
                <div className="subcontent">
                  <div className="sub-img">
                    <img
                      src={props.theme === "dark" ? nostorage : nostationswhite}
                      className="img-fluid"
                      width="106"
                      height="106"
                      alt="nostorage"
                    />
                  </div>
                  <span className="d-block h3">No Stations</span>
                  <p>
                    Ends all miles and costs <br />
                    associated with getting
                    <br /> to and from gas stations
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="common-section-all pt-5 pb-0">
        <div className="container reinvented-fuel">
          <div className="col-md-12">
            <div className="heading-style text-center">
              <h5
                className="wow fadeInUp text-capitalize text-orange"
                data-wow-delay="0.2s"
              >
                Gas It Up On-Boarding Process
              </h5>
            </div>
          </div>
          <div className="text-center position-relative speedometer">
            <figure className="pt-3 ">
              <img
                src={
                  props.theme === "dark"
                    ? Speedometerfull
                    : Speedometerfullwhite
                }
                alt="Speedometer"
                className="img-fluid"
              />
            </figure>
          </div>
        </div>
      </section>
      <div className="downloadschedule py-0 ourproceschedule">
        <DownloadSchedule downLoadPros={downLoadPros} />
      </div>
      <section className="common-section-all efficient-delivery pt-0">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-12 col-sm-12 dashboard-listing">
              <div className="row align-items-center justify-content-center listing-list flex-row-reverse">
                <div className="col-lg-6 col-12 ps-lg-5">
                  <div className="heading-style wow fadeInUp ps-lg-5 mb-lg-0 pt-lg-3">
                    <h5 className=" text-start mb-5">
                      The Ultimate Fueling
                      <br />
                      <span className="text-orange">Solution</span> brought
                      directly
                      <br /> to your
                      <span className="text-orange"> vehicle or fleet.</span>
                    </h5>
                    <ul className="mb-lg-0 mb-5">
                      {deselDefListingefficien.map((deselDefListin, i) => (
                        <li
                          className="wow fadeInRight p-0"
                          data-wow-delay={`0.` + i + `s`}
                          key={i}
                        >
                          <Link
                            to={"/" + deselDefListin.linkurl}
                            className="d-flex align-items-center justify-content-between w-100"
                          >
                            <div className="d-flex align-items-center">
                              <i className="me-lg-3 me-2 white_check">
                                <img src={check} alt="check" />
                              </i>
                              {deselDefListin.list}
                            </div>
                            <i className="me-lg-3 me-2">
                              <img
                                src={arrow}
                                alt="arrow"
                                height="15px"
                                style={{ objectFit: "contain" }}
                              />
                            </i>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6 col-12 text-center mb-lg-0 mb-5 wow fadeInLeft dots-orange certified-banner">
                  <figure>
                    <img
                      src={ourprocessefficiency}
                      alt="our process efficiency"
                      className="img-fluid border-20 w-100"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all py-0 pt-lg-5">
        <div className="container">
          <div className="row align-items-start justify-content-center">
            <div className="col-md-12 col-sm-12 dashboard-listing">
              <div className="heading-style text-center wow fadeInUp">
                <h5 className="">
                  GIU <span className="text-orange">Fuel Management</span>
                </h5>
              </div>
              <div className="row align-items-start justify-content-center listing-list">
                <div className="col-lg-6 col-12">
                  <ul className="pt-lg-5 mb-lg-0 mb-5 mt-lg-4">
                    {beyondpump.map((beyondpumpList, i) => (
                      <li
                        className="wow fadeInLeft"
                        data-wow-delay={`0.` + i + `s`}
                        key={i}
                      >
                        <i className="me-lg-3 me-2">
                          <img src={check} alt="check" />
                        </i>
                        {beyondpumpList}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col-lg-6 col-12 text-center mb-lg-0 mb-5 wow fadeInRight">
                  <DashboardSliderslide
                    DashboardSliderslid={DashboardSliderslid}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Services
        serviceblock={serviceblock}
        IsSubHead={false}
        IsHead={true}
        IsHeadOurprocess={true}
      />
      <PriceModal box={pricingbox} />
      <section className="common-section-all pt-0">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 pb-0">
              <div className="heading-style text-center wow fadeInUp">
                <h5 className="text-capitalize">
                  contact a <span className="text-orange"> Gas It Up</span>{" "}
                  professional
                  <br />
                </h5>
              </div>
            </div>
            <Contact
              contactbanner={contactbanner}
              type={type}
              showBusinessFields={false}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default OurProcess;

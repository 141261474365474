import React from "react";
import CertifiedSupplierIcons from "../CertifiedSupplierIcons/CertifiedSupplierIcons";
import "./CertifiedSupplier.css";

function CertifiedSupplier(props) {
  return (
    <>
      <section className="common-section-all pt-4 pb-md-4 mb-lg-3">
        <div className="container">
          {props.certifiedblock.map((certifuel, i) => (
            <div className="col-md-12 col-sm-12 dashboard-listing" key={i}>
              <div className="row align-items-center justify-content-center flex-row-reverse">
                <div className="col-lg-6 col-12 px-lg-5 px-lg-0">
                  <div className="heading-style text-center wow fadeInUp mb-0">
                    <h5 className="text-lg-start text-center">
                      {certifuel.title} {certifuel.isNewline ? <br /> : ""}
                      <span className="text-orange">
                        {certifuel.titlesub ? certifuel.titlesub : ""}{" "}
                      </span>
                      {""}
                    </h5>
                  </div>
                  <ul className="mt-lg-4 mt-3">
                    <li className="wow fadeInUp" data-wow-delay="0.1s">
                      {certifuel.list1}
                    </li>
                    <li className="wow fadeInUp" data-wow-delay="0.2s">
                      {certifuel.list2}
                    </li>
                    <li className="wow fadeInUp" data-wow-delay="0.3s">
                      {certifuel.list3 ? certifuel.list3 : ""}
                    </li>
                  </ul>
                  {certifuel.isLink ? (
                    <a
                      href={certifuel.islinkaddress}
                      className="btn btn--primary px-4 d-inline-flex mt-4"
                    >
                      <span>{certifuel.link}</span>
                    </a>
                  ) : (
                    ""
                  )}
                  <div className="d-flex align-items-center justify-content-lg-start justify-content-center certified-icon mt-lg-4 mt-3">
                    <CertifiedSupplierIcons
                      CertifieIcons={props.CertifieIcons}
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-12 text-center mb-lg-0 mb-5 p-md-4 p-3 wow fadeInUp dots-orange certified-banner">
                  <figure>
                    <img
                      src={certifuel.banner}
                      alt="certifuel banner"
                      className="img-fluid border-20 w-100"
                    />
                  </figure>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
}

export default CertifiedSupplier;

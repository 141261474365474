import React, { useEffect, useState } from "react";
import BreadCrumb from "../BreadCrumb/BreadCrumb";
import Contact from "../Contactus/Contact";
import axios from "axios";
import moment from "moment";
import {
  blog1,
  blog2,
  calendar,
  careersbanner,
  leadershipcontactbanner,
  moblogo,
} from "../Imagesjs/Images";
import Sidebar from "../Sidebar/Sidebar";
import "./BlogDetail.css";
import BlogComments from "../BlogComments/BlogComments";
function BlogDetail(props) {
  const [data, setData] = useState([]);
  const [img, setimg] = useState("");
  var id = props.location.pathname.split("/")[2];
  const pagename = {
    name: "Blog",
  };
  const contactbanner = leadershipcontactbanner;

  const Newlist = [
    {
      banner: blog1,
      title: "GIU Conference",
      postedby: "Admin",
      Des: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry...",
      linktitle: "Read More",
    },
    {
      banner: blog2,
      title: "GIU Press Meet",
      postedby: "Admin",
      Des: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry...",
      linktitle: "Read More",
    },
    {
      banner: blog2,
      title: "GIU Conference",
      postedby: "Admin",
      Des: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry...",
      linktitle: "Read More",
    },
    {
      banner: blog1,
      title: "GIU Conference",
      postedby: "Admin",
      Des: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry...",
      linktitle: "Read More",
    },
  ];

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_base_KEY}/getData2?flag=4&isPublished=true&isArticle=false&blogId=${id}`
      )
      .then((res) => {
        setData(res.data.data.data[0]);
        setimg(res.data.data.data[0].imageUrl);
      })
      .catch((err) => { });
  }, [id]);

  return (
    <>
      <section className="inner-banner section-banner faq">
        <div className="background-image">
          <img src={careersbanner} alt="careersbanner" />
        </div>
        <div className="container position-relative">
          <div className="row align-items-lg-center align-items-end">
            <div className="col-12  text-center">
              <BreadCrumb pagename={pagename} />
            </div>
            <div className="col-12">
              <div className="home-banner-texter text-center">
                <h1 className="wow fadeInUp" data-wow-delay="0.2s">
                  <span>Blog</span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all blog-details tabing-list pb-0">
        <div className="container">
          <div className="row align-items-start justify-content-center">
            <div className="col-md-8">
              <div className="row align-items-center justify-content-center  blogdetail">
                <div className="blogblock col-md-12 mb-5" data-wow-delay="0.2s">
                  <h4 className="mt-0">{data?.title}</h4>
                  <div className="meta-details mb-3">
                    <div className="d-flex align-items-center justify-content-start">
                      <div className="posted-details text-left me-4 d-flex align-items-center justify-content-start">
                        <i>
                          <img src={calendar} alt="calendar" />
                        </i>
                        <span>
                          {moment(data?.createdOn).format("DD/MM/YYYY")}
                        </span>
                      </div>
                      <div className="admin-details">
                        <i>
                          <img src={moblogo} alt="client" />
                        </i>
                        <span>
                          Posted By:
                          <span className="text-orange"> Admin</span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <figure>
                    <img
                      src={img?.original}
                      alt="blog banner"
                      className="img-fluid w-100"
                    />
                  </figure>
                  <div dangerouslySetInnerHTML={{ __html: data.description }} />
                </div>
              </div>
              <BlogComments getid={id} />
            </div>
            <Sidebar News={Newlist} />
          </div>
        </div>
      </section>
      <section className="common-section-all">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 pb-0">
              <div className="heading-style text-center wow fadeInUp">
                <h5 className="text-capitalize">
                  Get into contact with a{" "}
                  <span className="text-orange">Gas It Up</span> professional
                  that
                  <br /> can get your{" "}
                  <span className="text-orange"> business</span> set up for{" "}
                  <span className="text-orange">success</span>
                </h5>
              </div>
            </div>
            <Contact
              type={2}
              showBusinessFields={false}
              contactbanner={contactbanner}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default BlogDetail;

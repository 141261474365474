import React, { useState } from "react";
import Slider from "react-slick";
import {
  cuiappbanner1,
  cuiappbanner2,
  cuiappbanner3,
  cuiappbanner4,
  cuiappicon1,
  cuiappicon2,
  cuiappicon3,
  cuiappicon4,
  cuislideicon1,
  cuislideicon10,
  cuislideicon11,
  cuislideicon12,
  cuislideicon13,
  cuislideicon2,
  cuislideicon3,
  cuislideicon4,
  cuislideicon6,
  cuislideicon7,
  cuislideicon8,
  cuislideicon9,
} from "../Imagesjs/Images";
import "./CUITabing.css";

function CUITabing() {
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  const CUIdetail = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    loop: true,
    fade: true,
    asNavFor: ".slider-thumb",
    swipe: true,
    autoplay: false,
    speed: 500,
    dots: false,
    arrows: true,
    autoplaySpeed: 5000,
    responsive: [     
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
    ],
  };

  const CUItabing = {
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: ".slider-content",
    dots: true,
    loop: true,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: "10px",
    infinite: true,
    speed: 500,
    responsive: [     
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
    ],
  };

  return (
    <section className="common-section-all pt-0 pb-0 bggrey">
      <div className="cuitab pb-5">
        <div className="container">
          <Slider
            className="slider slider-thumb"
            {...CUItabing}
            asNavFor={slider2}
            ref={(slider) => setSlider1(slider)}
          >
            <div className="tabicon">
              <figcaption>
                <i>
                  <img src={cuiappicon1} alt="CIU APP" width={50} height={50}/>
                </i>
                <span>CIU APP</span>
              </figcaption>
            </div>
            <div className="tabicon">
              <figcaption>
                <i>
                  <img src={cuiappicon2} alt="PUBLIC / BUSINESS"  width={50} height={50}/>
                </i>
                <span>
                  PUBLIC / <br />
                  BUSINESS
                </span>
              </figcaption>
            </div>
            <div className="tabicon">
              <figcaption>
                <i>
                  <img src={cuiappicon3} alt="Home" width={50} height={50} />
                </i>
                <span>Home</span>
              </figcaption>
            </div>
            <div className="tabicon">
              <figcaption>
                <i>
                  <img src={cuiappicon4} alt="Robotic Charging" width={60} height={60} />
                </i>
                <span>
                  Robotic <br /> Charging
                </span>
              </figcaption>
            </div>
          </Slider>
          <Slider
            className="slider slider-content"
            {...CUIdetail}
            asNavFor={slider1}
            ref={(slider) => setSlider2(slider)}
          >
            <div>
              <div className="row align-items-center slideinfo">
                <div className="col-lg-6 col-12 ">
                  <div className="heading-style text-lg-start text-center wow fadeInUp mb-lg-0 mb-3 pe-lg-5">
                    <h5 className="text-lg-start text-center">
                      Charge It Up App <br />
                      <span className="text-green">Energy to do more</span>
                    </h5>
                    <p>
                      Using the CIU app, you will be able to locate, pay, and
                      charge at ANY public charging stations across the country,
                      regardless of the brand or manufacturer.
                    </p>
                    <p>
                      Our technology allows EV drivers to find stations with
                      real-time availability, filter the type of charger,
                      connector type or power, and even reserve a charging
                      station for a limited time.
                    </p>
                    <p>
                      Users can safely monitor their charge activity, discover
                      transparent pricing, and find the cheapest charging times
                      throughout the day, all managed directly through the CIU
                      app.
                    </p>
                    <p>
                      Finding public charging for your vehicle has never been
                      easier.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-12 text-center mb-lg-0 mb-5 wow fadeInLeft mt-3 right-circle">
                  <figure>
                    <img
                      src={cuiappbanner2}
                      alt="cuiapp banner"
                      className="img-fluid border-20 w-100"
                      width={50} height={50}
                    />
                  </figure>
                </div>
              </div>
              <div className="col-lg-12 col-12  mt-5">
                <div className="row align-items-start justify-content-center">
                  <div className="col-xl-2 col-md-6 col-12 slidercontenticon  position-relative">
                    <figcaption>
                      <i>
                        <img
                          src={cuislideicon6}
                          alt="Locate Any Charging Station That Works With Your Make
                        And Model"
                        />
                      </i>
                      <span>
                        Locate Any Charging Station That Works With Your Make
                        And Model
                      </span>
                    </figcaption>
                  </div>
                  <div className="col-xl-2 col-md-6 col-12 slidercontenticon  position-relative">
                    <figcaption>
                      <i>
                        <img
                          src={cuislideicon7}
                          alt="Real Time Ability And Off-Peak Pricing & savings"
                        />
                      </i>
                      <span>
                        Real Time Ability And Off-Peak Pricing & savings
                      </span>
                    </figcaption>
                  </div>
                  <div className="col-xl-2 col-md-6 col-12 slidercontenticon position-relative">
                    <figcaption>
                      <i>
                        <img
                          src={cuislideicon2}
                          alt="Smart Monitoring & Transparent Invoicing"
                        />
                      </i>
                      <span>Smart Monitoring & Transparent Invoicing</span>
                    </figcaption>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="row align-items-center slideinfo">
                <div className="col-lg-6 col-12 ">
                  <div className="heading-style text-lg-start text-center wow fadeInUp mb-lg-0 mb-3 pe-lg-5">
                    <h6>CIU SMART STATIONS FOR PUBLIC / BUSINESS</h6>
                    <h5 className="text-lg-start text-center">
                      A <span className="text-green">New Destination </span> For
                      EV Drivers
                    </h5>
                    <p>
                      Charge It Up's turnkey ecosystem can provide car
                      manufacturers, rental companies, businesses, fleets, and
                      autonomous vehicles provide complete charging solutions
                      for their EVs. Supplying the hardware, software support,
                      and a business panel with a range of services that can
                      plug directly into your brand experience to drive optimum
                      productivity.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-12 text-center mb-lg-0 mb-5 wow fadeInRight mt-3 right-circle">
                  <figure>
                    <img
                      src={cuiappbanner1}
                      alt="cuiapp banner"
                      className="img-fluid border-20 w-100"
                    />
                  </figure>
                </div>
              </div>
              <div className="col-lg-12 col-12 mt-5 ">
                <div className="row align-items-start justify-content-center">
                  <div className="col-xl-2 col-md-6 col-12 slidercontenticon position-relative">
                    <figcaption>
                      <i>
                        <img src={cuislideicon1} alt="turkey Solution" />
                      </i>
                      <span>
                        turnkey Solution for <br />
                        EV Hardware & <br />
                        installation
                      </span>
                    </figcaption>
                  </div>
                  <div className="col-xl-2 col-md-6 col-12 slidercontenticon position-relative">
                    <figcaption>
                      <i>
                        <img src={cuislideicon2} alt="CIU Smart" />
                      </i>
                      <span>
                        CIU Smart <br />
                        software
                      </span>
                    </figcaption>
                  </div>
                  <div className="col-xl-2 col-md-6 col-12 slidercontenticon position-relative">
                    <figcaption>
                      <i>
                        <img
                          src={cuislideicon3}
                          alt="Customizable Business Panel"
                        />
                      </i>
                      <span>
                        Customizable Business <br />
                        Panel With 24/7 <br />
                        support
                      </span>
                    </figcaption>
                  </div>
                  <div className="col-xl-2 col-md-6 col-12 slidercontenticon position-relative">
                    <figcaption>
                      <i>
                        <img
                          src={cuislideicon4}
                          alt="Autonomous & Mobility Services"
                        />
                      </i>
                      <span>
                        Autonomous & <br />
                        Mobility Services <br />
                        for all EV Needs
                      </span>
                    </figcaption>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="row align-items-center slideinfo">
                <div className="col-lg-6 col-12 ">
                  <div className="heading-style text-lg-start text-center wow fadeInUp mb-lg-0 mb-3 pe-lg-5">
                    <h6>CIU SMART STATIONS FOR HOME</h6>
                    <h5 className="text-lg-start text-center">
                      More
                      <span className="text-green"> Than A full Charge</span>
                    </h5>
                    <p>
                      We know a thing or two about making it easy to refuel your
                      gasoline vehicle, and we want to bring the same
                      ease-of-use to the EV landscape. Installing our home
                      station charger provides the ultimate level of convenience
                      and efficiency for charging your vehicle.
                    </p>
                    <p>
                      Designed with our users in mind, our home stations are
                      sleek, modern, safe, and weatherproof so that they can
                      look great in your driveway and function even better. A
                      range of customization options and smart abilities allows
                      you to install the charger that fits your needs and
                      lifestyle.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-12 text-center mb-lg-0 mb-5 wow fadeInLeft mt-3 right-circle">
                  <figure>
                    <img
                      src={cuiappbanner3}
                      alt="cuiapp banner"
                      className="img-fluid border-20 w-100"
                    />
                  </figure>
                </div>
              </div>
              <div className="col-lg-12 col-12  mt-5">
                <div className="row align-items-start justify-content-center">
                  <div className="col-xl-2 col-md-6 col-12 slidercontenticon position-relative">
                    <figcaption>
                      <i>
                        <img src={cuislideicon8} alt="Reliable & Easy" width={70} height={70}/>
                      </i>
                      <span>Reliable & Easy</span>
                    </figcaption>
                  </div>
                  <div className="col-xl-2 col-md-6 col-12 slidercontenticon position-relative">
                    <figcaption>
                      <i>
                        <img
                          src={cuislideicon9}
                          alt="Schedule Efficient Off-Peak Charging"
                          width={70} height={70}
                        />
                      </i>
                      <span>
                        Schedule Efficient <br />
                        Off-Peak Charging
                      </span>
                    </figcaption>
                  </div>
                  <div className="col-xl-2 col-md-6 col-12 slidercontenticon position-relative">
                    <figcaption>
                      <i>
                        <img
                          src={cuislideicon10}
                          alt="Fast Charging Technology"
                          width={70} height={70}
                        />
                      </i>
                      <span>
                        Fast Charging <br /> Technology
                      </span>
                    </figcaption>
                  </div>
                  <div className="col-xl-2 col-md-6 col-12 slidercontenticon position-relative">
                    <figcaption>
                      <i>
                        <img src={cuislideicon11} alt="Safe & Cost-Effective"  width={70} height={70}/>
                      </i>
                      <span>Safe & Cost-Effective</span>
                    </figcaption>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="row align-items-center slideinfo">
                <div className="col-lg-6 col-12 ">
                  <div className="heading-style text-lg-start text-center wow fadeInUp mb-lg-0 mb-3 pe-lg-5">
                    <h6>CIU SMART ROBOTIC CHARGING</h6>
                    <h5 className="text-lg-start text-center">
                      Keep Your Business
                      <br />
                      <span className="text-green"> Moving Forward</span>
                    </h5>
                    <p>
                      Charge It Up’s autonomous robotic technology is a
                      flexible, cost-effective charging solution that is raising
                      the bar. This platform allows for mobile charging to take
                      place without the need of designated charging parking or
                      human interaction.
                    </p>
                    <p>
                      The smart robotic charging stations will be well-equipped
                      with cameras, safety sensors, and other state-of-the-art
                      technology. They are the perfect EV answer for autonomous
                      vehicles, parking lots without current EV infrastructure,
                      or lots that need to scale up their EV offerings
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-12 text-center mb-lg-0 mb-5 wow fadeInLeft mt-3 right-circle">
                  <figure>
                    <img
                      src={cuiappbanner4}
                      alt="cuiapp banner "
                      className="img-fluid border-20 w-100"
                    />
                  </figure>
                </div>
              </div>
              <div className="col-lg-12 col-12  mt-5">
                <div className="row align-items-start justify-content-center">
                  <div className="col-xl-2 col-md-6 col-12 slidercontenticon position-relative">
                    <figcaption>
                      <i>
                        <img src={cuislideicon12} alt="Reliable & Easy"  width={70} height={70}/>
                      </i>
                      <span>Reliable & Easy</span>
                    </figcaption>
                  </div>
                  <div className="col-xl-2 col-md-6 col-12 slidercontenticon position-relative">
                    <figcaption>
                      <i>
                        <img
                          src={cuislideicon9}
                          alt="Schedule Efficient Off-Peak Charging"
                          width={70} height={70}
                        />
                      </i>
                      <span>
                        Schedule Efficient <br /> Off-Peak Charging
                      </span>
                    </figcaption>
                  </div>
                  <div className="col-xl-2 col-md-6 col-12 slidercontenticon position-relative">
                    <figcaption>
                      <i>
                        <img
                          src={cuislideicon13}
                          alt="Fast Charging Technology"
                          width={70} height={70}
                        />
                      </i>
                      <span>
                        Fast Charging <br /> Technology
                      </span>
                    </figcaption>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </section>
  );
}

export default CUITabing;

import React from "react";
import Slider from "react-slick";
import {bannermacbookimg } from "../Imagesjs/Images";
import './DashboardSliderslide.css';

function DashboardSliderslide(props) {

    const setting = {
        draggable: true,
        autoplay: true,
        autoplaySpeed: 4000,
        arrows: false,
        dots: false,
        fade: true,
        speed: 500,
        infinite: true,
      };
  return (
    <>
      <div className="img-frame position-relative macbook">
        <i>
          <img src={bannermacbookimg} alt="macbook" className="img-fluid" width="100%" height="100%"/>
        </i>
        <Slider className="image-slide" {...setting}>
          {props.DashboardSliderslid.map((slide, i) => (
            <div className="clickcustomform wow  animated" key={i}>
              <img src={slide.image} alt="thumb" className="img-fluid" width="100%" height="100%"/>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
}

export default DashboardSliderslide;

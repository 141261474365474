import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import CertifiedSupplier from "../../CertifiedSupplier/CertifiedSupplier";
import Contact from "../../Contactus/Contact";
import {
  api,
  changefuel,
  changefuelingcontactbanner,
  chargeItUpiconwhite,
  chargeituplogo,
  check,
  energy,
  energyinnovation,
  foundationbanner,
  foundationbanner1,
  foundationbanner2,
  foundationlogo,
  licensing,
} from "../../Imagesjs/Images";
import Partners from "../../Partners/Partners";
import "./FuelingTheFuel.css";

function FuelingTheFuel(props) {
  const foundbanimag = {
    dots: false,
    centerMode: false,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    arrows: true,
  };
  const foundban = [
    {
      img: foundationbanner,
    },
    {
      img: foundationbanner1,
    },
    {
      img: foundationbanner2,
    },
  ];
  const type = 4;
  const contactbanner = changefuelingcontactbanner;
  const FoundationList = [
    "STEM-focused college path preparation and career aspirations and readiness",
    "Interning in a STEM related field",
    "Peer and adult networks",
    "Entrance to and persistence in STEM major & career",
    "Sense of belonging and connection to STEM community",
    "Home ownership identity",
  ];

  const certifiedblock = [
    {
      banner: energyinnovation,
      isNewline: true,

      title: "Changing Communities for the Better ",
      titlesub: "Coming in 2024",
    },
  ];

  const CertifieIcons = [
    {
      IsHeading: true,
      icon1: energy,
      title: "Renewable energy",
      width: "50px",
      height: "50px"
    },
    {
      IsHeading: true,
      icon1: api,
      title: "API Licensing",
      width: "50px",
      height: "50px"
    },
    {
      IsHeading: true,
      icon1: licensing,
      title: "GIU Fuel Management Licensing",
      width: "50px",
      height: "50px"
    },
  ];

  return (
    <>
      <section className="inner-banner section-banner changefueling">
        <div className="background-image">
          <img src={changefuel} alt="change fuel" />
        </div>
        <div className="container position-relative">
          <div className="row align-items-lg-center align-items-end">
            <div className="col-12">
              <div className="home-banner-texter text-center">
                <h1>
                  <span
                    className="wow fadeInUp text-white"
                    data-wow-delay="0.2s"
                  >
                    Changing{" "}
                  </span>
                  <br />
                  <span
                    className="wow fadeInUp text-white"
                    data-wow-delay="0.4s"
                  >
                    the Future{" "}
                  </span>
                  <br />
                  <span
                    className="wow fadeInUp text-white"
                    data-wow-delay="0.6s"
                  >
                    of{" "}
                  </span>
                  <span
                    className="wow fadeInUp text-orange"
                    data-wow-delay="0.6s"
                  >
                    Fueling
                  </span>
                </h1>
              </div>
            </div>
            <div className="col-md-6"></div>
          </div>
        </div>
      </section>
      <section className="common-section-all foundation-dots mb-0">
        <div className="container">
          <div className="heading-style text-center wow fadeInUp">
            <h5>
              Gas It Up <span className="text-orange">Foundation</span>
            </h5>
            <p>
              The Gas It Up Foundation has an impact in STEM by investing in
              STEAM-E Labs for public and private schools in local
              <br /> communities. Our Foundation also partners with corporations
              who are in the STEAM sectors to offer internships. GIUF has also{" "}
              <br />
              established partnerships for GIU fellows to ensure job readiness.
            </p>
          </div>
          <div className="row align-items-center justify-content-center">
            <div className="col-md-12 col-sm-12 dashboard-listing">
              <div className="row align-items-center justify-content-center listing-list flex-row-reverse">
                <div className="col-lg-6 col-12 text-center mb-lg-0 mb-5 wow fadeInRight right-circle dots-purple foundation_logo">
                  <figure className="position-relative">
                    <Slider
                      className="image-slide mb-md-5 mb-3"
                      {...foundbanimag}
                    >
                      {foundban.map((foundationbanner, i) => (
                        <img
                          src={foundationbanner.img}
                          alt="foundation banner"
                          className="img-fluid border-20 w-100"
                          key={i}
                        />
                      ))}
                    </Slider>
                  </figure>
                  <i>
                    <img src={foundationlogo} alt="foundation logo" />
                  </i>
                </div>
                <div className="col-lg-6 col-12">
                  <ul className="mt-lg-0 mt-3">
                    {FoundationList.map((foundationlist, i) => (
                      <li
                        className="wow fadeInLeft"
                        data-wow-delay={`0.` + i + `s`}
                        key={i}
                      >
                        <i className="me-3">
                          <img src={check} alt="check" />
                        </i>
                        {foundationlist}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all fleet-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7 pb-0">
              <div className="heading-style text-lg-start text-center wow fadeInUp mb-0">
                <h5 className="text-lg-start text-center mb-lg-0">
                  Learn more about the GIU{" "}
                  <span className="text-orange">Foundation</span>
                </h5>
              </div>
            </div>
            <div className="col-md-5 pb-0 text-lg-end text-center">
              <a
                href="https://gasitupfoundation.org/"
                className="px-5 btn btn--primary d-inline-flex"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>Read More</span>
              </a>
            </div>
          </div>
        </div>
      </section>
      <div className="highQuality-fuel pb-lg-4 mt-lg-5">
        <CertifiedSupplier
          certifiedblock={certifiedblock}
          CertifieIcons={CertifieIcons}
        />
      </div>
      <section className="common-section-all business-partner py-5 mt-md-5 leadershipbusiness">
        <div className="container">
          <div className="col-md-12 px-lg-5 px-2 pb-0">
            <div className="heading-style text-center wow fadeInUp">
              <h5>
                Trusted By <span className="text-orange"> The Best</span>
              </h5>
            </div>
          </div>
        </div>
        <Partners />
      </section>
      <section className="common-section-all pb-0">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-12 col-sm-12 dashboard-listing">
              <div className="row align-items-center justify-content-center listing-list flex-row-reverse">
                <div className="col-lg-6 col-12 ps-lg-5 ">
                  <div className="ps-lg-5 heading-style text-start">
                    <h5 className="text-start text-capitalize wow fadeInUp">
                      Robotic <span className="text-green">Charging</span>
                    </h5>
                    <p className="wow fadeInUp" data-wow-delay="0.2s">
                      The final phase of the Charge It Up plan is to roll out
                      its autonomous robotic charging technology. This platform
                      allows for remote charging to take place without the need
                      of designated charging spaces.
                    </p>
                    <Link
                      to="/chargeitup"
                      className="px-5 btn btn--secondary d-inline-flex mt-3"
                    >
                      <span>Read More</span>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-6 col-12 text-center mb-lg-0 mb-5 wow fadeInLeft">
                  <div
                    className="dots-orange bg-grey border-20 robotic_charge"
                    style={{ zIndex: "inherit" }}
                  >
                    <div className="home-banner-texter text-lg-start text-center border-20">
                      <h5 className="wow fadeInUp mb-4" data-wow-delay=".2s">
                        <img
                          src={
                            props.theme === "dark"
                              ? chargeituplogo
                              : chargeItUpiconwhite
                          }
                          alt="chargeitup logo"
                          className="img-fluid"
                        />
                      </h5>
                      <h2 className="wow fadeInUp mb-0" data-wow-delay="0.2s">
                        Keep it
                        <span className="ps-2 text-green">Positive.</span>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 pb-0">
              <div className="heading-style text-center wow fadeInUp">
                <h5 className="text-capitalize">
                  contact a <span className="text-orange"> Gas It Up</span>{" "}
                  professional <br />
                </h5>
              </div>
            </div>
            <Contact
              type={type}
              contactbanner={contactbanner}
              showBusinessFields={false}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default FuelingTheFuel;

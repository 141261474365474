import React from "react";
import Slider from "react-slick";
import { download, iphonescreen, relax, schedule } from "../Imagesjs/Images";
import "./IphoneSlider.css";

function IphoneSlider(props) {
  var pathname = window.location.pathname;

  const iphoneimage1 = [
    { iosimage1: download },
    { iosimage2: schedule },
    { iosimage3: relax },
  ];
  const setting = {
    draggable: true,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    dots: false,
    fade: true,
    speed: 400,
    infinite: true,
  };
  return (
    <>
      <div className="img-frame position-relative IphoneSlider">
        <i>
          <img
            src={iphonescreen}
            alt="iphonescreen"
            className="img-fluid wow fadeinRight"
            width="100%"
            height="100%"
          />
        </i>
        {pathname === "/our-process" && (
          <Slider className="image-slide" {...setting}>
            {props.iphoneimage.map((iphoneslide, i) => (
              <div className="clickcustomform wow  animated" key={i}>
                <img
                  src={iphoneslide.iosimage}
                  alt="thumb"
                  width="100%"
                  height="100%"
                />
              </div>
            ))}
          </Slider>
        )}

        {pathname === "/" && (
          <Slider className="image-slide" {...setting}>
            {props.activeStep === 0 && (
              <div className="clickcustomform wow  animated">
                <img
                  src={iphoneimage1[0].iosimage1}
                  alt="thumb"
                  width="100%"
                  height="100%"
                />
              </div>
            )}

            {props.activeStep === 1 && (
              <div className="clickcustomform wow  animated">
                <img
                  src={iphoneimage1[1].iosimage2}
                  alt="thumb"
                  width="100%"
                  height="100%"
                />
              </div>
            )}

            {props.activeStep === 2 && (
              <div className="clickcustomform wow  animated">
                <img
                  src={iphoneimage1[2].iosimage3}
                  alt="thumb"
                  width="100%"
                  height="100%"
                />
              </div>
            )}
          </Slider>
        )}

        {pathname === "/our-process" && (
          <Slider className="image-slide" {...setting}>
            {props.activeStep === 0 && (
              <div className="clickcustomform wow  animated">
                <img
                  src={iphoneimage1[0].iosimage1}
                  alt="thumb"
                  width="100%"
                  height="100%"
                />
              </div>
            )}

            {props.activeStep === 1 && (
              <div className="clickcustomform wow  animated">
                <img
                  src={iphoneimage1[1].iosimage2}
                  alt="thumb"
                  width="100%"
                  height="100%"
                />
              </div>
            )}

            {props.activeStep === 2 && (
              <div className="clickcustomform wow  animated">
                <img
                  src={iphoneimage1[2].iosimage3}
                  alt="thumb"
                  width="100%"
                  height="100%"
                />
              </div>
            )}
          </Slider>
        )}
      </div>
    </>
  );
}

export default IphoneSlider;

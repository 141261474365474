import React from "react";
import Contact from "../Contactus/Contact";
import {
  chargeItUpbanner,
  chargeItUpicon,
  chargeItUpiconwhite,
  chargeituplogo,
  linehorn1,
  linehorn2,
  linehorn3,
  linehorn4,
  linehorn5,
} from "../Imagesjs/Images";
import "./ChargeItUp.css";
import { Link } from "react-router-dom";
import CUITabing from "../CUITabing/CUITabing";

function ChargeItUp(props) {
  const type = 16;
  return (
    <>
      <section className="inner-banner section-banner build-pump chargeitup">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12 col-12 text-center">
              <i>
                <img
                  src={chargeItUpicon}
                  alt="chargeItUp icon"
                  className="img-fluid"
                  width={80}
                  height={80}
                />
              </i>
            </div>
            <div
              className="col-lg-6 col-12 position-relative"
              style={{ zIndex: "9" }}
            >
              <div className="home-banner-texter text-lg-start text-center">
                <h5 className="wow fadeInUp mb-4 px-3" data-wow-delay=".2s">
                  <img
                    src={
                      props.theme === "dark"
                        ? chargeituplogo
                        : chargeItUpiconwhite
                    }
                    alt="chargeItUp icon"
                    className="img-fluid"
                  />
                </h5>
                <h1 className="wow fadeInUp mb-4" data-wow-delay="0.2s">
                  Keep it
                  <span className="ps-2">Positive.</span>
                </h1>
                <p className="wow fadeInUp pe-lg-4" data-wow-delay="0.3s">
                  Electric vehicle charging solutions for home, public, and
                  business scenarios.
                </p>
              </div>
            </div>
            <div className="offset-lg-1 col-lg-5 col-12 wow fadeInRight text-start my-5">
              <div className="wow zoomIn text-center px-3">
                <figure>
                  <img
                    src={chargeItUpbanner}
                    alt="chargeItUp banner"
                    className="img-fluid"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all intro-sistercompny pb-4">
        <div className="container">
          <div className="col-md-12 px-lg-5 px-2 pb-0">
            <div className="heading-style text-center mb-0">
              <div className="intro-horn">
                <i className="horn-left d-flex flex-column">
                  <img
                    src={linehorn1}
                    alt="line"
                    className="wow fadeInUp me-3"
                    data-wow-delay="0.2s"
                    width={30}
                    height={15}
                  />
                  <img
                    src={linehorn2}
                    alt="line"
                    className="wow fadeInUp me-4"
                    data-wow-delay="0.4s"
                    width={30}
                    height={15}
                  />
                  <img
                    src={linehorn3}
                    alt="line"
                    className="wow fadeInUp me-3"
                    data-wow-delay="0.6s"
                    width={30}
                    height={15}
                  />
                </i>
                <span className="wow fadeInUp" data-wow-delay=".1s">
                  Coming in 2024
                </span>
                <i
                  className="horn-right d-flex flex-column"
                  data-wow-delay=".2s"
                >
                  <img
                    src={linehorn4}
                    alt="line"
                    className="wow fadeInUp ms-3"
                    data-wow-delay="0.2s"
                    width={30}
                    height={15}
                  />
                  <img
                    src={linehorn2}
                    alt="line"
                    className="wow fadeInUp ms-4"
                    data-wow-delay="0.4s"
                    width={30}
                    height={15}
                  />
                  <img
                    src={linehorn5}
                    alt="line"
                    className="wow fadeInUp ms-3"
                    data-wow-delay="0.6s"
                    width={30}
                    height={15}
                  />
                </i>
              </div>
              <h5
                className="wow fadeInUp text-capitalize mb-3"
                data-wow-delay=".3s"
              >
                <span className="text-white">The</span> Charge It Up Solution
                <span className="text-white">.</span> <br />
                <span className="text-white">
                  More Than Just a Full Charge{" "}
                </span>
              </h5>
              <p className="wow fadeInUp" data-wow-delay=".6s">
                Charge It Up brings charging solutions directly to drivers,
                businesses, and fleets to help keep them safe, efficient, and
                on-the-go.
                <br />
                <br />
              </p>
              <p className="wow fadeInUp" data-wow-delay=".7s">
                Charge It Up's approach is to offer an end to end home, public,
                and fleet solution that converges IT, IoT, and EV to enable
                <br />
                customers unique electrification needs. Charge It Up offers
                industry leading smart home and public chargers, a fast charging
                <br />
                network and a free app with which you can find and charge at any
                charging provider across the country. <br />
                <br />
              </p>
              <p className="wow fadeInUp" data-wow-delay=".8s">
                From hardware to software and even robotics, Charge It Up EV
                Solutions offers integrated solutions for charging your home,
                <br />
                fleet, or business, and keeping you on-the-go.
              </p>
            </div>
          </div>
        </div>
      </section>
      <CUITabing />
      <section className="common-section-all fleet-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7 pb-0">
              <div className="heading-style text-lg-start text-center wow fadeInUp mb-lg-0 mb-3">
                <h5 className="text-lg-start text-center mb-lg-0">
                  Interested in <span className="text-green"> partnering </span>{" "}
                  with us
                </h5>
              </div>
            </div>
            <div className="col-lg-5 pb-0 text-lg-end text-center">
              <Link
                to="/getafleet"
                className="px-5 btn btn--secondary d-inline-flex"
                style={{ zIndex: "9" }}
              >
                <span>Learn More</span>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all button-green">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 pb-0">
              <div className="heading-style text-center wow fadeInUp">
                <h5 className="text-capitalize">
                  contact a <span className="text-green">Charge It Up</span>{" "}
                  professional
                </h5>
              </div>
            </div>
            <Contact type={type} showBusinessFields={false} />
          </div>
        </div>
      </section>
    </>
  );
}

export default ChargeItUp;

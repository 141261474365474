import React from 'react'
import { Link } from 'react-router-dom'
import { arrowleft, errormsg } from '../Imagesjs/Images'
import './404Error.css'

function Error(props) {
  console.log('props')
  return (
    <>
    <div className="errorpage text-center">
        <figure>
            <img src={errormsg} alt="errormsg" className="img-fluid"/>
        </figure>
        <div className="home-banner-texter mt-5">
              <h1 className="wow fadeInUp animate text-unset" data-wow-delay="0.2s">
                    Page not found
              </h1>
              <p
                className="wow fadeInUp animate mt-4 mb-3"
                data-wow-delay="0.6s"
              >
                The page you are looking for doesn't exist.<br/> Here are some helpful links:
              </p>
        </div>
        <div className=" banner-actions d-flex align-items-center justify-content-center mt-0">
                <Link
                  to="/"
                  className="btn btn--primary bg-white clickcustomform wow animate fadeInUp m-3 border-0"
                  data-wow-delay="0.2s"
                >
                <i className="me-3">
                    <img
                      src={arrowleft}
                      alt="arrow"
                      width="10px"
                      className="d-flex wow"
                    />
                </i>
                  <span onClick={(e)=>{e.preventDefault();window.history.back()}}>Go Back</span>
                
                </Link>
                <Link
                  to="/"
                  className="btn btn--primary clickcustomform wow animate fadeInUp"
                  data-wow-delay="0.4s"
                >
                  <span>Take me home</span>
                
                </Link>
              </div>
    </div>
    </>
  )
}

export default Error
import { useEffect, useRef } from 'react';

const useGooglePlacesAutocomplete = (callback) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (!window.google) return;

    const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current);

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (!place.geometry || !place.address_components) return;

      const formattedAddress = {
        formatted_address: place.formatted_address,
        locality: '',
        admin_area_l1: '',
        street_number: '',
        route: '',
        country: '',
        postal_code: '',
        name: place.name,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        formatted_address_second: inputRef.current.value || ''
      };

      place.address_components.forEach(component => {
        if (component.types.includes('locality')) {
          formattedAddress.locality = component.long_name;
        } else if (component.types.includes('administrative_area_level_1')) {
          formattedAddress.admin_area_l1 = component.short_name;
        } else if (component.types.includes('street_number')) {
          formattedAddress.street_number = component.short_name;
        } else if (component.types.includes('route')) {
          formattedAddress.route = component.long_name;
        } else if (component.types.includes('country')) {
          formattedAddress.country = component.long_name;
        } else if (component.types.includes('postal_code')) {
          formattedAddress.postal_code = component.short_name;
        }
      });

      callback(formattedAddress);
    });
  }, [callback]);

  return inputRef;
};

export default useGooglePlacesAutocomplete;

import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import axios from "axios";
import Swal from "sweetalert2";
import "./Newsletter.css";
const ValidatedLoginForm = (props) => (
  <Formik
    initialValues={{ email: "", type: props.newstype }}
    onSubmit={(values, action) => {
      action.resetForm();
      axios
        .post(`${process.env.REACT_APP_API_base_KEY}/suscribe`, values)
        .then((res) => {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Thank you!!",
            text: "Your interest has been submitted to us. Our team will get back to you soon!!",
            showConfirmButton: false,
            timer: 4000,
          });
        })
        .catch((err) => {});
    }}
    validationSchema={Yup.object().shape({
      email: Yup.string()
        .email("Email Should be valid")
        .required("Email Is Required"),
    })}
  >
    {(props) => {
      const {
        touched,
        errors,
        values,
        handleChange,
        handleBlur,
        handleSubmit,
      } = props;

      return (
        <>
          <form onSubmit={handleSubmit}>
            <div className="d-flex align-items-center justify-content-center newsletter px-2 position-relative">
              <div className="form-group col-8">
                <input
                  type="text"
                  className="form-control me-2"
                  placeholder="Enter Your Email"
                  id="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  autoComplete="off"
                />
                <p className="text-danger mb-0">
                  {errors.email && touched.email && (
                    <span className="input-feedback">{errors.email}</span>
                  )}
                </p>
              </div>
              <button
                type="submit"
                value="Submit"
                className="btn btn--primary offset-1 col-3 px-5"
              >
                <span>Submit</span>
              </button>
            </div>
          </form>
        </>
      );
    }}
  </Formik>
);
export default ValidatedLoginForm;

import React from "react";
import GetAFleetqout from "../GetAFleetQoute/GetAFleetqout";
import { img12, img6, img8, leadershipcontactbanner } from "../Imagesjs/Images";
import Partners from "../Partners/Partners";
import Testimonial from "../Testimonial/Testimonial";

function GetAFleet() {
  const contactbanner = leadershipcontactbanner;
  const slidesData = [
    {
      imge: img6,
      testimonialbody:
        "I've been using Gas It Up for my business fleet and I couldn't be happier. Not only does their service save my time and energy, but it's also more affordable than buying fuel from the pump. I'd definitely recommend them to anyone looking for an easy, convenient way to fuel up their business!",
      title: "John Posillico",
      designation: "President, Arizona beverages",
    },
    {
      imge: img8,
      testimonialbody:
        "Blackridge Construction is grateful to have the Gas It Up trucks come out and fuel our fleet. The GIU Fuel management panel has given us tons of insight and data on our refueling process",
      title: "Chris Caroll",
      designation: "Blackridge Construction",
    },
    {
      imge: img12,
      testimonialbody:
        "The efficiency of our ambulance fleet has increased significantly since starting our relationship with GIU. The GIU fuel technicians deliver great customer service and leave us confident, knowing that LifeMed is prepared to assist in emergency situations.",
      title: "Maurice Williams",
      designation: "MedLife Ambulance ",
    },
    {
      imge: img8,
      testimonialbody:
        "Blackridge Construction is grateful to have the Gas It Up trucks come out and fuel our fleet. The GIU Fuel management panel has given us tons of insight and data on our refueling process",
      title: "Chris Caroll",
      designation: "Blackridge Construction",
    },
  ];
  return (
    <>
      <section className="common-section-all">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 pb-0">
              <div className="heading-style text-center wow fadeInUp">
                <h5>
                  Get a <span className="text-orange"> Fleet Quote:</span>
                </h5>
              </div>
            </div>
            <GetAFleetqout fleetbanner={contactbanner} />
          </div>
        </div>
      </section>
      <section className="common-section-all business-partner py-5">
        <div className="container">
          <div className="col-md-12 px-lg-5 px-2 pb-0">
            <div className="heading-style text-center wow fadeInUp">
              <h5>
                Fueling the <span className="text-orange">Community</span>
              </h5>
            </div>
          </div>
        </div>
        <Partners />
      </section>
      <section className="common-section-all mb-3 mb-md-0">
        <div className="container">
          <div className="col-md-12 px-lg-5 px-2 pb-0">
            <div className="heading-style text-center wow fadeInUp mb-3">
              <h5>Customer Testimonies</h5>
            </div>
          </div>
        </div>
        <Testimonial slidesData={slidesData} />
      </section>
    </>
  );
}

export default GetAFleet;

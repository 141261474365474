import React from "react";
import { check } from "../Imagesjs/Images";

function ListListing() {
  const ListListing = [
    "Schedule automated fueling",
    "Save money while helping the environment",
    <span>See our savings and CO<sub>2</sub> calculator below</span>,
    "Accept all fuel cards",
    "Get advanced fueling insight reports",
  ];
  return (
    <div>
      <ul style={{ paddingTop: "19px" }}>
        {ListListing.map((number, i) => (
          <li
            className="wow fadeInLeft"
            data-wow-delay={`0.` + i + `s`}
            key={i}
          >
            <i className="me-3">
              <img src={check} alt="check" />
            </i>
            {number}{" "}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default ListListing;

import React from "react";
import Slider from "react-slick";
import { bannerimg } from "../Imagesjs/Images";
import "./TabSlider.css";

function TabSlider(props) {
  const setting = {
    draggable: true,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    dots: false,
    fade: true,
    speed: 500,
    infinite: true,
  };
  return (
    <>
      <div className="img-frame position-relative laptopslide">
        <i>
          <img
            src={bannerimg}
            alt="macbook"
            className="img-fluid w-100 wow FadeInRight"
            data-wow-delay="0.2s"
            width="100%"
            height="100%"
          />
        </i>
        <Slider className="image-slide" {...setting}>
          {props.slideimages.map((slidimg, i) => (
            <div className="clickcustomform wow  animated" key={i}>
              <img
                src={slidimg.image1}
                alt="thumb"
                width="100%"
                height="100%"
              />
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
}

export default TabSlider;

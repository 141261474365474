import React from "react";
import "./PriceModal.css";

function PriceModal(props) {
  return (
    <>
      <section className="common-section-all pt-2">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="heading-style text-start wow fadeInUp">
                <h5>
                  Pricing
                  <span className="text-white"> Models</span>
                </h5>
              </div>
            </div>
          </div>
          <div className="pricing-reel">
            <div className="container">
              <div className="price-box">
                <div className="row">
                  {props.box.map((pricingbox, i) => (
                    <div
                      className="col-xl-3 col-md-6 col-12 wow fadeInUp mb-xl-0 mb-4"
                      key={i}
                      data-wow-delay={".0" + i + "s"}
                    >
                      <div
                        // onClick={() => onhandleclick(`pricebox${i}`)}
                        className={`
                          pricebox${i} pricebox  active
                          `}
                      >
                        <div className="price-col">
                          <div
                            className="pb-3 mb-3"
                            style={{
                              borderBottom:
                                "1px solid rgba(255, 255, 255, 0.5)",
                            }}
                          >
                            <h4>
                              {pricingbox.title}
                              <br />
                              <span>{pricingbox.subhead}</span>
                            </h4>
                            <h2>
                              {pricingbox.price}
                              {pricingbox.IsPerMonth ? <span> /mo</span> : ""}
                            </h2>
                          </div>
                          <ul>
                            {pricingbox.boxlistng.map((boxlistng, i) => (
                              <li
                                className="wow fadeInUp"
                                data-wow-delay={".0" + i + "s"}
                                key={i}
                              >
                                {boxlistng.list}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PriceModal;

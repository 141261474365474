import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "./B2c.css";

function B2c(props) {
  const b2csetting = {
    dots: false,
    slidesToShow: 4,
    slidesToscroll: 1,
    speed: 1000,
    autoplay: true,
    infinite: false,
    loop: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
        },
      },
    ],
  };
  return (
    <>
      <section className="common-section-all">
        <div className="heading-style text-center wow fadeInUp">
          {props.Isheadtrue ? (
            <h5>
              Fuel Delivered <span className="text-orange"> To You </span>
            </h5>
          ) : (
            <h5>
              Where You Can <span className="text-orange">Gas It Up</span>
            </h5>
          )}
        </div>
        <div className="scenarios-reel">
          <div className="container">
            <Slider className="image-slide" {...b2csetting}>
              {props.scenarios.map((e, i) => (
                <div className="box" key={i}>
                  <div className="test-component">
                    <div className="scenarios-icon text-center">
                      <Link to="#" onClick={(e) => e.preventDefault()}>
                        <i>
                          <img src={e.icon} alt="icon" width={60} height={60}/>
                        </i>
                      </Link>
                    </div>
                    <article className="test-title">
                      <h4>{e.title}</h4>
                    </article>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
}

export default B2c;

import React, { useEffect, useState } from "react";
import "./FuelingFuture.css";
import GMap from "../../GMap";
import { activelocation, ciu, upcominglocation } from "../Imagesjs/Images";
function FuelingFuture(props) {
  const [filtermarker, setfiltermarker] = useState([]);
  const activelocationarr = [
    {
      lat: 32.776665,
      long: -96.796989,
      marker: activelocation,
    },
    {
      lat: 42.331429,
      long: -83.045753,
      marker: activelocation,
    },
    {
      lat: 39.739235,
      long: -104.99025,
      marker: activelocation,
    },
    {
      lat: 39.952583,
      long: -75.165222,
      marker: activelocation,
    },
    {
      lat: 40.825661,
      long: -74.108521,
      marker: activelocation,
    },
    {
      lat: 40.741895,
      long: -73.989308,
      marker: activelocation,
    },
    {
      lat: 40.69243621826172,
      long: -73.99037170410156,
      marker: activelocation,
    },
    {
      lat: 32.2228765,
      long: -110.9748477,
      marker: activelocation,
    },
    {
      lat: 40.22140121459961,
      long: -74.77140045166016,
      marker: activelocation,
    },
    {
      lat: 40.2658805847168,
      long: -76.88015747070312,
      marker: activelocation,
    },
  ];

  const ciuarr = [
    {
      lat: 27.7567667,
      long: -81.4639835,
      marker: ciu,
    },
    {
      lat: 38.7251776,
      long: -105.607716,
      marker: ciu,
    },
    {
      lat: 36.7014631,
      long: -118.755997,
      marker: ciu,
    },
    {
      lat: 43.6211955,
      long: -84.6824346,
      marker: ciu,
    },
    {
      lat: 31.2638905,
      long: -98.5456116,
      marker: ciu,
    },
    {
      lat: 40.741895,
      long: -73.989308,
      marker: ciu,
    },
  ];

  const upcominglocationarr = [
    {
      lat: 38.97919845581055,
      long: -76.49140167236328,
      marker: upcominglocation,
    },
    {
      lat: 38.89543914794922,
      long: -77.03128051757812,
      marker: upcominglocation,
    },
    {
      lat: 27.947895050048828,
      long: -82.45733642578125,
      marker: upcominglocation,
    },
    {
      lat: 25.770843505859375,
      long: -80.19763946533203,
      marker: upcominglocation,
    },
    {
      lat: 28.537979125976562,
      long: -81.37873840332031,
      marker: upcominglocation,
    },
    {
      lat: 30.2711286,
      long: -97.7436995,
      marker: upcominglocation,
    },
    {
      lat: 29.424585342407227,
      long: -98.49463653564453,
      marker: upcominglocation,
    },
    {
      lat: 31.76162338256836,
      long: -106.48563385009766,
      marker: upcominglocation,
    },
    {
      lat: 29.7603759765625,
      long: -95.36980438232422,
      marker: upcominglocation,
    },
    {
      lat: 33.4484367,
      long: -112.074141,
      marker: upcominglocation,
    },
  ];

  useEffect(() => {
    setfiltermarker([...ciuarr, ...upcominglocationarr, ...activelocationarr]);
  }, []);

  const onItemClick = () => {
    setfiltermarker([...activelocationarr]);
  };

  const onItemClick1 = () => {
    setfiltermarker([...upcominglocationarr]);
  };
  const onItemClick2 = () => {
    setfiltermarker([...ciuarr]);
  };
  const onItemClick3 = () => {
    setfiltermarker([...ciuarr, ...upcominglocationarr, ...activelocationarr]);
  };
  return (
    <>
      <div className="container">
        <div className="col-md-12 col-sm-12 mx-auto fueling-future">
          <div className="map-container mb-3">
            <GMap filtermarker={filtermarker} />
          </div>
          <div className="row align-items-center justify-content-center my-4 icon-identifi">
            <ul>
              <li onClick={() => onItemClick3()}>
                <strong>All</strong>
              </li>
              <li onClick={() => onItemClick()}>
                <strong>Active</strong>
              </li>
              <li onClick={() => onItemClick1()}>
                <strong>Future</strong>
              </li>
              <li onClick={() => onItemClick2()}>
                <strong>Charge It Up</strong>
              </li>
            </ul>
          </div>
          <div className="col-md-12 px-lg-5 px-2 pb-0">
            <div className="heading-style text-center wow fadeInUp">
              {props.Istitlehaed === true ? (
                <h5 className="fw-normal">
                  Fueling the <span className="text-orange">Future</span>
                </h5>
              ) : (
                <h5 className="fw-normal">
                  Convenience, <span className="text-orange">Delivered</span>
                </h5>
              )}
              <h4>Gas It Up Locations</h4>
              <p>
                Gas It Up is rapidly growing throughout the United States and
                proving to be one of the leading mobile fuel <br /> providers
                for autonomous fleets and daily drivers.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FuelingFuture;

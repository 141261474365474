import React, { Component } from "react";
import { Link } from "react-router-dom";
import { moblogo, moblogolight } from "../Imagesjs/Images";
import NavLink from "./NavLink";
import "./Header.css";

class Header extends Component {
  state = {
    activeIndex: null,
    active: false,
    isActive: false,
    dropdown: window.innerWidth > 1024 ? "dropdown" : "",
    is_desktop: window.innerWidth > 1024 ? true : false,
  };

  handleResize = (e) => {
    const windowSize = window.innerWidth;

    if (windowSize > 1024) {
      this.setState({ dropdown: "dropdown" });
      this.setState({ is_desktop: true });
    } else {
      this.setState({ dropdown: "" });
      this.setState({ is_desktop: false });
    }
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  toggleHeader = () => {
    if (window.innerWidth < 1280) {
      document.body.classList.toggle("opennav");
      this.setState((prev) => ({ ...prev, active: !prev.active }));
    }

    // document.querySelectorAll(cl)[0].classList.toggle("dropdown");
  };
  handleClick = (index) => {
    this.setState({ activeIndex: index });
  };

  onItemClick = (event) => {
    for (const li of document.querySelectorAll("li.nav-item")) {
      li.classList.remove("active");
    }
    event.currentTarget.classList.toggle("active");
  };

  onClickActive = (e, cl) => { //Mobile
    if (this.state.is_desktop === false) {
      document.querySelectorAll(cl)[0].classList.toggle("dropdown");
    }
  };

  render() {
    let pathname = window.location.pathname.replace(
      //eslint-disable-next-line
      /[`~!@#$%^&*()_|+\=?;:'",.<>\{\}\[\]\\\/]/gi,
      ""
    );
    let whoWeFule = [
      "busines-fleets-trucking-and-construction-companies",
      "corporate-and-college-campuses-stadiums-and-arenas",
      "retail-and-rurface-parking-lots",
    ];
    let checkBussiness = whoWeFule.includes(pathname);
    let dropdown = this.state.dropdown;
    var whoWeFuleClassName = checkBussiness
      ? "nav-item " + dropdown + " w3_megamenu active wo-we"
      : "nav-item  " + dropdown + " w3_megamenu wo-we";

    let WhoWeAre = ["leaderShip-team", "fueling-the-future", "our-process"];
    let checkWhoWeAre = WhoWeAre.includes(pathname);
    var WhoWeAreClassName = checkWhoWeAre
      ? "nav-item " + dropdown + " w3_megamenu active wo-we1"
      : "nav-item " + dropdown + " w3_megamenu wo-we1";

    let WhatWeDo = [
      "fuel-management-software",
      "diesel-on-demand",
      "our-fuel-techs-and-sefaty",
      "gui-fleet",
      "gasoline",
      "environmental-impact",
      "geofencing",
      "autonomous-fleet-equipment",
    ];
    let checkWhatWeDo = WhatWeDo.includes(pathname);
    var WhatWeDoClassName = checkWhatWeDo
      ? "nav-item " + dropdown + " w3_megamenu fw active wo-we2"
      : "nav-item " + dropdown + " w3_megamenu fw wo-we2";

    return (
      <header className="shadow-sm sticky-top">
        <nav
          className={`navbar navbar-expand-lg shadow w3_megamenu ${this.state.active && "open-nav"
            }`}
          role="navigation"
        >
          <div className="container">
            <div className="navbar-header">
              <Link to="/" className="navbar-brand d-block">
                <i className="d-flex align-items-center">
                  <img
                    src={this.props.theme === "dark" ? moblogo : moblogolight}
                    alt="sitelogo"
                    height={60}
                    width={100}
                    className=""
                  />
                </i>
              </Link>
              <div className=" mob-block">
                <div className="d-flex align-items-center">
                  <div className="nav navbar-nav navbar-right">
                    <ul className="nav navbar-nav mr-auto">
                      <li className="nav-item dropdown">
                        <Link
                          to="#"
                          onClick={(e) => e.preventDefault()}
                          data-toggle="dropdown"
                          className="dropdown-toggle"
                        >
                          LogIn<b className="caret ms-1"></b>
                        </Link>
                        <ul className="dropdown-menu">
                          <li className="w3_megamenu-content">
                            <div className="col-sm-12">
                              <h3 className="title">GIU Fuel Panel</h3>
                            </div>
                          </li>
                          <li>
                            <Link to="/" className="btn btn--grey p-0">
                              Sign Up
                              {/* <span>Sign Up</span> */}
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <button
                    className="navbar-toggler navbar-toggler-right"
                    onClick={this.toggleHeader}
                    id="eventtoggle"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarsExampleDefault"
                    aria-controls="navbarsExampleDefault"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="nav navbar-nav navbar-right desk-block">
                <ul className="nav mr-auto">
                  <li>
                    <a
                      href={`${process.env.React_App_login_link}`}
                      target="_blank"
                      className="me-xl-4 me-2 btn btn--grey"
                      rel="noopener noreferrer"
                    >
                      {/* <span>GIU Fuel Panel</span> */}
                      GIU Fuel Panel
                    </a>
                  </li>
                  <li>
                    <a
                      href={`${process.env.React_App_signup_link}`}
                      target="_blank"
                      className="btn btn--grey"
                      rel="noopener noreferrer"
                    >
                      {/* <span>Sign Up</span> */}
                      Sign Up
                    </a>
                  </li>
                </ul>
              </div>
              <div
                className="collapse navbar-collapse align-items-center justify-content-end me-xl-4 me-2"
                id="navbarsExampleDefault"
              >
                <ul className="nav navbar-nav ml-auto w-100 justify-content-end">
                  <li className="" onClick={this.onItemClick}>
                    <NavLink exact="true" to="/" onClick={this.toggleHeader}>
                      Home
                    </NavLink>
                  </li>
                  <li className={WhoWeAreClassName} onClick={this.onItemClick}>
                    <Link
                      to="#"
                      onClick={(e) => this.onClickActive(e, ".wo-we1")}
                      data-bs-toggle="dropdown"
                      className="dropdown-toggle"
                    >
                      Who We Are<b className="caret ms-1"></b>
                    </Link>
                    <ul className="dropdown-menu">
                      <li
                        className="w3_megamenu-content"
                        onClick={this.onItemClick}
                      >
                        <NavLink to="/our-process" onClick={this.toggleHeader}>
                          <h3 className="title">Our Process</h3>
                          <p>
                            Learn more about the GIU app, our delivery process,
                            and benefits!
                          </p>
                        </NavLink>
                      </li>
                      <li
                        className="w3_megamenu-content"
                        onClick={this.onItemClick}
                      >
                        <NavLink
                          to="/leaderShip-team"
                          onClick={this.toggleHeader}
                        >
                          <h3 className="title">Leadership Team</h3>
                          <p>Meet our board members and team</p>
                        </NavLink>
                      </li>
                      <li
                        className="w3_megamenu-content"
                        onClick={this.onItemClick}
                      >
                        <NavLink
                          to="/fueling-the-future"
                          onClick={this.toggleHeader}
                        >
                          <h3 className="title">Fueling the Future </h3>
                          <p>
                            Changing the fueling and charging landscape for the
                            better everyday
                          </p>
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li className={WhatWeDoClassName} onClick={this.onItemClick}>
                    <Link
                      to="#"
                      onClick={(e) => this.onClickActive(e, ".wo-we2")}
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      className="dropdown-toggle"
                    >
                      What We Do<b className="caret ms-1"></b>
                    </Link>
                    <ul className="dropdown-menu">
                      <li className="">
                        <div className="row">
                          <div className="col-xl-4 col-12 dropmenufw">
                            <p className="title">Technology & Insights</p>
                            <ul>
                              <li
                                className="w3_megamenu-content"
                                onClick={this.onItemClick}
                              >
                                <NavLink
                                  to="/fuel-management-software"
                                  onClick={this.toggleHeader}
                                >
                                  <h3 className="title">
                                    Fuel Management Software
                                  </h3>
                                  <p>
                                    Check out our real-time reports tailored to
                                    your <br />
                                    business operation
                                  </p>
                                </NavLink>
                              </li>
                              <li
                                className="w3_megamenu-content"
                                onClick={this.onItemClick}
                              >
                                <NavLink
                                  to="/fleets"
                                  onClick={this.toggleHeader}
                                >
                                  <h3 className="title">GIU Fleet</h3>
                                  <p>
                                    Mobile fueling trucks build from the ground
                                    up by <br />
                                    professionals to serve you or your business{" "}
                                    <br />
                                    operation.
                                  </p>
                                </NavLink>
                              </li>
                              <li
                                className="w3_megamenu-content"
                                onClick={this.onItemClick}
                              >
                                <NavLink
                                  to="/geofencing"
                                  onClick={this.toggleHeader}
                                >
                                  <h3 className="title">Geofencing</h3>
                                  <p>
                                    Our geofencing allows our fuel techs to{" "}
                                    deliver to your
                                    <br /> car or fleet vehicle(s) exact parked
                                    location to bring
                                    <br /> the pump to you.
                                  </p>
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                          <div className="col-xl-4 col-12 dropmenufw">
                            <p className="title">Energy Innovation</p>
                            <ul>
                              <li
                                className="w3_megamenu-content"
                                onClick={this.onItemClick}
                              >
                                <NavLink
                                  to="/diesel-on-demand"
                                  onClick={this.toggleHeader}
                                >
                                  <h3 className="title">Diesel & DEF</h3>
                                  <p>
                                    Save time and get high quality Diesel fuel
                                    reduce <br /> diesel emissions with DEF
                                    delivery.
                                  </p>
                                </NavLink>
                              </li>
                              <li
                                className="w3_megamenu-content"
                                onClick={this.onItemClick}
                              >
                                <NavLink
                                  to="/gasoline"
                                  onClick={this.toggleHeader}
                                >
                                  <h3 className="title">Gasoline</h3>
                                  <p>
                                    Top tier fuel delivered directly from source{" "}
                                    <br /> of fleets to daily drivers.
                                  </p>
                                </NavLink>
                              </li>
                              <li
                                className="w3_megamenu-content"
                                onClick={this.onItemClick}
                              >
                                <NavLink
                                  to="/autonomous-fleet-equipment"
                                  onClick={this.toggleHeader}
                                >
                                  <h3 className="title">
                                    Autonomous Fleet & Equipment
                                  </h3>
                                  <p>
                                    Bring the advanced fueling solutions to
                                    autonomous
                                    <br />
                                    fleets and euipment around the clock.
                                  </p>
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                          <div className="col-xl-4 col-12 dropmenufw">
                            <p className="title">Safety & Environment</p>
                            <ul>
                              <li
                                className="w3_megamenu-content"
                                onClick={this.onItemClick}
                              >
                                <NavLink
                                  to="/our-fuel-techs-and-sefaty"
                                  onClick={this.toggleHeader}
                                >
                                  <h3 className="title">
                                    Our fuel Techs & Safety{" "}
                                  </h3>
                                  <p>
                                    GIU Fuel Technician Drivers are rigoriusly
                                    trained ,
                                    <br /> certified, and rigorously follow all
                                    local, state, <br /> municipal, and
                                    inernational fire codes. codes.
                                  </p>
                                </NavLink>
                              </li>
                              <li
                                className="w3_megamenu-content"
                                onClick={this.onItemClick}
                              >
                                <NavLink
                                  to="/environmental-impact"
                                  onClick={this.toggleHeader}
                                >
                                  <h3 className="title">
                                    Environmental Impact
                                  </h3>
                                  <p>
                                    GIU energy solutions make it easy to do the
                                    right <br />
                                    thing for Mother Earth.
                                  </p>
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>

                  <li className={whoWeFuleClassName} onClick={this.onItemClick}>
                    <Link
                      to="#"
                      onClick={(e) => this.onClickActive(e, ".wo-we")}
                      data-bs-toggle="dropdown"
                      className="dropdown-toggle"
                    >
                      Who We Fuel<b className="caret ms-1"></b>
                    </Link>
                    <ul className="dropdown-menu">
                      <li
                        className="w3_megamenu-content"
                        onClick={this.onItemClick}
                      >
                        <NavLink
                          to="/corporate-and-college-campuses-stadiums-and-arenas"
                          onClick={this.toggleHeader}
                        >
                          <h3 className="title">
                            Corporate & College Campuses, Stadiums & Arenas{" "}
                          </h3>
                          <p>
                            Serving employees, students, municipal and
                            healthcare
                            <br /> professionals and fans across the United
                            States.
                          </p>
                        </NavLink>
                      </li>
                      <li
                        className="w3_megamenu-content nav-item"
                        onClick={this.onItemClick}
                      >
                        <NavLink
                          to="/business-fleets-trucking-and-construction-companies"
                          onClick={this.toggleHeader}
                        >
                          <div className="col-sm-12">
                            <h3 className="title">
                              Business Fleets, Trucking & Construction Companies
                            </h3>
                            <p>
                              Learn how to save your business time and money
                              with the
                              <br /> ultimate fleet fueling and management
                              platform.
                            </p>
                          </div>
                        </NavLink>
                      </li>
                      <li
                        className="w3_megamenu-content"
                        onClick={this.onItemClick}
                      >
                        <NavLink
                          to="/retail-and-rurface-parking-lots"
                          onClick={this.toggleHeader}
                        >
                          <h3 className="title">
                            Retail & Surface Parking lots
                          </h3>
                          <p>
                            Keeping daily drivers and consumers safe and
                            on-the-go, by
                            <br /> bringing the pump to you.
                          </p>
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li onClick={this.onItemClick}>
                    <NavLink to="/chargeitup" onClick={this.toggleHeader}>
                      Charge It Up
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default Header;

import * as Yup from 'yup';

const Schema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Email required"),

  descriptionQuote: Yup.string().optional(),
  zipCode: Yup.string()
    .matches(/^[0-9]+$/, "Must be only digits")
    .required("ZipCode is required"),
    businessName: Yup.string()
    .matches(/^[A-Za-z ]*$/, "Please enter valid businessName")
    .required("businessName name required"),
//   businessAddress: Yup.string()
//     .matches(/^[A-Za-z0-9\s\.,#@\-]+$/, "Please enter valid businessAddress")
//     .required("businessAddress name required"),
    frequency: Yup.string().required("Please select Frequency"),
  employeeSize: Yup.string().required('Please select employee size'),
  fleetSize: Yup.string().required('Please enter fleet size'),
  gallons: Yup.string().required('Please enter gallons'),
  tankType: Yup.string().required('Please select tank type'),
  fuelType: Yup.string().required('Please select fuel type'),
  phoneNumber: Yup.string()
  .matches(/^[0-9]+$/, "Must be only digits")
  .required("phoneNumber is required"),
  numberOfLocation: Yup.string()
  .matches(/^[0-9]+$/, "Must be only digits")
  .required("Number of location is required"),
})

export default Schema;
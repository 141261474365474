import React from 'react'
import appstore from "../assets/images/apple-store.svg";
import googlestore from "../assets/images/google-play.svg";
import './Playstoreicons.css';

function Playstoreicons() {
  return (
    <>
      <div className="row align-items-center justify-content-center playstoreIcons">
        <div className="col-md-12 col-sm-12 text-lg-start text-center playstore-icons d-flex align-items-center justify-content-lg-start justify-content-center">
          <a href="https://apps.apple.com/us/app/gas-it-up-inc/id1462258806" className="d-flex align-items-center me-2" target="_blank" rel="noopener noreferrer" aria-label="Appstore link">
            <img src={appstore} alt="appstore" className="wow fadeInUp" width="150px" height="47px" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.gasitup.userNew" className="d-flex align-items-center ms-2" target="_blank" rel="noopener noreferrer" aria-label="Googleplaystore link">
            <img
              src={googlestore} className="wow fadeInUp" width="150px" height="47px"
              alt="googlestore"
            />
          </a>

        </div>
      </div>
    </>
  )
}

export default Playstoreicons
import React from "react";
import { Link } from "react-router-dom";
import "./Fleet.css";

function Fleet() {
  return (
    <>
      <section className="common-section-all fleet-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7 pb-0">
              <div className="heading-style text-lg-start text-center wow fadeInUp mb-0">
                <h5 className="text-lg-start text-center ">
                  How Gas It Up can benefit{" "}
                  <span className="text-orange">your fleet</span>
                </h5>
                <p className="text-capitalize mb-lg-0">
                  Simplifies fleet routes, reduces stops, real-time insights and
                  control <br />
                  panel for all fleet vehicles, savings insights
                </p>
              </div>
            </div>
            <div className="col-lg-5 pb-0 text-lg-end text-center">
              <Link
                to="/business-fleets-trucking-and-construction-companies"
                className="px-5 btn btn--primary d-inline-flex"
              >
                <span>Read More</span>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Fleet;

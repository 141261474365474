import * as Yup from 'yup';
const Schema1 = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^[A-Za-z ]*$/, "Please enter valid firstname")
    .required("First name required"),
  lastName: Yup.string()
    .matches(/^[A-Za-z ]*$/, "Please enter valid lastname")
    .required("Last name required"),
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Email required"),

  message: Yup.string().optional(),
  phone: Yup.string()
    .matches(/^[0-9]+$/, "Must be only digits")
    .required("Phone is required"),
  zipCode: Yup.string()
    .matches(/^[0-9]+$/, "Must be only digits")
    .required("ZipCode is required"),
})
export default Schema1;
import React from "react";
import Services from "../../Services/Services";
import SavingCalculator from "../../Calculator/SavingCalculator";
import Calculator from "../../Calculator/Calculator";
import FuelingFuture from "../../FuelingFuture/FuelingFuture";
import Contactedm from "../../Contactus/Contactedm";
import Testimonial from "../../Testimonial/Testimonial";
import "./Edmpa.css";
import VideoBlock from "../../VideoBlock/VideoBlock";
import {
  arrow,
  icon1,
  icon2,
  icon3,
  icon4,
  icon5,
  icon6,
  image6,
  image8,
  image11,
  businessfleetcontactbanner,
  img6,
  img8,
  img12,
} from "../../Imagesjs/Images";
import { Link } from "react-router-dom";
import ListListing from "../../ListListing/ListListing";
import DashboardSliderslide from "../../DashboardSliderslide/DashboardSliderslide";

function EDm(props) {
  const DashboardSliderslid = [
    { image: image11 },
    { image: image6 },
    { image: image8 },
  ];
  let pathname = window.location.pathname.replace(
    //eslint-disable-next-line
    /[`~!@#$%^&*()_|+\=?;:'",.<>\{\}\[\]\\\/]/gi,
    ""
  );
  const type = 20;
  const contactbanner = businessfleetcontactbanner;
  const slidesData = [
    {
      imge: img6,
      testimonialbody:
        "I've been using Gas It Up for my business fleet and I couldn't be happier. Not only does their service save my time and energy, but it's also more affordable than buying fuel from the pump. I'd definitely recommend them to anyone looking for an easy, convenient way to fuel up their business!",
      title: "John Posillico",
      designation: "President, Arizona beverages",
    },
    {
      imge: img8,
      testimonialbody:
        "Blackridge Construction is grateful to have the Gas It Up trucks come out and fuel our fleet. The GIU Fuel management panel has given us tons of insight and data on our refueling process",
      title: "Chris Caroll",
      designation: "Blackridge Construction",
    },
    {
      imge: img12,
      testimonialbody:
        "The efficiency of our ambulance fleet has increased significantly since starting our relationship with GIU. The GIU fuel technicians deliver great customer service and leave us confident, knowing that LifeMed is prepared to assist in emergency situations.",
      title: "Maurice Williams",
      designation: "MedLife Ambulance ",
    },
    {
      imge: img8,
      testimonialbody:
        "Blackridge Construction is grateful to have the Gas It Up trucks come out and fuel our fleet. The GIU Fuel management panel has given us tons of insight and data on our refueling process",
      title: "Chris Caroll",
      designation: "Blackridge Construction",
    },
  ];
  const serviceblock = [
    {
      icon: icon1,
      ItemTitle: "Business Fleets",
      ItemDescription:
        "Here to keep your small, medium, or large fleet operation on the go!",
    },
    {
      icon: icon2,
      ItemTitle: "First Responders",
      ItemDescription:
        "Fueling all emergency vehicles that help protect our communities.",
    },
    {
      icon: icon3,
      ItemTitle: "Government Fleets",
      ItemDescription:
        "Fuel delivery for government vehicles, GIU can help agencies reduce costs and improve efficiency. ",
    },
    {
      icon: icon4,
      ItemTitle: "Delivery Fleets",
      ItemDescription:
        "Delivering the fuel to your fleet, so you can deliver more.",
    },
    {
      icon: icon5,
      ItemTitle: "Airport Fleets & Parking",
      ItemDescription:
        "Parking customers can return from their trip to a full tank of gas! Rental car companies and airport equipment never have to worry about fueling up again.",
    },
    {
      icon: icon6,
      ItemTitle: "Generators & Equipment",
      ItemDescription:
        "Bringing the pump to construction companies and all equipment that requires fuel.",
    },
  ];

  const scrollToBottom = () => {
    const windowHeight = window.innerHeight;
    const scrollY = window.scrollY;
    const scrollHeight = document.body.scrollHeight;
    const offsetFromBottom = 900; // Adjust this value to scroll to a desired position above the bottom
  
    const targetPosition = scrollHeight - windowHeight - offsetFromBottom;
  
    window.scrollTo({
      top: targetPosition,
      behavior: 'smooth' // Optional: smooth scrolling behavior
    });
  };
  

  return (
    <>
      <section className="inner-banner section-banner businessfleet">
        <div className="background-image">
          <VideoBlock path={pathname} />
        </div>
        <div className="container position-relative">
          <div className="row align-items-lg-center align-items-end">
            <div className="col-xl-7 col-lg-8 col-12">
              <div className="home-banner-texter text-start">
                <h1 className="wow fadeInUp" data-wow-delay="0.2s">
                  Automated Fueling For <br />
                  <span className="wow fadeInUp" data-wow-delay="0.4s">
                    Your Fleet
                  </span>
                </h1>
                <p className="wow fadeInUp" data-wow-delay="0.6s">
                  The Gas Station Should Come to you
                </p>
                <div className=" banner-actions d-flex align-items-center justify-content-start mt-2">
                  <button onClick={scrollToBottom}
                    
                    className="btn btn--primary wow fadeInUp px-4"
                    data-wow-delay="0.8s"
                  >
                    <span>Register Now </span>
                    <i className="ms-3">
                      <img
                        src={arrow}
                        alt="arrow"
                        width="10px"
                        className="d-flex wow"
                      />
                    </i>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-6"></div>
          </div>
        </div>
      </section>
      <Services
        serviceblock={serviceblock}
        IsSubHead={false}
        IsHead={true}
        IsHeadOurprocess={false}
      />
     <section className="common-section-all py-0">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 pb-0">
              <div className="heading-style text-center wow fadeInUp">
                <h5 className="text-capitalize">
                  Manage your entire{" "}
                  <span className="text-orange">Business Fleet</span> through
                  your <br /> personalized Gas It Up{" "}
                  <span className="text-orange">Fuel Management</span>
                </h5>
                <p className="mt-3">
                  Our Fuel Management System is designed to make your life
                  easier and your operation more efficient. By automating your
                  fuel <br /> management, you can save time and money while
                  gaining valuable insights into your operation. The system is
                  easy to use and <br />
                  can be customized to fit your specific needs.
                </p>
              </div>
              <div className="col-md-12 col-sm-12 dashboard-listing">
                <div className="row align-items-start justify-content-center listing-list flex-row-reverse">
                  <div className="col-lg-6 col-12 text-center mb-lg-0 mb-5 wow fadeInRight">
                    <DashboardSliderslide
                      DashboardSliderslid={DashboardSliderslid}
                    />
                  </div>
                  <div className="col-lg-6 col-12">
                    <ListListing />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all mb-3 mb-md-0 pt-0">
        <div className="container">
          <div className="col-md-12 px-lg-5 px-2 pb-0">
            <div className="heading-style text-center wow fadeInUp mb-3">
              <h5>Customer Testimonies</h5>
            </div>
          </div>
        </div>
        <Testimonial slidesData={slidesData} type={type} />
      </section>
      <section className="common-section-all pt-0 telematicdashboardcontact">
        <div className="container">
          <div className="col-md-12 px-lg-5 px-2 pb-0">
            <div className="heading-style text-center wow fadeInUp">
              <h5 className="text-capitalize">
                Get into contact with a{" "}
                <span className="text-orange">Gas It Up</span> professional that
                <br />
                can get your <span className="text-orange"> business</span> set
                up for <span className="text-orange"> success</span>
              </h5>
            </div>
          </div>

          <Contactedm
            contactbanner={contactbanner}
            type={type}
            showBusinessFields={true}
          />
        </div>
      </section>
    </>
  );
}

export default EDm;

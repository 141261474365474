import React from "react";
import BreadCrumb from "../BreadCrumb/BreadCrumb";
import CertifiedSupplier from "../CertifiedSupplier/CertifiedSupplier";
import Contact from "../Contactus/Contact";
import { certifiedbanner, careersbanner } from "../Imagesjs/Images";

function Investors() {
  const Investors = [
    {
      banner: certifiedbanner,
      title: "Always on  ",
      titlesub: "The Go",
      list1:
        "GIU will consistently collaborate with its partners and industry leaders to create curated features, benefits and promos that always engage our users and community.",
      list2:
        "Across the country, bills and regulations are being passed to guide the mobile-fueling industry. Especially in today’s environment, cities, corporations, and venues are looking for ways to keep things going while also being safe.",
      list3:
        "Our active partnerships in other markets throughout the country are just the beginning! Don’t get caught in the past, become a trailblazer that is changing the future of fueling!",
    },
  ];
  const CertifieIcons = [];
  const pagename = {
    name: "Investors",
  };
  return (
    <>
      <section className="inner-banner section-banner faq">
        <div className="background-image">
          <img src={careersbanner} alt="careers banner" />
        </div>
        <div className="container position-relative">
          <div className="row align-items-lg-center align-items-end">
            <div className="col-12  text-center">
              <BreadCrumb pagename={pagename} />
            </div>
            <div className="col-12">
              <div className="home-banner-texter text-center">
                <h1 className="wow fadeInUp" data-wow-delay="0.2s">
                  <span>Investors </span>
                </h1>
                <p className="wow fadeInUp" data-wow-delay="0.3s">
                  If you are interested in learning more about partnership
                  opportunities with Gas It Up or learning <br />
                  more about benefits GIU brings to you, contact us! We look
                  forward to hearing from you!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all pb-0">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 pb-0">
              <div className="heading-style text-center wow fadeInUp">
                <h5 className="text-capitalize">
                  Want more information about{" "}
                  <span className="text-orange">Gas It Up</span> and partnership{" "}
                  <br />
                  <span className="text-orange">opportunities?</span>
                </h5>
              </div>
            </div>
            <Contact type={16} showBusinessFields={false} />
          </div>
        </div>
      </section>
      <section className="py-5">
        <CertifiedSupplier
          certifiedblock={Investors}
          CertifieIcons={CertifieIcons}
        />
      </section>
    </>
  );
}

export default Investors;

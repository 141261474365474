import React from 'react'
import { contactus } from '../Imagesjs/Images'
import Contactus from './Contactus'
import "./Contactus.css"

function Contact(props) {
 
  const type = props.type;
  const contactbanner = contactus;
  return (
    <>
    
      <div className="col-12 ">
          <div className="row align-items-start contact-form mx-0 getfleetqouteblock">
            <div className="col-xl-6 col-lg-12 wow fadeInLeft px-0 ">
              <figure><img src={props.contactbanner?props.contactbanner:contactbanner} alt="contactus" className="img-fluid w-100" height="100%" width="100%" /></figure>
            </div>
            <div className="col-xl-6 col-lg-12 wow fadeInRight px-lg-5 px-3 getfleetqouteform">
              <Contactus  type={type} showBusinessFields={props.showBusinessFields} />
            </div>
          </div>
      </div>
    </>
  )
}

export default Contact
import React from 'react';
import { Route, Switch } from 'react-router-dom';
// import { BrowserRouter, Routes, Route } from "react-router-dom";
import Privacypolicy from './Policies/Privacypolicy';
import Termsandcondition from './Policies/Termsandcondition';
import Careers from './Careers/Careers';
import Faq from './Faq/Faq';
// import Home from '../Components/Home/Home.js';
import Home from './Home/Home'
import AtonomonusFleet from './WhatWeDo/AtonomonusFleet/AtonomonusFleet';
import DeselAndDEF from './WhatWeDo/DeselAndDEF/DeselAndDEF';
import EnvironmentImpact from './WhatWeDo/EnvironmentImpact/EnvironmentImpact';
import GasOnline from './WhatWeDo/GasOnline/GasOnline';
import Geofencing from './WhatWeDo/Geofencing/Geofencing';
import OurFuelTecsSefaty from './WhatWeDo/OurFuelTecsSefaty/OurFuelTecsSefaty';
import StateOfArtFleet from './WhatWeDo/StateOfArtFleet/StateOfArtFleet';
import TelematicDashboard from './WhatWeDo/TelematicDashboard/TelematicDashboard';
import FuelingTheFuel from './WhoWeAre/FuelingTheFuel/FuelingTheFuel';
import LeaderShipTeam from './WhoWeAre/LeaderShipTeam/LeaderShipTeam';
import OurProcess from './WhoWeAre/OurProcess/OurProcess';
import BusinessFleet from './WhoWeFuel/BusinessFleet/BusinessFleet'
import CorporateContact from './WhoWeFuel/CorporateContact/CorporateContact';
import RetailSurface from './WhoWeFuel/RetailSurface/RetailSurface';
import News from './News/News';
import Investors from './Investors/Investors';
import ViewBlog from './ViewBlog/ViewBlog';
import BlogDetail from './BlogDetail/BlogDetail';
import GetAFleet from './GetAFleet/GetAFleet';
import ScrollRestoration from 'react-scroll-restoration'
import ChargeItUp from './ChargeItUp/ChargeItUp';
import Error from './404Error/404Error';
// import Edmpa from './WhoWeFuel/EdmpA/Edmpa'
import Edmpa from './WhoWeFuel/EdmpA/EdmpA'
const Main = (props) => {
  return (
    <>
      <ScrollRestoration />
      <Switch>
        <Route exact path='/' render={() => <Home theme={props.theme} />} />
        {/* <Route exact path='/' component={Home} /> */}
        <Route path='/edm-pa' render={() => <Edmpa theme={props.theme} />} />
        <Route path='/business-fleets-trucking-and-construction-companies' render={() => <BusinessFleet theme={props.theme} />} />
        <Route path='/corporate-and-college-campuses-stadiums-and-arenas' render={() => <CorporateContact theme={props.theme} />} />
        <Route path='/retail-and-rurface-parking-lots' component={RetailSurface} />
        <Route path='/fuel-management-software' component={TelematicDashboard} />
        <Route path='/diesel-on-demand' component={DeselAndDEF} />
        <Route path='/our-fuel-techs-and-sefaty' render={() => <OurFuelTecsSefaty theme={props.theme} />} />
        <Route path='/fleets' component={StateOfArtFleet} />
        <Route path='/gasoline' component={GasOnline} />
        <Route path='/environmental-impact' render={() => <EnvironmentImpact theme={props.theme} />} />
        <Route path='/geofencing' component={Geofencing} />
        <Route path='/autonomous-fleet-equipment' render={() => <AtonomonusFleet theme={props.theme} />} />
        <Route path='/leaderShip-team' component={LeaderShipTeam} />
        <Route path='/our-process' render={() => <OurProcess theme={props.theme} />} />
        <Route path='/fueling-the-future' render={() => <FuelingTheFuel theme={props.theme} />} />
        <Route path='/faq' component={Faq} />
        <Route path='/privacy-policy' component={Privacypolicy} />
        <Route path='/termsandcondition' component={Termsandcondition} />
        <Route path='/careers' component={Careers} />
        <Route path='/news' component={News} />
        <Route path='/investors' component={Investors} />
        <Route path='/blog' component={ViewBlog} />
        <Route path='/blogdetail' component={BlogDetail} />
        <Route path='/getafleet' component={GetAFleet} />
        <Route path='/chargeitup' render={() => <ChargeItUp theme={props.theme} />} />
        <Route path='**' render={() => <Error theme={props.theme} />} />
      </Switch>
    </>
  );
}

export default Main;
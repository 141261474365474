import React from "react";
import BreadCrumb from "../BreadCrumb/BreadCrumb";
import { careersbanner } from "../Imagesjs/Images";
import "./Policies.css";
function Termsandcondition() {
  const pagename = {
    name: "Terms & Conditions",
  };
  return (
    <>
      <section className="inner-banner section-banner faq">
        <div className="background-image">
          <img src={careersbanner} alt="careers banner" />
        </div>
        <div className="container position-relative">
          <div className="row align-items-lg-center align-items-end">
            <div className="col-12  text-center">
              <BreadCrumb pagename={pagename} />
            </div>
            <div className="col-12">
              <div className="home-banner-texter text-center">
                <h1 className="wow fadeInUp" data-wow-delay="0.2s">
                  <span>Terms &amp; </span>
                  <span
                    className="wow fadeInUp text-orange"
                    data-wow-delay="0.4s"
                  >
                    Conditions
                  </span>
                </h1>
                <p className="wow fadeInUp" data-wow-delay="0.3s">
                Terms of Use<br/>
                Last Updated March 26, 2020
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all policy-page">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-8">   
              <p>
                {" "}
                These Terms of Use form the binding agreement (the “Agreement”)
                that governs the relationship between Gas It Up Inc. (the
                “Company”; also referred to herein using the terms “we,” “us,”
                or “our”) and you (the “Customer”; also referred to herein using
                the terms “you” and “your”) with respect to your use of the
                Company’s mobile refueling application and associated services
                (the “Services”). Company and Customer may each be referred to
                herein as a “Party,” or collectively as the “Parties.” This
                Agreement effective as of the date last updated, as written
                above, and may be modified at any time by the Company. Your
                continued use of the Services constitutes your consent to the
                Terms of Service in place at the time that you utilize our
                Services.
              </p>
              <h4> 1. Grant of Limited License. </h4>
              <p>
                Subject to the terms of this Agreement, we hereby grant you a
                limited, revocable license (the “License”) to download, access
                and use the Gas It Up application (the “Licensed Application”)
                and any content offered through the Licensed Application on your
                mobile device in order to personally utilize the Services. The
                License granted hereunder is limited to the rights expressly
                granted in this Agreement, is non-exclusive, and may not be
                sublicensed, assigned, or transferred without the prior written
                consent of the Company.{" "}
              </p>
              <h4> 2. Provision and Limitations of Services. </h4>
              <p>
                {" "}
                a) Required Approvals. The location(s), schedules, timing, and
                windows for delivery of the Services are contingent on Company’s
                receipt of approval by all local authorities having jurisdiction
                over mobile fueling operations in the locality where deliveries
                to Customer are to be made under this Agreement. If regulatory
                limitations prevent us from satisfying any of the obligations
                set forth herein, such obligations shall be deemed to be waived
                by the Customer. The Services set forth in this Agreement shall
                not commence at Customer’s desired delivery location(s) until
                the Company has received all necessary approvals to conduct
                mobile fueling activities at such location, and such Services
                shall be conducted only to the extent allowed by such permits or
                approvals.{" "}
              </p>
              <h4> 3. Fees &amp; Payment Terms. </h4>
              <p>
                {" "}
                a) Payment. With respect to transactions between the Company and
                you, any fuel charges, taxes, fees (including credit card
                transaction fees), and penalties will be charged directly to
                your selected payment method on file at the time of delivery or
                attempted delivery. All payments are non-refundable. You hereby
                agree to electronic receipts delivered through the Company’s
                mobile application or by email. You may receive a printed
                receipt at your request by contacting the company at
                support@gasitup.com.{" "}
              </p>
              <p>
                {" "}
                b) Taxes. Any use tax, sales tax, excise tax, duty, or any other
                governmental tax, fee or charge related to the transaction
                between you and us (other than taxes based on our income) shall
                be paid by you.{" "}
              </p>
              <h4> 4. Information Gathering. </h4>
              <p>
                {" "}
                a) You hereby acknowledge that the Company utilizes geolocation
                in order to provide the Services to you, and your continued use
                of the Licensed Application constitutes your consent to the use
                of such geolocation. If geolocation services are turns off the
                Services may be unavailable or diminished. You also acknowledge
                that use of the Services requires you to give certain personal
                information to the Company, including your name, payment card
                information, and identifying vehicle information (including VIN
                number, license plate number, make, model, and year). The
                accuracy of any such information that you provide is your sole
                responsibility.{" "}
              </p>
              <h4> 5. Disclaimer &amp; Limitation of Liability. </h4>
              <p>
                {" "}
                a) Disclaimer. THE SERVICES ARE PROVIDED AS-IS, AND CUSTOMER
                AGREES, TO THE MAXIMUM EXTENT PERMISSIBLE BY APPLICABLE LAW, TO
                ASSUME ALL RISK RELATED TO THE SERVICES OR THE PROVISION THEROF.
                EXCEPT AS EXPRESSLY SET FORTH IN THIS AGREEMENT, WE HEREBY
                DISCLAIM ALL REPRESENTATIONS AND WARRANTIES, EXPRESS, IMPLIED,
                OR STATUTORY, INCLUDING ALL IMPLIED WARRANTIES OF
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
                NON-INFRINGEMENT OF RIGHTS. ADDITIONALLY, WE MAKE NO
                REPRESENTATIONS, WARRANTIES, OR GUARANTEES REGARDING THE
                TIMELINESS, RELIABILITY, QUALITY, SUITABILITY, OR SAFETY OF THE
                SERVICES OR OF ANY GOODS REQUESTED OR PROVIDED THROUGH THE USE
                OF THE SERVICES.{" "}
              </p>
              <p>
                {" "}
                b) Limitation of Liability. TO THE MAXIMUM EXTENT PERMITTED BY
                APPLICABLE LAW, IN NO EVENT SHALL WE BE LIABLE UNDER ANY LEGAL
                THEORY OR FORM OF ACTION (INCLUDING BUT NOT LIMITED TO CONTRACT,
                NEGLIGENCE, STRICT LIABILITY IN TORT OR WARRANTY OF ANY KIND)
                FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY,
                OR PUNITIVE DAMAGES (INCLUDING BUT NOT LIMITED TO LOST PROFITS,
                LOST DATA, COST OF SUBSTITUTE GOODS, PERSONAL INJURY, PROPERTY
                DAMAGE, OR OTHER ECONOMIC DAMAGES) ARISING OUT OF OR RELATED TO
                THE SERVICES OR THE PROVISION THEREOF, OR OTHERWISE RELATING TO
                THIS AGREEMENT, EVEN IF WE HAD NOTICE OF THE POSSIBILITY OF SUCH
                DAMAGES. WE SHALL NOT BE LIABLE FOR ANY DAMAGES, LOSSES OR COSTS
                RESULTING FROM OR ASSOCIATED WITH USE OF THE SERVICES BY YOU,
                PROVISION OF THE SERVICES BY US, LATE DELIVERY OR UNAVAILABILITY
                OF THE SERVICES, GOODS PROCURED DURING THE COURSE OF THE
                SERVICES, OR THE PROCUREMENT OF SUBSTITUTE SERVICES AND GOODS OR
                SERVICES. WE SHALL NOT BE LIABLE FOR ANY DAMAGES TO VEHICLES
                UNLESS SUCH DAMAGES WERE CAUSED BY OUR INTENTIONAL ACTS. WE
                SHALL NOT BE LIABLE FOR ANY LOSS, DAMAGE, PENALTY OR OTHER
                INJURY AS A RESULT OF ANY DELAY OR FAILURE DUE TO ANY CAUSE
                BEYOND OUR REASONABLE CONTROL, INCLUDING WITHOUT LIMITATION ACTS
                OF NATURE. IN NO EVENT SHALL OUR TOTAL LIABILITY FOR ANY AND ALL
                CLAIMS ARISING OUT OF OR RELATED TO THE SERVICES, OR OTHERWISE
                RELATING TO THIS AGREEMENT, EXCEED THE AMOUNTS PAID BY YOU TO US
                DURING THE THREE (3) MONTHS IMMEDIATELY PRECEDING THE CAUSE OF
                ACTION GIVING RISE TO POTENTIAL LIABILITY. THIRD PARTIES NOT
                PREVIOUSLY APPROVED BY US SHALL NOT BE PERMITTED TO USE THE
                SERVICES PURSUANT TO THIS AGREEMENT, AND YOU SHALL BE SOLELY
                LIABLE FOR ANY UNAUTHORIZED USE BY THIRD PARTIES, INCLUDING
                LIABILITY FOR ANY DAMAGES, LOSSES, OR OTHER COSTS ARISING OUT OF
                OR RELATED TO THE USE OF THE SERVICES BY SUCH THIRD PARTIES.{" "}
              </p>
              <h4> 6. Termination. </h4>
              <p>
                The Company may immediately terminate your ability to use the
                Services if you materially breach any of the provisions of this
                Agreement.
              </p>
              <br />
              <h4> 7. Indemnity. </h4>
              <p>
                You shall indemnify and hold harmless the Company and its
                affiliates and suppliers, and its and their respective officers,
                directors, employees and agents, from and against any and all
                demands, claims, actions, causes of action, proceedings, suits,
                assessments, losses, damages, liabilities, judgments, fines,
                penalties, settlements, interest, cost and expenses (including
                attorneys’ fees) arising out of or in connection with or
                relating to: (i) your negligence, recklessness, or misconduct,
                (ii) your violation of applicable laws, rules or regulations,
                (iii) your breach of this Agreement; (iv) personal injury or
                property damage caused by your acts or omissions; (v) your
                violation of the rights of any third party, and/or any dispute
                between you and any third party; and (vi) access to, alleged use
                of, or use of the Services or any goods obtained by way of the
                Services by you. You shall defend us from such claims at our
                exclusive direction and at your expense, and you agree to fully
                cooperate in the defense of such claim. You shall notify us
                promptly of any incident involving Services resulting in
                personal injury or damage to property, and you shall fully
                cooperate with us in the investigation of such incident.
              </p>
              <br />
              <h4> 8. Access and Safety. </h4>
              <p>
                {" "}
                If there is any dispute or determination required as to whether
                a vehicle can be safely accessed in accordance with all
                applicable laws and regulations, such determination shall be
                resolved in the sole and absolute discretion of Company.
                Customer will ensure that its vehicles are parked in a manner
                that will allow our drivers safe and suitable access to your
                vehicle.{" "}
              </p>
              <br />
              <h4> 9. App Store Notice </h4>
              <p>
                {" "}
                a) Notwithstanding anything herein to the contrary, the limited
                License and your use of the Licensed Application remain subject
                to the terms of use for the app store or platform from which the
                Licensed Application was downloaded. You agree to comply with
                any applicable app store or platform terms of use, as well as
                any applicable third-party terms (such as wireless data service
                agreements) at all times while using the Licensed Application or
                the Services.{" "}
              </p>
              <p>
                {" "}
                b) If the License Application has been downloaded from the Apple
                Inc. (“Apple”) App Store, this Section 8(b) will apply to all
                use of the Service by you. The Parties acknowledge that this
                Agreement is entered into solely between the Parties (and not
                with Apple) and that we are solely responsible for the Licensed
                Application and the content thereof. Apple is not responsible
                for providing any maintenance or support services for the
                Licensed Application, whether under this Agreement or under any
                applicable law, and to the extent that any maintenance or
                support services are legally required, such services are our
                sole responsibility. To the extent not expressly disclaimed
                herein, we are responsible for any warranty (express or
                implied), and in the event that the Licensed Application or
                Services fail to conform to any applicable warranty, you may
                notify Apple, and they will refund the purchase price (if any)
                for the Licensed Application to you. To the maximum extent
                permitted by applicable law, Apple will have no other warranty
                obligations with respect to the Licensed Application, and any
                other claims, losses, liabilities, damages, costs or expenses
                attributable to any failure to conform to any warranty will be
                our sole responsibility. In the event of any third-party claim
                that the Licensed Application or your possession and/or use
                thereof infringes on such third-party's intellectual property
                rights, we (and not Apple) will be solely responsible for the
                investigation, defense, settlement and discharge of any such
                claim. Both the Company and you hereby acknowledge that the
                Company, not Apple, is responsible for addressing any claims
                made by you or any third party relating to the Licensed
                Application or your possession and/or use thereof, including,
                but not limited to: (i) product liability claims; (ii) any claim
                that the Licensed Application fails to conform to any applicable
                legal or regulatory requirement; and (iii) claims arising under
                consumer protection, privacy, or similar legislation. Both the
                company and you hereby acknowledge and agree that Apple and its
                subsidiaries are third party beneficiaries of the Agreement, and
                that upon your acceptance of the terms of this Agreement, Apple
                will have the right (and will be deemed to have accepted the
                right) to enforce this Agreement against you in its capacity as
                a third party beneficiary thereof. You hereby represent and
                warrant that (i) you are not located in a country that is
                subject to a U.S. Government embargo, or that has been
                designated by the U.S. Government as a “terrorist supporting”
                country; and (ii) you are not listed on any U.S. Government list
                of prohibited or restricted parties.{" "}
              </p>
              <h4> 10. Miscellaneous </h4>
              <p>
                {" "}
                a) Severability of this Agreement. If any provision of this
                Agreement is found by a proper authority to be unenforceable or
                invalid, such unenforceability or invalidity shall not render
                this Agreement unenforceable or invalid as a whole and such
                unenforceable or invalid provision shall be changed and
                interpreted so as to best accomplish the objectives of such
                provision within the limits of applicable laws.{" "}
              </p>
              <p>
                {" "}
                b) Conflict Resolution &amp; Arbitration. Any dispute,
                controversy or claim arising out of or relating to this
                Agreement or to a breach hereof, including its interpretation,
                performance or termination shall be finally resolved on an
                individual basis first by good faith negotiations, failing
                which, then by binding arbitration. All arbitration, including
                the rendering of the award, shall be conducted in accordance
                with the commercial arbitration rules and procedures adopted by
                American Arbitration Association (“AAA”), in New York, New York,
                which shall be the exclusive forum for resolving such dispute,
                controversy or claim. The decision of the arbitrators shall be
                final, and binding and judgment thereon may be entered by any
                court of competent jurisdiction. If for any reason this Section
                11(c) is found to be unenforceable, the Parties hereby agree
                that the state and federal courts located in the state of New
                York have exclusive jurisdiction over any dispute, controversy
                or claim arising out of or relating to this Agreement or to a
                breach hereof, and the parties hereby submit to the exclusive
                venue of the courts located in New York, New York.{" "}
              </p>
              <p>
                {" "}
                c) Choice of Law. This Agreement will be subject to and be
                interpreted in accordance with the laws of the State of New
                York, without regard to its choice or conflicts of laws
                provisions. The courts presiding in the state of New York will
                have exclusive jurisdiction and shall be the exclusive venue to
                adjudicate any dispute arising out of this Agreement, and both
                parties irrevocably consent to the personal jurisdiction of such
                courts.{" "}
              </p>
              <p>
                {" "}
                d) Infringement of Intellectual Property. Notwithstanding the
                foregoing, we may enforce judgments or seek injunctive relief
                for an infringement or threatened infringement of our
                intellectual property rights in any court of competent
                jurisdiction. Such remedies are not exclusive and are in
                addition to all other remedies that may be available at law, in
                equity or otherwise.{" "}
              </p>
              <p>
                {" "}
                e) Nonwaiver of Rights. No waiver of any term, provision or
                condition of this Agreement whether by conduct or otherwise in
                any one or more instances will be deemed to be construed as a
                further or continuing waiver of such term, provision or
                condition or of any other term, provision or condition of this
                Agreement. Additionally, no failure to exercise, or delay in
                exercising, any rights, remedy, power, or privilege arising from
                this Agreement will operate or be construed as a waiver thereof.{" "}
              </p>
              <p>
                {" "}
                f) Our Relationship with You. The Company is an independent
                contractor, and neither of us shall be considered employees,
                agents, partners, franchisees, owners, joint ventures or
                representatives of the other.{" "}
              </p>
              <p>
                {" "}
                g) Written Notices &amp; Consents. Any notices or consents under
                this Agreement to either Party must be in writing, and (i) in
                the case of notice to the Company, sent by certified mail to Gas
                It Up Inc., 1266 East Main Street, Suite 700R, Stamford, CT
                06902, effective upon receipt, or (ii) in the case of notice to
                the Customer, sent by email to the email address last associated
                with your account, effective 24 hours after sending.{" "}
              </p>
              <p>
                {" "}
                h) Entire Agreement. This Agreement, together with our Privacy
                Policy, constitutes the entire agreement between us relating to
                the subject matter contained herein and supersedes any prior
                agreements and understandings, whether oral or written, between
                us with respect to the Services.{" "}
              </p>
              <p>
                {" "}
                i) Amendments. The Company may amend the Privacy Policy and
                Terms of Service from time to time in its sole discretion.
                Amendments to these policies shall become effective as of the
                date first published, and your continued use of the Service
                thereafter constitutes your acceptance of these amendments.{" "}
              </p>
              <p>
                {" "}
                j) Successors &amp; Assigns. This Agreement shall be binding on
                and inure to the benefit of the parties hereto and their
                respective heirs, executors, administrators, legal
                representatives, successors and permitted assigns. This
                Agreement may not be assigned by Customer without the prior
                written consent of the Company. Any purported assignment in
                violation of this section shall be null and void.{" "}
              </p>
              <p>
                {" "}
                k) Survival: Termination or expiration of this Agreement will
                not release Customer from any obligation or liability accrued
                prior to such termination or expiration.{" "}
              </p>
              <p>
                {" "}
                If you have any questions about this Agreement or the Services
                provided by the company, or complaints or claims about the
                Services, you may contact Gas It Up Inc. at <a href="mailto:support@gasitup.com" >support@gasitup.com</a>{" "}
              </p>
              <br />
              <br />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Termsandcondition;

import React from "react";
import BreadCrumb from "../BreadCrumb/BreadCrumb";
import Accordion from "react-bootstrap/Accordion";
import "./Faq.css";
import {
  activelocation,
  careersbanner,
  ciu,
  leadershipcontactbanner,
  upcominglocation,
} from "../Imagesjs/Images";
import Contact from "../Contactus/Contact";
import Contactus from "../Contactus/Contactus";
import GetAFleetQoute from "../GetAFleetQoute/GetAFleetQoute";
import GMap from "../../GMap";
import { useState } from "react";
import { useEffect } from "react";
import Playstoreicons from "../Playstoreicons/Playstoreicons";

function Faq() {
  const contactbanner = leadershipcontactbanner;
  const pagename = {
    name: "FAQ",
  };

  const [filtermarker, setfiltermarker] = useState([]);

  const activelocationarr = [
    {
      lat: 32.776665,
      long: -96.796989,
      marker: activelocation,
    },
    {
      lat: 42.331429,
      long: -83.045753,
      marker: activelocation,
    },
    {
      lat: 39.739235,
      long: -104.99025,
      marker: activelocation,
    },
    {
      lat: 39.952583,
      long: -75.165222,
      marker: activelocation,
    },
    {
      lat: 40.825661,
      long: -74.108521,
      marker: activelocation,
    },
    {
      lat: 40.741895,
      long: -73.989308,
      marker: activelocation,
    },
    {
      lat: 40.69243621826172,
      long: -73.99037170410156,
      marker: activelocation,
    },
    {
      lat: 32.2228765,
      long: -110.9748477,
      marker: activelocation,
    },
    {
      lat: 40.22140121459961,
      long: -74.77140045166016,
      marker: activelocation,
    },
    {
      lat: 40.2658805847168,
      long: -76.88015747070312,
      marker: activelocation,
    },
  ];

  const ciuarr = [
    {
      lat: 27.7567667,
      long: -81.4639835,
      marker: ciu,
    },
    {
      lat: 38.7251776,
      long: -105.607716,
      marker: ciu,
    },
    {
      lat: 36.7014631,
      long: -118.755997,
      marker: ciu,
    },
    {
      lat: 43.6211955,
      long: -84.6824346,
      marker: ciu,
    },
    {
      lat: 31.2638905,
      long: -98.5456116,
      marker: ciu,
    },
    {
      lat: 40.741895,
      long: -73.989308,
      marker: ciu,
    },
  ];

  const upcominglocationarr = [
    {
      lat: 38.97919845581055,
      long: -76.49140167236328,
      marker: upcominglocation,
    },
    {
      lat: 38.89543914794922,
      long: -77.03128051757812,
      marker: upcominglocation,
    },
    {
      lat: 27.947895050048828,
      long: -82.45733642578125,
      marker: upcominglocation,
    },
    {
      lat: 25.770843505859375,
      long: -80.19763946533203,
      marker: upcominglocation,
    },
    {
      lat: 28.537979125976562,
      long: -81.37873840332031,
      marker: upcominglocation,
    },
    {
      lat: 30.2711286,
      long: -97.7436995,
      marker: upcominglocation,
    },
    {
      lat: 29.424585342407227,
      long: -98.49463653564453,
      marker: upcominglocation,
    },
    {
      lat: 31.76162338256836,
      long: -106.48563385009766,
      marker: upcominglocation,
    },
    {
      lat: 29.7603759765625,
      long: -95.36980438232422,
      marker: upcominglocation,
    },
    {
      lat: 33.4484367,
      long: -112.074141,
      marker: upcominglocation,
    },
  ];

  useEffect(() => {
    setfiltermarker([...ciuarr, ...upcominglocationarr, ...activelocationarr]);
  }, []);

  const accordion = [
    {
      title: "What is an authorization hold?",
      des: "GIU delivers varying amounts of goods and services before collecting payment. Like those industries, GIU will issue an authorization hold(in most cases, a $$ hold) before dispatching a truck or van to fulfill service to our customers.",
    },
    {
      title: "How much will this cost me?",
      des: "Our fuel prices are comparable with local gas stations. A service fee may apply depending on your area. Check the app or Panel for pricing in your area.",
    },
    {
      title: "How do locate my car?",
      des: "We locate your car based on the vehicle’s description, tag and location data you provide in our app.",
    },
    {
      title: "How do you put gas in car?",
      des: "Our three-step process for ordering gas includes you leaving your gas door ajar. Once we fuel you up, we will alert you via text. We’ll also close your gas door once we finish.",
    },
    {
      title:
        "Why is there charge on my bank statement when I haven't received service?",
      des: "When GIU customers enter their credit card information, GIU will issue a $1 verification charge that is immediately refunded. The evening before a customer's service, GIU will issue a larger authorization hold (often $) from which the customer's final amount will be captured.",
    },
    {
      title: "How do I get the Gas It Up app?",
      des: "The Gas It Up App can be downloaded from the Google PlayStore or Apple AppStore by searching for 'Gas It Up'.",
    },
    {
      title: "What is Gas It Up?",
      des: "Gas It Up is a mobile-app based refueling service. We have a very flexible and fully mobile last mile infrastructure specially designed for retail gas refueling. It's simple. Become a customer, a few clicks, and we are on our way to deliver fuel to you. On Demand. Anytime. Anywhere.",
    },
    {
      title: "How do you know where my car is?",
      des: "You’re asked to provide the delivery address at the time of booking. Besides that, we also keep some basic information about your car handy: make, color, model, year and license plate number, etc.",
    },
    {
      title: "Can you open my gas tank if I forget to?",
      des: "Your team should be able to answer this question we develop the technology. Explaining vehicle below 2018 will have to leave the gas tank on lock and vehicle from 2018 and up I have the option to connect to connect car.",
    },
    {
      title: "How are gas prices determined?",
      des: "Our gas prices aren’t static. You can check our local current gas prices on the app’s home screen. We aim to offer lower prices than the average gas station in the delivery area.",
    },
    {
      title: "Do I have to have a Gas It Up subscription?",
      des: "Our aim is to charge a lower price per gallon for Regular and Premium gas in comparison to the local gas station prices. A monthly membership provides many benefits, including unlimited fuel deliveries. You are welcome to pay using Google Pay, Apple Pay, or a Credit or Debit card of your choice.",
    },
    {
      title: "Why should I get a Gas It Up subscription?",
      des: "Automate your fuel request.",
    },
    {
      title: "When do I pay and get a receipt?",
      des: "After filling up your vehicle, you can pay through our mobile app as well as on our website. We will send an email receipt that contains information regarding the amount of gallons, price per gallon, and the respective dollar amount if processed on your credit card.",
    },
    {
      title: "What if I need to cancel an order?",
      des: "You can cancel your request using the app. However, if you cancel once the driver reaches the destination, you will be charged the current delivery fee.",
    },
    {
      title: "Where is Gas It Up available?Add map",
      des: "Currently, Gas It Up is available in the New York area",
    },
    {
      title: "Can Gas It Up fuel my boat? Motorcycle?",
      des: "Yes, we will deliver gas to your boat or watercraft provided there is car access near the dock. Prior to scheduling a delivery, ensure your boat takes regular 87 or premium 93 gas.",
    },
    {
      title: "Can Gas It Up fuel my boat? Motorcycle?",
      des: "Yes, we will deliver gas to your boat or watercraft provided there is car access near the dock. Prior to scheduling a delivery, ensure your boat takes regular 87 or premium 93 gas.",
    },
    {
      title: "Will Gas It Up fuel at more stadiums and arenas?",
      des: "Yes as we continue to grow.",
    },
    {
      title: "Hours of operation: 24/7",
      des: "Our services are available 24*7.",
    },
    {
      title: "Can GIU unlock my gas tank?",
      des: "Yes, technology to do this coming",
    },
    {
      title: "Will GIU fuel my cars at my house?",
      des: "No",
    },
    {
      title:
        "If gas it up is not currently in my area, where do I sign up to get notified when they are? ",
      form: <Contactus />,
    },
    {
      title: "Can I use Charge It Up services yet?  ",
      des: "Charge It Up will be launched in 2023. ",
    },
    {
      title: "Where is GIU currently servicing?",
      form: <GMap filtermarker={filtermarker} />,
    },
    {
      title: "When will the Charge It Up app be launched? ",
      des: "Second quarter of 2023",
    },
    // {
    //   title: "Will I have direct contact with the fuel technician fueling my car/ fleet? ",
    //   des: "Lorem ipsum",
    // },
    {
      title: "Who can access the fuel management system?",
      des: "Business and fleet owners. ",
    },
    {
      title:
        "How many people can login to one fuel management system profile? ",
      des: "As many as the admin create.",
    },
    {
      title: "What fleet sizes does GIU service? ",
      des: "We service businesses and fleet operations of all sizes! ",
    },
    {
      title: "How do I learn more about partnerships with GIU?  ",
      form: <Contactus />,
    },
    {
      title: "How do I download Gas It Up? ",
      form: <Playstoreicons />,
    },
    {
      title: "How do I sign my fleet up for scheduled fueling? ",
      form: <GetAFleetQoute />,
    },
    {
      title: "What stadiums does Gas It Up currently service at?",
      des: "Denver Broncos, MileHigh Stadium at Empower Field and the Philadelphia eagles at Lincoln Financial Field ",
    },
    {
      title:
        "Does GIU partner with business, colleges/ universities, etc. even if they don’t have a fleet operation? ",
      des: "Yes! We fuel everyday commuters in surface parking lots and partner with businesses and colleges to bring their employers, fans, and students the benefits! ",
    },
    {
      title: "Can I schedule fuel for my personal car?",
      des: "Yes, you can order it immediately or schedule a fueling time. ",
    },
    {
      title: "Where can Gas it Up deliver? ",
      des: "Retail and surface lots that are partnered with Gas It Up ",
    },
    // {
    //   title: "What if my tank is locked?",
    //   des: "lorum ipsum",
    // },
    // {
    //   title: "How is pricing determined? ",
    //   des: "lorum ipsum",
    // },
    // {
    //   title: "how do I get a receipt?",
    //   des: "lorum ipsum",
    // },
    {
      title: "Does GIU deliver to corporate office parks? ",
      form: <Contactus />,
    },
    {
      title: "What kind of fuel do you provide?",
      des: "Unleaded Regular and Premium - Red Diesel (non-taxable fuel for off-road machinery) and Clear Diesel ",
    },
    {
      title: "Can you deliver to my boat?",
      des: "Yes, if we are permitted by your specific marina then we can deliver fuel to your boat. ",
    },
    {
      title: "What are your hours of operation? ",
      des: "GIU hours of operation are dependent on your businesses or fleets operation hours - we coordinate and schedule services together. ",
    },
  ];
  return (
    <>
      <section className="inner-banner section-banner faq">
        <div className="background-image">
          <img src={careersbanner} alt="careers banner" />
        </div>
        <div className="container position-relative">
          <div className="row align-items-lg-center align-items-end">
            <div className="col-12  text-center">
              <BreadCrumb pagename={pagename} />
            </div>
            <div className="col-12">
              <div className="home-banner-texter text-center">
                <h1 className="wow fadeInUp" data-wow-delay="0.2s">
                  <span>Frequently Asked </span>
                  <span
                    className="wow fadeInUp text-orange"
                    data-wow-delay="0.4s"
                  >
                    Questions
                  </span>
                </h1>
                {/* <p className="wow fadeInUp" data-wow-delay="0.3s">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem
                  <br /> Ipsum has been the industry's
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-8 col-12">
              <Accordion defaultActiveKey="1">
                {accordion.map((accro, i) => (
                  <Accordion.Item
                    eventKey={i}
                    className="wow fadeInUp"
                    data-wow-delay={".0" + i + "s"}
                    key={i}
                  >
                    <Accordion.Header>
                      <span className="text-capitalize">{accro.title}</span>
                    </Accordion.Header>

                    {accro?.form ? (
                      <Accordion.Body className="contact-form getfleetqouteblock px-4">
                        <div className="getfleetqouteform">{accro?.form}</div>
                      </Accordion.Body>
                    ) : (
                      <Accordion.Body>{accro.des}</Accordion.Body>
                    )}
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all pt-0">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 pb-0">
              <div className="heading-style text-center wow fadeInUp">
                <h5 className="text-capitalize">
                  Get into contact with a{" "}
                  <span className="text-orange">Gas It Up</span> professional
                  that
                  <br /> can get your{" "}
                  <span className="text-orange"> business</span> set up for{" "}
                  <span className="text-orange"> success</span>
                </h5>
              </div>
            </div>
            <Contact
              type={1}
              showBusinessFields={false}
              contactbanner={contactbanner}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default Faq;

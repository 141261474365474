import React from "react";
import { check } from "../Imagesjs/Images";
import "./Calculator.css";
import { Range, getTrackBackground } from "react-range";
const STEP = 200;
const MIN = 0;
const MAX = 20000;
const STEP1 = 1;
const MIN1 = 0;
const MAX1 = 100;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      values: [0],
      values1: [0],
      co2NumberOfVehicles: [0],
      co2NumberOfVehiclessecond: [0],
      co2NumberOfTimes: [0],
      carbonDioxideReducedEachYear: [0],
      hero: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChange1 = this.handleChange1.bind(this);
    this.completeChange = this.completeChange.bind(this);
  }
  handleChange(e) {
    this.setState({ values: e });
    this.setState({ co2NumberOfVehicles: e / 200 });
    this.setState({
      co2NumberOfVehiclessecond: e,
    });
    this.updatedata();
  }
  handleChange1(e) {
    this.setState(
      {
        values1: e,
        co2NumberOfTimes: e,
      },
      () => this.updatedata()
    );
  }
  completeChange(event) {
    this.setState({ values: [0] });
    this.setState({ values1: [0] });
    this.setState(
      {
        hero: event.target.value === "true",
      },
      () => {}
    );
    this.setState({ carbonDioxideReducedEachYear: "0" });
    this.setState({ co2NumberOfTimes: "0" });
    this.setState({ co2NumberOfVehiclessecond: "0" });
  }
  handleChange3(e) {
    const value = e.target.value;
    setTimeout(() => {
      if (value > 20000) {
        return;
      }
      this.setState(
        {
          values: [value],
          co2NumberOfVehicles: value / 200,
          co2NumberOfVehiclessecond: (value / 200) * 200,
        },
        () => this.updatedata()
      );
    }, 0);
  }

  handleChange4(e) {
    var t = e.target.value;
    setTimeout(() => {
      if (t > 100) {
        return;
      }
      this.setState({ values1: [t] });
      this.setState({ co2NumberOfTimes: [t] }, () => this.updatedata());
    }, 0);
  }

  updatedata() {
    if (this.state.hero === true) {
      this.setState({
        carbonDioxideReducedEachYear: (
          this.state.co2NumberOfTimes *
          52 *
          this.state.co2NumberOfVehiclessecond *
          9.0
        ).toLocaleString(),
      });
    } else {
      this.setState({
        carbonDioxideReducedEachYear: Math.round(
          this.state.co2NumberOfTimes *
            52 *
            this.state.co2NumberOfVehiclessecond *
            1.4
        ).toLocaleString(),
      });
    }
  }
  render() {
    return (
      <section className="common-section-all pb-0" id="savingcal">
        <div className="container">
          <div className="heading-style text-center wow fadeInUp">
            <h5>
              CO<sub>2</sub>
              <span className="text-orange"> Calculator</span>
            </h5>
            <p>
              Each time you have Diesel Direct go on-site to fuel your trucks,
              less distance is driven, less fuel is burned and less carbon
              dioxide is released!
            </p>
          </div>
          <div className="col-lg-9 col-11 mx-auto text-center">
            <div className="row calcu-list text-start mt-4">
              <div className="col-lg-6 mb-md-3">
                <p className="position-relative ps-4">
                  <img className="position-absolute" src={check} alt="check" />
                  <span>
                    Every gallon of gasoline burned releases 19 lbs of CO
                    <sub>2</sub>
                  </span>
                </p>
                <p className="position-relative ps-4">
                  <img className="position-absolute" src={check} alt="check" />
                  <span>
                    Every gallon of diesel burned releases 22 lbs of CO
                    <sub>2</sub>
                  </span>
                </p>
              </div>
              <div className="col-lg-6 mb-3">
                <p className="position-relative ps-4">
                  <img className="position-absolute" src={check} alt="check" />
                  <span>
                    Each time a truck is fueled through mobile fueling, 60 lbs
                    of CO<sub>2</sub> is saved on average for diesel vehicles
                    and 51 lbs of CO<sub>2</sub> is saved for gasoline vehicles.
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="calcu-wrapper pt-5 pb-4">
                <div className="calcu-header text-center">
                  <p>
                    Calculate how much carbon dioxide emissions you are reducing
                    through mobile fueling!
                  </p>
                </div>

                <div className="row">
                  <div className="col-lg-7">
                    <div className="radio-button mb-3">
                      <div className="f-20 text-white mb-3">Fuel Type :</div>
                      <label className="radio d-inline-block me-4 txt-14">
                        Diesel
                        <input
                          type="radio"
                          name="fuel_type"
                          onChange={this.completeChange}
                          value={true}
                          checked={this.state.hero}
                        />
                        <span className="checkround"></span>
                      </label>
                      <label className="radio d-inline-block txt-14">
                        Gasoline
                        <input
                          type="radio"
                          name="fuel_type"
                          value={false}
                          onChange={this.completeChange}
                          checked={!this.state.hero}
                        />
                        <span className="checkround"></span>
                      </label>
                    </div>
                    <div className="frame">
                      <div className="center">
                        <div
                          className="slider-item"
                          style={{ marginBottom: "50px" }}
                        >
                          <h6>Number of Vehicles in Your Fleet:</h6>
                          <div className="rang-outer">
                            <div className="d-flex align-items-center justify-content-between">
                              <span className="t-20"></span>
                              <input
                                id=""
                                className="input-control"
                                type="text"
                                value={this.state.values}
                                onChange={(e) => this.handleChange3(e)}
                              />
                            </div>
                            <Range
                              values={this.state.values}
                              step={STEP}
                              min={MIN}
                              max={MAX}
                              onChange={(e) => this.handleChange(e)}
                              renderTrack={({ props, children }) => (
                                <div
                                  onMouseDown={props.onMouseDown}
                                  onTouchStart={props.onTouchStart}
                                  style={{
                                    ...props.style,
                                    height: "36px",
                                    display: "flex",
                                    width: "100%",
                                  }}
                                >
                                  <div
                                    ref={props.ref}
                                    style={{
                                      height: "3px",
                                      width: "100%",
                                      borderRadius: "4px",
                                      background: getTrackBackground({
                                        values: this.state.values,
                                        colors: [
                                          "#ff7900",
                                          this.props.theme === "dark"
                                            ? "rgba(255, 255, 255, 0.11)"
                                            : "rgba(0,0,0,0.11)",
                                        ],
                                        min: MIN,
                                        max: MAX,
                                      }),
                                      alignSelf: "center",
                                    }}
                                  >
                                    {children}
                                  </div>
                                </div>
                              )}
                              renderThumb={({ props, isDragged }) => (
                                <div
                                  {...props}
                                  style={{
                                    ...props.style,
                                    height: "24px",
                                    width: "24px",
                                    borderRadius: "50px",
                                    backgroundColor: "#ff7900",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      height: "25px",
                                      width: "12px",
                                      backgroundColor: isDragged
                                        ? "#548BF4"
                                        : "#CCC",
                                    }}
                                  />
                                  <output id="output">
                                    {this.state.values[0] !== ""
                                      ? this.state.values
                                      : 0}
                                  </output>
                                </div>
                              )}
                            />
                          </div>
                        </div>
                        <div
                          className="slider-item"
                          style={{ marginBottom: "50px" }}
                        >
                          <h6>
                            Number of Times each Vehicle is Fueled Weekly:
                          </h6>
                          <div className="rang-outer">
                            <div className="d-flex align-items-center justify-content-between">
                              <span className="t-20"></span>
                              <input
                                id=""
                                className="input-control"
                                type="text"
                                value={this.state.values1}
                                onChange={(e) => this.handleChange4(e)}
                              />
                            </div>
                            <Range
                              values={this.state.values1}
                              step={STEP1}
                              min={MIN1}
                              max={MAX1}
                              onChange={(e) => this.handleChange1(e)}
                              renderTrack={({ props, children }) => (
                                <div
                                  onMouseDown={props.onMouseDown}
                                  onTouchStart={props.onTouchStart}
                                  style={{
                                    ...props.style,
                                    height: "36px",
                                    display: "flex",
                                    width: "100%",
                                  }}
                                >
                                  <div
                                    ref={props.ref}
                                    style={{
                                      height: "3px",
                                      width: "100%",
                                      borderRadius: "4px",
                                      background: getTrackBackground({
                                        values: this.state.values1,
                                        colors: [
                                          "#ff7900",
                                          this.props.theme === "dark"
                                            ? "rgba(255, 255, 255, 0.11)"
                                            : "rgba(0,0,0,0.11)",
                                        ],
                                        min: MIN1,
                                        max: MAX1,
                                      }),
                                      alignSelf: "center",
                                    }}
                                  >
                                    {children}
                                  </div>
                                </div>
                              )}
                              renderThumb={({ props, isDragged }) => (
                                <div
                                  {...props}
                                  style={{
                                    ...props.style,
                                    height: "24px",
                                    width: "24px",
                                    borderRadius: "50px",
                                    backgroundColor: "#ff7900",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      height: "25px",
                                      width: "12px",
                                      backgroundColor: isDragged
                                        ? "#548BF4"
                                        : "#CCC",
                                    }}
                                  />
                                  <output id="output">
                                    {this.state.values1[0] !== ""
                                      ? this.state.values1
                                      : 0}
                                  </output>
                                </div>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 mt-3 mt-lg-0">
                    <div className="annual-saving">
                      <h4 className="text-center pb-4">
                        Carbon Dioxide Reduced Each Year
                      </h4>
                      <div className="row pt-lg-5 my-3">
                        <div className="f-50">
                          {this.state.carbonDioxideReducedEachYear} lbs
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default App;

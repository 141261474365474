import React from "react";
import { Link } from "react-router-dom";
import BreadCrumb from "../BreadCrumb/BreadCrumb";
import { careersbanner } from "../Imagesjs/Images";
import "./Policies.css";
function Privacypolicy() {
  const pagename = {
    name: "Privacy policy",
  };
  return (
    <>
      <section className="inner-banner section-banner faq">
        <div className="background-image">
          <img src={careersbanner} alt="careers banner" />
        </div>
        <div className="container position-relative">
          <div className="row align-items-lg-center align-items-end">
            <div className="col-12  text-center">
              <BreadCrumb pagename={pagename} />
            </div>
            <div className="col-12">
              <div className="home-banner-texter text-center">
                <h1 className="wow fadeInUp" data-wow-delay="0.2s">
                  <span>Privacy </span>
                  <span
                    className="wow fadeInUp text-orange"
                    data-wow-delay="0.4s"
                  >
                    policy
                  </span>
                </h1>
                <p className="wow fadeInUp" data-wow-delay="0.3s">
                WELCOME TO THE GAS IT UP PRIVACY POLICY <br />
                Effective Date: February 25th, 2019
               
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all policy-page">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-8 col-12">
              <h5>
                <strong>
                  At Gas It Up, Inc. (“Gas It Up”, “we”, “us” or “our”) we take
                  your privacy and the security of your information very
                  seriously.{" "}
                </strong>
              </h5>
			  <br />
              <p>
                This Privacy Policy (“Policy”) covers Gas It Up owned websites
                (“Site” or “Sites”), including{" "}
                <Link to="/">
                  https://www.gasitup.com
                </Link>{" "}
                and platforms, including our mobile application (“App”), and any
                services offered by Gas It Up (collectively, the “Services”) to
                you (“Customers”), and individuals authorized by Gas It Up to
                use the Services to deliver our offerings to you (“Team
                Members”), (collectively, “Service Users.”) This Privacy Policy
                is incorporated into, and is part of, our Terms of Use available
                at{" "}
                <Link to="/privacy-policy"
                  onClick={() => {
                    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                  }}>
                  https://www.gasitup.com/Gasitup/privacyPolicy
                </Link>{" "}
                which govern your access to the Services. Unless otherwise
                defined herein, capitalized terms shall have the meaning
                assigned to such terms in the [Terms of Use].{" "}
              </p>
              <br />
              <p>
                {" "}
                If you have any questions regarding this Policy please contact
                us at support@gasitup.com.{" "}
              </p>
              <p>
                The Policy describes the types of information we gather from
                Service Users, including people using our Services and from
                individual users (“you” or “users”) interacting with the
                Services, and how we use, transfer, and secure such information.
                By using any of the Services, you agree to be bound by this
                Policy. This Policy does not govern information we receive from
                third parties who are not Service Users. If you do not agree to
                the terms of this Policy, please do not use any of our Services.
                Each time you use our Services, the current version of this
                Policy will apply. Accordingly, when you use any of our
                Services, you should check the date of this Policy (which
                appears at the top) and review any changes since you last
                reviewed the Policy.
              </p>
              <h4> 1. Types of Information We Collect </h4>
              <p>
                Gas It Up may collect two types of information from users of our
                Services: “Personal Information” which is information that can
                be used to identify you (such as name or e-mail address, etc.)
                and “Aggregate Information” which is information that cannot be
                used to identify you (such as device type, or time of access to
                the Services.){" "}
              </p>
              <h5> 1.1 &nbsp;Personal Information Collected </h5>
              <br />
              <p>
                {" "}
                1.1.1. Gas It Up collects Personal Information you voluntarily
                provide to us when you use our Services, including our Services
                and App, or that may be provided on your behalf by another
                Service User. For example, you may provide us with your email
                address, first name and last name, or other Personal
                Information, or Personal Information may be provided on your
                behalf by another Server User, perhaps someone who uses the same
                vehicle.{" "}
              </p>
              <p>
                {" "}
                1.1.2. We do not control your use of our Services to share
                information with others. If you chose to share information
                publicly through our Services, please understand that we cannot
                control the actions of third parties who could collect this
                information.{" "}
              </p>
              <p>
                {" "}
                1.1.3. Collection of Personal Information by Third Parties:{" "}
              </p>
              <p>
                1.1.4. Some links on our Services may redirect you to third
                party resources, including websites and services that Gas It Up
                does not operate. The privacy practices of these websites and
                services will be governed by their own policies. We make no
                representation or warranty as to the privacy policies of any
                third parties, including the providers of third party
                applications. If you are submitting information to any such
                third party through our Services, you should review and
                understand that party’s applicable policies, including their
                privacy policy, before providing your information to the third
                party.
              </p>
              <p>
                1.1.5. For example, it is possible that your payment
                information, such as credit card or debit card information, and
                may be collected and stored by third party payment vendors,
                should you choose to provide such a payment method to a third
                party through your use of the Services. Gas It Up leverages
                third parties, including Chase Pay and Authorize.Net to allow
                you to make payments. Please understand that your interactions
                with these third parties are subject to their own privacy
                policies and terms of use, and Gas It Up does not control your
                interactions with third party payment vendors where you provide
                your information directly to these third party providers.
              </p>
              <h5> 1.2. Aggregate Information Collected </h5>
              <br />
              <p>
                {" "}
                1.2.1. Aggregate Information is information that does not
                identify you. Aggregate Information may be collected when you
                use our Services independent of any information you voluntarily
                enter. Gas It Up may collect, use, store, and transfer Aggregate
                Information without restriction.{" "}
              </p>
              <p>
                {" "}
                1.2.2. For example, when you visit our Site, some information is
                automatically collected. Such information could include your
                operating system, the site from which you linked to us
                (“referring page”), the name of the website you choose to visit
                immediately after ours (called the “exit page”), information
                about other websites you have recently visited, browser
                (software used to browse the internet) type and language, device
                identifier numbers, your site activity, and the time and date of
                your visit. Although we do our best to honor the privacy
                preferences of our visitors, we are not able to respond to Do
                Not Track signals from your browser at this time.{" "}
              </p>
              <p>
                1.2.3. We may also use cookies, pixels, beacons, or other web
                tracking technologies to track the amount of time spent on our
                Sites or social media and whether or not certain content, such
                as a video was viewed. We may work with a third party to collect
                and process this information for us, based on our instructions
                and in compliance with this Policy.
              </p>
              <p>
                1.2.4. Our mobile applications may automatically collect certain
                Aggregate Information related to your use of the mobile
                application. Such aggregate information may include the date and
                time of your visit, the phone network associated with your
                mobile device, your mobile device’s operating system or
                platform, the type of mobile device you use, your mobile
                device’s unique device ID and the features of our mobile
                application you accessed.
              </p>
              <p>
                1.2.5. As an integral part of providing the Services, we may use
                GPS, or other technology to collect geolocation data that tells
                us your current location at various points. You can opt out of
                allowing us to provide this information either by refusing
                access to the information or disabling your location setting on
                your device, but if you choose to opt out, you will not be able
                to use certain aspects of the Services.
              </p>
              <p>
                1.2.6. You can stop all collection of information by any of our
                mobile applications by uninstalling the mobile application. You
                may use the standard uninstall processes as may be available as
                part of your mobile device or via the mobile application
                marketplace or network.
              </p>
              <h5> 1.3. Use of “Cookies” </h5>
              <br />
              <p>
                {" "}
                1.3.1. Cookies are alphanumeric identifiers that we transfer to
                your computer's hard drive through your web browser to help us
                identify you when you come to our Site. You have choices with
                respect to cookies. By modifying your browser preferences, you
                have the choice to accept all cookies, to be notified when a
                cookie is set, or to reject all cookies. If you choose to reject
                all cookies you may be unable to use those aspects of our Sites
                that require registration in order to participate. You can learn
                more about cookies and how they work at www.allaboutcookies.org.
                You can always disable cookies through your browser settings.
                Doing so, however, may disable certain features on our Sites.
                You can opt-out from third party cookies that are used for
                advertising purposes on the NAI website at{" "}
                
                <Link to="/privacy-policy"
                  onClick={() => {
                    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                  }}
                >
                  http://www.networkadvertising.org/managing/opt_out.asp
                </Link>{" "}
                .{" "}
              </p>
              <h5> 1.4. Analytics </h5>
              <br />
              <p>
                {" "}
                1.4.1. We may use third parties, such as Google Analytics or
                other analytics providers, to analyze traffic to a Site. Google
                Analytics does not create individual profiles for visitors and
                only collects aggregate data. To disable Google Analytics,
                please download the browser add-on for the deactivation of
                Google Analytics provided by Google at
                http://tools.google.com/dlpage/gaoptout?hl=en. To learn more
                about privacy and Google Analytics, please consult the Google
                Analytics overview provided by Google at{" "}
                <Link to="/privacy-policy"
                  onClick={() => {
                    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                  }}
                >
                  {" "}
                  http://www.google.com/intl/en/analytics/privacyoverview.html{" "}
                </Link>{" "}
                . You may find additional information about Google Analytics at{" "}
                <Link to="/privacy-policy"
                  onClick={() => {
                    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                  }}
                >
                  {" "}
                  http://www.google.com/policies/privacy/partners/
                </Link>{" "}
                . You have the option to opt out of Google's use of cookies by
                changing your settings through Google Ad Settings via the Google
                advertising opt-out page at{" "}
                <Link to="/privacy-policy"
                  onClick={() => {
                    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                  }}
                >
                  {" "}
                  http://www.google.com/policies/technologies/ads/
                </Link>
                .{" "}
              </p>
              <h4>
                {" "}
                2 &nbsp;Sharing of Personal Information with Third Parties{" "}
              </h4>
              <br />
              <p>
                {" "}
                2.1. We may transfer Personal Information to third parties for
                the purpose of providing the Services. We may disclose Personal
                Information to our appropriate affiliates, including third party
                service providers, to provide you with the Services. For
                example, we may transfer your Personal Information to third
                party service providers to (i) process your payment for goods or
                services and (ii) communicate with third party services
                regarding requests you have made through the Services. These
                third party service providers are not authorized to retain,
                share, store or use the Personal Information for any purposes
                other than to provide the services they have been hired to
                provide. We do not sell, trade or otherwise share Personal
                Information with third parties for the marketing purposes of the
                third party.{" "}
              </p>
              <p>
                {" "}
                2.2. We may also elect to transfer your Personal Information to
                third parties under special circumstances to: (i) to comply with
                a legal requirement, judicial proceeding, court order, or legal
                process served on Gas It Up or its affiliates, (ii) to
                investigate a possible crime, such as fraud or identity theft;
                (iii) in connection with the sale, purchase, merger,
                reorganization, liquidation or dissolution of Gas It Up; (iv)
                when we believe it is necessary to protect the rights, property,
                or safety of Gas It Up or other persons, or (v) as otherwise
                required or permitted by law, including any contractual
                obligations of Gas It Up.{" "}
              </p>
              <h4> 3 &nbsp;Personal Information Collected </h4>
              <br />
              <p>
                {" "}
                3.1. You can request that any Personal Information stored by Gas
                It Up be deleted at any time by contacting us at
                support@gasitup.com. We may require you to provide certain
                information to verify that it is you making the request.{" "}
              </p>
              <p>
                {" "}
                3.2. We provide you the opportunity to “opt-out” of having your
                personally identifiable information used for certain purposes
                when we ask for this information. For example, if you do not
                wish to receive marketing material from us, you can indicate
                your preference in the app’s options area. [Gas It Up, please
                let us know if this section is accurate. You do not have to
                provide this functionality in the app itself, you could only
                provide it via unsubscribe links within marketing communications
                if only email marketing will be used. ] If you no longer wish to
                receive our newsletter and promotional communications, you may
                opt- out of receiving them by following the instructions
                included in each communication.{" "}
              </p>
              <p>
                Gas It Up’s mobile applications may also provide you with push
                notifications of upcoming events, such as, promotions or notice
                of your upcoming reservations with third party service
                providers. You can opt out of receiving push notifications at
                any time by using the settings on your mobile device. [Gas It
                Up, if push notifications will be used in this way, we recommend
                that this functionality be provided. Please let us know if you
                would like to discuss.]
              </p>
              <h4> 4 &nbsp; Children and Privacy </h4>
              <p>
                4.1. We do not knowingly collect Personal Information from
                children in connection with the features of our Services, and
                our Services are not to be used by those under the age of 13. If
                we become aware that an individual under the age of 13 has
                provided personally identifiable information through our
                Services, we will immediately remove the individual’s personally
                identifiable information from our files.
              </p>
              <h4> 5 &nbsp; How Do We Protect Your Information </h4>
              <p>
                5.1. We take the security of your Personal Information very
                seriously. We use reasonable administrative, physical, and
                technical safeguards to secure the Personal Information you
                share with us. Despite these safeguards and our additional
                efforts to secure your information, we cannot promise or
                guarantee that hackers, cybercriminals, or other unauthorized
                third-parties will not be able to defeat our security, and
                improperly collect, access, steal, or modify your Personal
                Information.{" "}
              </p>
              <p>
                5.2. The security of your use of any Gas It Up mobile
                application relies on your protection of your mobile device. You
                may not share your instance of the mobile application with
                anyone. If you believe that an unauthorized access to your
                instance of the mobile application has occurred please report it
                immediately at support@gasitup.com. You must promptly notify us
                if you become aware that any information provided by or
                submitted to the mobile application is lost, stolen, or used
                without permission.
              </p>
              <p>
                5.3. The security of the user profile you create on any instance
                of the Site or App, or through the Services relies on your
                protection of your login credentials. You are responsible for
                maintaining the security of your login credentials, including
                your password and for any and all activities that occur under
                your account. You may not share your password with anyone. We
                will never ask you to send your password or other sensitive
                information to us in an email, though we may ask you to enter
                this type of information on a Gas It Up website, or mobile
                application interface.{" "}
              </p>
              <p>
                5.4. Any email or other communication purporting to be from us
                requesting your password or asking you to provide sensitive
                account information via email, should be treated as unauthorized
                and suspicious and should be reported to us immediately by
                emailing support@gasitup.com. If you believe someone else has
                obtained access to your password, please change it immediately
                and report it immediately by emailing support@gasitup.com.
              </p>
              <h4> 6 &nbsp; Will Gas It Up Change This Privacy Policy? </h4>
              <p>
                6.1. Each time you use our Services, the current version of the
                Policy will apply. When you use any of our Services, you should
                check the date of this Policy (which appears at the top of the
                Policy) and review any changes since the last version. This
                Policy is available at{" "}
                <Link to="/privacy-policy" 
                  onClick={() => {
                    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                  }}
                >
                  https://www.gasitup.com/Gasitup/privacyPolicy
                </Link>
                . Our business changes frequently and this Policy is subject to
                change from time to time. Unless stated otherwise, our current
                Policy applies to all information that we have about you.
              </p>
              <h4> 7 &nbsp; How Do I contact Gas It Up? </h4>
              <p>
                7.1. To contact us with your questions or comments regarding
                this Policy or the information collection and dissemination
                practices of this website, please email us at
                support@gasitup.com.
              </p>
              <h4> 8 &nbsp; Governing Law </h4>
              <p>
                8.1. This Policy is governed by the laws of the State of New
                York, U.S.A. without giving effect to any principles of conflict
                of law.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Privacypolicy;

import React, { useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import Swal from "sweetalert2";
import { useEffect } from "react";
import Schema from "../../schema/Courseshmea";
import Schema1 from "../../schema/Formsshema";

const ValidatedLoginForm = (props) => {
  const [data, setData] = useState([]);
  const [citydata, setcitydata] = useState([]);
  const handler = (event) => {
    if (event.target.value !== "") {
      axios
        .get(
          `${process.env.REACT_APP_API_base_KEY}/getData2?flag=3&search=${event.target.value}`
        )
        .then((res) => {
          if (res.data.data.data.length > 0) {
            setData(res.data.data);
          } else {
            setData([]);
          }
        })
        .catch((err) => { });
    } else {
      setData([]);
    }
  };

  function getdata1(ele) {
    setFieldValue("zipCode", ele[0]);
    setData([]);
  }

  const handlercity = (event) => {
    if (event.target.value !== "") {
      axios
        .get(
          `${process.env.REACT_APP_API_base_KEY}/getData2?flag=3&citySearch=${event.target.value}`
        )
        .then((res) => {
          if (res.data.data.data.length > 0) {
            setcitydata(res.data.data);
            console.log('hhhhhhhhhhhhh', citydata)
          } else {
            setcitydata([]);
          }
        })
        .catch((err) => { });
    } else {
      setcitydata([]);
    }
  };
  const formikProps = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      zipCode: "",
      message: "",
      type: props.type ? props.type : 1,
      bussinessName: "",
      bussinessAddress: "",
      companySize: "",
      fleetSize: "",
      fuelType: "",
      phone: "",
    },
    enableReinitialize: true,
    onSubmit: (values, action) => {
      let payload;
      console.log(values, "values are")
      if (!props.showBusinessFields) {
        payload = {
          email: values.email,
          firstName: values.firstName,
          lastName: values.lastName,
          city: values.city,
          zipCode: values.zipCode,
          message: values.message,
          type: props.type,
          phone: values.phone
        };
        console.log("🚀 ~ ValidatedLoginForm ~ payload:", payload)
        if (payload.zipCode) {
          payload.zipCode = values.zipCode;
        }
      } else {
        payload = values;
        delete values.firstName;
        delete values.lastName;
      }
      axios
        .post(`${process.env.REACT_APP_API_base_KEY}/form`, payload)
        .then((res) => {
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Thank you!!",
            text: "Your interest has been submitted to us. Our team will get back to you soon!!",
            showConfirmButton: false,
            timer: 4000,
          });
          action.resetForm();
          document.getElementById("firstName").value = "";
          document.getElementById("lastName").value = "";
          document.getElementById("city").value = "";

          if (!props.showBusinessFields) {
            setFieldValue("bussinessName", "bussinessName");
            setFieldValue("bussinessAddress", "bussinessAddress");

            setFieldValue("fleetSize", "fleetSize");
            setFieldValue("companySize", "companySize");
            setFieldValue("fuelType", "fuelType");
          } else {
            setFieldValue("firstName", "firstName");
            setFieldValue("lastName", "lastName");
            setFieldValue("city", "city");
          }
        })
        .catch((err) => { });
    },
    validationSchema: props.showBusinessFields ? Schema : Schema1,
  });

  const {
    touched,
    errors,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = formikProps;

  function getData2(ele) {
    formikProps.setFieldValue("city", ele);
    setcitydata([]);
  }

  useEffect(() => {
    if (!props.showBusinessFields) {
      setFieldValue("bussinessName", "bussinessName");
      setFieldValue("bussinessAddress", "bussinessaddress");
      setFieldValue("companySize", "companySize");
      setFieldValue("fleetSize", "fleetSize");
      setFieldValue("fuelType", "fuelType");
    } else {
      setFieldValue("firstName", "firstName");
      setFieldValue("lastName", "lastName");
      setFieldValue("city", "city");
    }
  }, [props.showBusinessFields, setFieldValue]);

  return (
    <div className="">
      <form onSubmit={handleSubmit}>
        <div className="row">
          {props.showBusinessFields ? (
            ""
          ) : (
            <div className="col-md-6 form-group">
              <label>First Name</label>
              <input
                type="text"
                placeholder="Enter First Name"
                className="form-control"
                id="firstName"
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
              />
              <p className="text-danger mb-0">
                {errors.firstName && touched.firstName && (
                  <span className="input-feedback">{errors.firstName}</span>
                )}{" "}
              </p>
            </div>
          )}
          {props.showBusinessFields ? null : (
            <div className="col-md-6 form-group">
              <label>Last Name</label>
              <input
                type="text"
                placeholder="Enter Last Name"
                className="form-control"
                id="lastName"
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="off"
              />
              <p className="text-danger mb-0">
                {errors.lastName && touched.lastName && (
                  <span className="input-feedback">{errors.lastName}</span>
                )}{" "}
              </p>
            </div>
          )}
          {props.showBusinessFields ? (
            <div className="col-md-12 form-group">
              <label>Business Name </label>
              <input
                type="text"
                placeholder="Enter Business Name"
                className="form-control"
                autoComplete="off"
                id="bussinessName"
                name="bussinessName"
                value={values.bussinessName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <p className="text-danger mb-0">
                {errors.bussinessName && touched.bussinessName && (
                  <span className="input-feedback">{errors.bussinessName}</span>
                )}{" "}
              </p>
            </div>
          ) : null}
          <div className="col-md-12 form-group">
            <label>Email Address</label>
            <input
              type="email"
              placeholder="Enter Email Address"
              className="form-control"
              id="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="off"
            />
            <p className="text-danger mb-0">
              {" "}
              {errors.email && touched.email && (
                <span className="input-feedback">{errors.email}</span>
              )}
            </p>
          </div>
          <div className="col-md-12 form-group">
            <label>Phone Number</label>
            <input
              type="text"
              placeholder="Enter Phone Number"
              className="form-control"
              maxLength="10"
              id="phone"
              value={values.phone}
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="off"
            />
            <p className="text-danger mb-0">
              {" "}
              {errors.phone && touched.phone && (
                <span className="input-feedback">{errors.phone}</span>
              )}
            </p>
          </div>
          {props.showBusinessFields ? (
            <div className="col-md-12 form-group">
              <label>Business Address </label>
              <input
                type="text"
                placeholder="Enter Business Address"
                className="form-control"
                autoComplete="off"
                id="bussinessAddress"
                name="bussinessAddress"
                value={values.bussinessAddress}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <p className="text-danger mb-0">
                {errors.bussinessAddress && touched.bussinessAddress && (
                  <span className="input-feedback">
                    {errors.bussinessAddress}
                  </span>
                )}{" "}
              </p>
            </div>
          ) : null}
        </div>
        <div className="row">
          {props.showBusinessFields ? (
            ""
          ) : (
            <div className="col-md-6 form-group position-relative">
              <label>City (optional)</label>
              <input
                type="text"
                placeholder="Enter City (optional)"
                className="form-control"
                id="city"
                value={values.city}
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyUp={(e) => handlercity(e)}
                autoComplete="off"
              />
              {citydata.length !== 0 && (
                <ul className="zipcode-listing">
                  {citydata.length !== 0 &&
                    citydata.data.map((ele, i) => {
                      return (
                        <li key={i} onClick={() => getData2(ele?.city)}>
                          {ele?.city}
                        </li>
                      );
                    })}
                </ul>
              )}
            </div>
          )}
          <div
            className={`${props.showBusinessFields ? "col-md-6" : "col-md-6"
              } form-group position-relative`}
          >
            <label>Zip Code</label>
            <input
              type="number"
              placeholder="Enter Zip Code"
              className="form-control"
              id="zipCode"
              name="zipCode"
              value={values.zipCode}
              onChange={(event) => {
                setFieldValue("zipCode", event.target.value);
              }} //
              onBlur={handleBlur}
              onKeyUp={(e) => handler(e)}
              autoComplete="off"
            />
            <p className="text-danger mb-0">
              {" "}
              {errors.zipCode && touched.zipCode && (
                <span className="input-feedback">{errors.zipCode}</span>
              )}
            </p>
            {data.length !== 0 && (
              <ul className="zipcode-listing">
                {data.length !== 0 &&
                  data.data.map((ele, i) => {
                    return (
                      <li key={i} onClick={() => getdata1(ele?.zipCode)}>
                        {ele?.zipCode}
                      </li>
                    );
                  })}
              </ul>
            )}
          </div>
          {props.showBusinessFields ? (
            <div className="col-md-6 form-group">
              <label>Company Size</label>
              <select
                value={values.companySize}
                className="form-select"
                onChange={(e) => setFieldValue("companySize", e.target.value)}
              >
                <option>Select Company Size</option>
                <option value="10-50">10-50</option>
                <option value="50-100">50-100</option>
                <option value="100-200">100-200</option>
              </select>
              <p className="text-danger mb-0">
                {" "}
                {errors.companySize && touched.companySize && (
                  <span className="input-feedback">{errors.companySize}</span>
                )}
              </p>
            </div>
          ) : null}
          {props.showBusinessFields ? (
            <div className="col-md-6 form-group">
              <label>Fleet Size</label>
              <select
                value={values.fleetSize}
                className="form-select"
                onChange={(e) => setFieldValue("fleetSize", e.target.value)}
              >
                <option value="" className="">
                  Select Fleet Size
                </option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
              </select>
              <p className="text-danger mb-0">
                {" "}
                {errors.fleetSize && touched.fleetSize && (
                  <span className="input-feedback">{errors.fleetSize}</span>
                )}
              </p>
            </div>
          ) : null}
          {props.showBusinessFields ? (
            <div className="col-md-6 form-group">
              <label>fuel type</label>
              <select
                value={values.fuelType}
                className="form-select"
                onChange={(e) => setFieldValue("fuelType", e.target.value)}
              >
                <option value="" className="">
                  Select Fuel Type{" "}
                </option>
                <option value="Gasoline - Diesel Clear +DEF -">
                  Gasoline - Diesel Clear + DEF
                </option>
                <option value="Diesel Red +DEF - Gasoline &">
                  Diesel Red + DEF - Gasoline
                </option>
                <option value="Diesel/ DEF - Other">
                  Diesel / DEF - Other
                </option>
              </select>
              <p className="text-danger mb-0">
                {" "}
                {errors.fuelType && touched.fuelType && (
                  <span className="input-feedback">{errors.fuelType}</span>
                )}
              </p>
            </div>
          ) : null}
        </div>

        <div className="row">
          <div className="col-md-12 form-group">
            <label>Message (optional)</label>
            <textarea
              rows={6}
              cols={3}
              className="w-100"
              id="message"
              value={values.message}
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="off"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-center">
            <button
              className="btn btn--primary px-lg-5 px-md-3 px-2 mx-auto w-100"
              id="submit"
              onClick={formikProps.handleSubmit}
              type="submit"
            >
              <span>Submit</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};
export default ValidatedLoginForm;

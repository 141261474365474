import React, { Component } from "react";
import Slider from "react-slick";
import {
  img1,
  img2,
  img5,
  img7,
  img6,
  img8,
  img9,
  img10,
  img12,
  wex,
  // broncos,
} from "../Imagesjs/Images.js";
import "./Partners.css";

class Partners extends Component {
  render() {
    const partnerimg = [
      {
        src: img1,
        title: "img1",
      },
      {
        src: img2,
        title: "img2",
      },

      {
        src: img5,
        title: "img5",
      },
      {
        src: img6,
        title: "img6",
      },
      {
        src: img7,
        title: "img6",
      },
      {
        src: img8,
        title: "img8",
      },
      {
        src: img9,
        title: "img9",
      },
      {
        src: img10,
        title: "img10",
      },
      {
        src: img12,
        title: "img12",
      },
      {
        src: wex,
        title: "wex",
      },
      // {
      //   src: broncos,
      //   title: "broncos",
      // },
    ];

    let Partnerssettings = {
      infinite: true,
      cssEase: "linear",
      slidesToShow: 9,
      autoplay: true,
      autoplaySpeed: 0,
      centerMode: true,
      centerPadding: "30px",
      speed: 5000,
      dots: false,
      arrows: false,
      responsive: [
        {
          breakpoint: 2500,
          settings: {
            slidesToShow: 8,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1920,
          settings: {
            slidesToShow: 7,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerPadding: "100px",
            centerMode: true,
          },
        },
      ],
    };
    return (
      <>
        <div className="slider--container">
          <div>
            <Slider {...Partnerssettings}>
              {partnerimg.map((img, i) => (
                <div className="circlelo clickcustomform" key={i}>
                  <img
                    src={img.src}
                    alt="Businness logo"
                    height={45}
                    width="100%"
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </>
    );
  }
}

export default Partners;

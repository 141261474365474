import React, { useState } from "react";
import Partners from "../Partners/Partners";
import FuelingFuture from "../FuelingFuture/FuelingFuture";
import Newsletter from "../Newsletter/Newsletter";
import Contact from "../Contactus/Contact";
import Playstoreicons from "../Playstoreicons/Playstoreicons";
import { Link } from "react-router-dom";
// import movingtruck from "../assets/images/truck.mp4";
import movingtruckwebm from "../assets/images/truck.webm";
// import movingtruckwhite from "../assets/images/truckwhite.mp4";
import movingtruckwhitewebm from "../assets/images/truckwhite.webm";
import {
  arrow,
  consumer,
  business,
  gasthumb,
  linehorn1,
  linehorn2,
  linehorn3,
  linehorn5,
  linehorn4,
  leadershipcontactbanner,
  image1,
  lytx,
  wex,
  verizonconnect,
  chargeituplogo,
  california,
  Colorado,
  florida,
  michigan,
  texas,
  newyork,
  esri,
  logolight,
  logo,
  movingtruckwhite,
  movingtruck
} from "../Imagesjs/Images";
import DownloadSchedule from "../DownloadSchedule/DownloadSchedule";
import "./Home.css";
import VideoBlock from "../VideoBlock/VideoBlock";
import TabSlider from "../TabSlider/TabSlider";
import TabVideo from "../TabVideo/TabVideo";
import { useEffect } from "react";

function Home(props) {
  const slideimag = [{ image1: image1 }];
  const [movingtruckweb, setmovingtruckweb] = useState("");
  let pathname = window.location.pathname.replace(
    //eslint-disable-next-line
    /[`~!@#$%^&*()_|+\=?;:'",.<>\{\}\[\]\\\/]/gi,
    ""
  );
  const newstype = 4;
  const contactbanner = leadershipcontactbanner;
  useEffect(() => {
    const browserName = navigator.userAgent;
    let browser = getBrowserName(browserName);
    localStorage.setItem("browser_name", browser);
    if (browser === "Apple Safari") {
      setmovingtruckweb(movingtruckwebm);
    }
  }, []);

  function getBrowserName(userAgent) {
    if (userAgent.includes("Firefox")) {
      return "Mozilla Firefox";
    } else if (userAgent.includes("SamsungBrowser")) {
      return "Samsung Internet";
    } else if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
      return "Opera";
    } else if (userAgent.includes("Trident")) {
      return "Microsoft Internet Explorer";
    } else if (userAgent.includes("Edge")) {
      return "Microsoft Edge (Legacy)";
    } else if (userAgent.includes("Edg")) {
      return "Microsoft Edge (Chromium)";
    } else if (userAgent.includes("Chrome")) {
      return "Google Chrome or Chromium";
    } else if (userAgent.includes("Safari")) {
      return "Apple Safari";
    } else {
      return "unknown";
    }
  }

  const downLoadPros = {
    downloadlist: [],
    IsRewrite: true,
  };

  useEffect(() => {
    document.title = "Welcome | Gasitup";
  }, []);

  return (
    <>
      <section className="home-banner section-banner home">
        <div className="container">
          <div className="row align-items-center ">
            <div className="col-lg-6 col-md-12 col-xs-12 home-banner-texter">
              <h1 className="wow fadeInUp animate" data-wow-delay="0.2s">
                The standard of technology <br />
                <span>&amp; fuel efficiency</span>
              </h1>
              <p
                className="wow fadeInUp animate banner-parag"
                data-wow-delay="0.6s"
              >
                Delivering fuel to business fleets and everyday drivers 24/7
              </p>
              <div className=" banner-actions d-flex align-items-center justify-content-center justify-content-lg-start mt-0">
                <Link
                  to="/getafleet"
                  className="btn btn--primary clickcustomform wow animate fadeInUp"
                  data-wow-delay="0.8s"
                >
                  Get a Fleet Quote
                  <i className="ms-3">
                    <img
                      src={arrow}
                      alt="arrow"
                      width="10px"
                      height="18px"
                      className="d-flex wow"
                    />
                  </i>
                </Link>
              </div>
              <Playstoreicons />
            </div>
            <div className="image-out text-start">
              <div className="col-4 mx-auto pb-5 text-center">
                <figure>
                  <i className="d-flex align-items-center">
                    <img
                      src={props.theme === "dark" ? logo : logolight}
                      alt="sitelogo"
                      height={80}
                      className=""
                    />
                  </i>
                </figure>
              </div>
              <div className="home-tab">
                <TabSlider slideimages={slideimag} />
              </div>

              <img
                className="mobile-rental wow zoomIn"
                data-wow-delay=".2s"
                src={gasthumb}
                alt="gas thumb"
                width="150px"
              />
            </div>
          </div>
        </div>
        <div className="truckmove">

          <video //MP4 buts works in IPHONe safari 
            muted // //MP4 buts does not work in IPHONe safari
            autoPlay
            loop
            playsInline
            src={props.theme === "dark" ? movingtruck : movingtruckwhite}
          >
            <source src={props.theme === "dark" ? movingtruck : movingtruckwhite} type="video/mp4" />
            <track src={props.theme === "dark" ? movingtruck : movingtruckwhite} kind="captions" srclang="en" label="english_captions"></track>
          </video>
        </div>
      </section>
      <section className="common-section-all py-0 videoblk">
        <div className="col-md-12">
          <div className="container">
            <div
              className="heading-style text-start"
              style={{ alignItems: "flex-start" }}
            >
              <h5 className="wow fadeInUp text-start" data-wow-delay="0.2s">
                Full Is Always Better.
              </h5>
              <h4 className="wow fadeInUp text-start" data-wow-delay="0.3s">
                The Gas Station Should Come To You
              </h4>
              <p className="wow fadeInUp text-start" data-wow-delay="0.4s">
                Gas It Up is an industry leading fuel management platform and
                delivery
                <br /> service for any fueling scenario. Fuel when you need it,
                where you <br />
                need it, all at the touch of a button.
              </p>
            </div>
          </div>
        </div>
        <VideoBlock path={pathname} />
      </section>
      <section className="common-section-all pb-0 ">
        <div className="container">
          <div className="optimun-productivity">
            <div className="col-md-12">
              <div className="heading-style text-center wow fadeInUp">
                <h5 className=" wow FadeInUp" data-wow-delay="0.2s">
                  Optimum Productivity for{" "}
                  <span className="text-orange">Your Business.</span>{" "}
                </h5>
                <h4 className=" wow FadeInUp" data-wow-delay="0.3s">
                  More Time. More Savings. More Possibilities.
                </h4>
                <p className=" wow FadeInUp" data-wow-delay="0.4s">
                  Fueling technology that accepts all major fuel cards, connects
                  directly with telematics, and provides custom data and
                  insights.
                </p>
              </div>
              <ul className="optimumlogos">
                <li>
                  <img src={wex} alt="wex" />
                </li>
                <li>
                  <img src={verizonconnect} alt="verizonconnect" />
                </li>
                <li>
                  <img src={lytx} alt="lytx" />
                </li>
                <li>
                  <img src={esri} alt="esri" />
                </li>
              </ul>
            </div>
            <div
              className="col-md-10 col-12 mx-auto wow FadeInUp arrow-top"
              data-wow-delay="0.2s"
              style={{ marginTop: "-30px" }}
            >
              <TabVideo />
            </div>
          </div>
        </div>
      </section>
      <div className="downloadschedule ">
        <DownloadSchedule downLoadPros={downLoadPros} />
      </div>
      <section className="common-section-all business-customer pt-0">
        <div className="container">
          <div className="col-md-12 px-lg-5 px-2 pb-0">
            <div className="heading-style text-center wow fadeInUp">
              <h5>
                Save<span className="text-orange"> Money.</span> Save{" "}
                <span className="text-orange">Time.</span> Stay{" "}
                <span className="text-orange">Safe.</span>{" "}
              </h5>
              <h4>
                Eliminating the need to visit germ-filled gas pumps in
                environments that
                <br /> you don’t feel safe again
              </h4>
            </div>
          </div>
          <div className="row align-items-start justify-content-evenly pt-lg-3 pt-3">
            <div className="col-lg-5 col-12">
              <div className="business-block wow flipInX ">
                <h3 className="text-uppercase">
                  <i>
                    <img
                      src={business}
                      alt="Businesses"
                      height={65}
                      width="100%"
                    />
                  </i>
                  Businesses
                </h3>
                <ul>
                  <li>
                    Impactful savings for fleets by reducing on-clock fueling
                    and increasing productivity
                  </li>
                  <li>
                    The smart and simple solution for refueling your fleet
                  </li>
                  <li>
                    Real-time data insights with telematic and fuel card
                    compatibility
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-5 col-12 ">
              <div className="consumer-block wow flipInX ">
                <h3 className="text-uppercase">
                  {" "}
                  <i>
                    <img
                      src={consumer}
                      alt="Consumers"
                      height={65}
                      width="100%"
                    />
                  </i>
                  Consumers
                </h3>
                <ul>
                  <li>
                    Your time is valuable, don't waste it at the gas station
                  </li>
                  <li>Competitive pricing to your local gas stations</li>
                  <li>
                    Keep your hands clean, let our fuel technicians handle the
                    dirty work
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all business-partner py-5">
        <div className="container">
          <div className="col-md-12 px-lg-5 px-2 pb-0">
            <div className="heading-style text-center wow fadeInUp">
              <h5>
                Trusted By <span className="text-orange">The Best!</span>
              </h5>
            </div>
          </div>
        </div>
        <Partners />
      </section>
      <section className="common-section-all pb-0">
        <FuelingFuture Istitlehaed={true} />
      </section>
      <section className="common-section-all intro-sistercompny">
        <div className="container">
          <div className="col-md-12 px-lg-5 px-2 pb-0">
            <div className="heading-style text-center">
              <div className="intro-horn">
                <i className="horn-left d-flex flex-column">
                  <img
                    src={linehorn1}
                    alt="line"
                    className="wow fadeInUp me-3"
                    data-wow-delay="0.2s"
                    width={30}
                    height={15}
                  />
                  <img
                    src={linehorn2}
                    alt="line"
                    className="wow fadeInUp me-4"
                    data-wow-delay="0.4s"
                    width={30}
                    height={15}
                  />
                  <img
                    src={linehorn3}
                    alt="line"
                    className="wow fadeInUp me-3"
                    data-wow-delay="0.6s"
                    width={30}
                    height={15}
                  />
                </i>
                <span className="wow fadeInUp" data-wow-delay=".1s">
                  Coming In 2024
                </span>
                <i
                  className="horn-right d-flex flex-column"
                  data-wow-delay=".2s"
                >
                  <img
                    src={linehorn4}
                    alt="line"
                    className="wow fadeInUp ms-3"
                    data-wow-delay="0.2s"
                    width={30}
                    height={15}
                  />
                  <img
                    src={linehorn2}
                    alt="line"
                    className="wow fadeInUp ms-4"
                    data-wow-delay="0.4s"
                    width={30}
                    height={15}
                  />
                  <img
                    src={linehorn5}
                    alt="line"
                    className="wow fadeInUp ms-3"
                    data-wow-delay="0.6s"
                    width={30}
                    height={15}
                  />
                </i>
              </div>
              <h5 className="wow fadeInUp" data-wow-delay=".3s">
                <img
                  src={chargeituplogo}
                  alt="chargeituplogo"
                  className="img-fluid px-lg-0 px-4"
                  width="450px"
                  height="100%"
                />
              </h5>
              <ul className="row align-items-center charituplist mt-lg-5 mt-3">
                <li
                  className="col-lg-2 col-md-4 col-6 wow fadeIn"
                  data-wow-delay="0.2s"
                >
                  <figure>
                    {" "}
                    <i>
                      <img
                        src={california}
                        alt="california"
                        height={65}
                        width="100%"
                      />
                    </i>
                    <span>california</span>
                  </figure>
                </li>
                <li
                  className="col-lg-2 col-md-4 col-6 wow fadeIn"
                  data-wow-delay="0.3s"
                >
                  <figure>
                    {" "}
                    <i>
                      <img
                        src={Colorado}
                        alt="Colorado"
                        height={65}
                        width="100%"
                      />
                    </i>
                    <span>Colorado</span>
                  </figure>
                </li>
                <li
                  className="col-lg-2 col-md-4 col-6 wow fadeIn"
                  data-wow-delay="0.4s"
                >
                  <figure>
                    {" "}
                    <i>
                      <img
                        src={florida}
                        alt="florida"
                        height={65}
                        width="100%"
                      />
                    </i>
                    <span>florida</span>
                  </figure>
                </li>
                <li
                  className="col-lg-2 col-md-4 col-6 wow fadeIn"
                  data-wow-delay="0.5s"
                >
                  <figure>
                    {" "}
                    <i>
                      <img
                        src={michigan}
                        alt="michigan"
                        height={65}
                        width="100%"
                      />
                    </i>
                    <span>michigan</span>
                  </figure>
                </li>
                <li
                  className="col-lg-2 col-md-4 col-6 wow fadeIn"
                  data-wow-delay="0.6s"
                >
                  <figure>
                    {" "}
                    <i>
                      <img src={texas} alt="texas" height={65} width="100%" />
                    </i>
                    <span>texas</span>
                  </figure>
                </li>
                <li
                  className="col-lg-2 col-md-4 col-6 wow fadeIn"
                  data-wow-delay="0.7s"
                >
                  <figure>
                    {" "}
                    <i>
                      <img
                        src={newyork}
                        alt="newyork"
                        height={65}
                        width="100%"
                      />
                    </i>
                    <span>new york</span>
                  </figure>
                </li>
              </ul>
              <h4 className="wow fadeInUp" data-wow-delay=".4s">
                Electric vehicle charging solutions for home, public, and
                business scenarios.
              </h4>
              <p className="wow fadeInUp" data-wow-delay=".6s">
                CIU provides charging solutions for EV fleets and equipment,
                using SDE technology to maximize output. A great solution to
                <br /> overcome the issues and concerns around infrastructural
                charging stations.
              </p>
            </div>
            <div className="text-center">
              <Link
                to="/chargeitup"
                className="btn btn--secondary d-inline-flex px-5 wow fadeInUp"
              >
                <span>View More</span>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all bg-purple newsleter_signup">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 wow fadeInLeft">
              <div className="heading-style text-md-start text-center">
                <h5 className="text-md-start text-center newsleter_text">
                  Sign up for our newsletter
                </h5>
                <h4>Subscribe to Gas It Up news and upcoming events!</h4>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 wow fadeInRight">
              <div
                className="heading-style text-md-start text-center"
                data-wow-delay="0.2s"
              >
                <p>
                  We care about your data in our
                  <span>
                    {" "}
                    <Link
                      to="/privacy-policy"
                      className="text-orange"
                      target="_blank"
                    >
                      privacy policy
                    </Link>
                  </span>
                </p>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 wow fadeInRight">
                  <Newsletter newstype={newstype} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 pb-0">
              <div className="heading-style text-center wow fadeInUp">
                <h5 className="text-capitalize">
                  We’d <span className="text-orange"> Love </span> to Hear From{" "}
                  <span className="text-orange"> You </span>
                </h5>
              </div>
            </div>
            <Contact
              type={1}
              showBusinessFields={false}
              contactbanner={contactbanner}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;

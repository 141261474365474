import "mapbox-gl/dist/mapbox-gl.css";
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
import React, { useState, useRef, useCallback, useEffect } from "react";
import MapGL, { Marker } from "react-map-gl";
import Geocoder from "react-map-gl-geocoder";
// import { activelocation, ciu, upcominglocation } from "./Components/Imagesjs/Images";

// Please be a decent human and don't abuse my Mapbox API token.
// If you fork this sandbox, replace my API token with your own.
// Ways to set Mapbox token: https://uber.github.io/react-map-gl/#/Documentation/getting-started/about-mapbox-tokens

const MAPBOX_TOKEN = "pk.eyJ1IjoiZ2FzaXR1cCIsImEiOiJjbG8ycWl6czYxdTduMnFtYzZucG5xM2ZkIn0.ZwVoroQbWYi3HMkfBV7QZQ"


const GMap = (props) => {
  const [viewport, setViewport] = useState({
    latitude: 32.776665,
    longitude: -96.796989,
    zoom: 4,
  });
  const mapRef = useRef();
  const handleViewportChange = useCallback(
    (newViewport) => setViewport(newViewport),
    []
  );
  const markerList = props.filtermarker;

  useEffect(() => {
    setViewport({
      latitude: 32.776665,
      longitude: -96.796989,
      zoom: 4,
    })
  }, [props.filtermarker])


  // if you are happy with Geocoder default settings, you can just use handleViewportChange directly
  const handleGeocoderViewportChange = useCallback(
    (newViewport) => {
      const geocoderDefaultOverrides = { transitionDuration: 1000 };

      return handleViewportChange({
        ...newViewport,
        ...geocoderDefaultOverrides
      });
    },
    [handleViewportChange]
  );

  return (
    <div className="mapheight">
      <MapGL
        ref={mapRef}
        {...viewport}
        width="100%"
        height="100%"
        onViewportChange={handleViewportChange}
        mapboxApiAccessToken={MAPBOX_TOKEN}
        mapStyle='mapbox://styles/mapbox/streets-v11'
      >
        {markerList.map((marker, index) => (
          <Marker offsetTop={-60} offsetLeft={-20} longitude={marker.long} latitude={marker.lat} anchor="bottom" key={index}>
            <img src={marker.marker} alt="marker" width={30} height={30} />

          </Marker>
        ))}

        <Geocoder
          mapRef={mapRef}
          onViewportChange={handleGeocoderViewportChange}
          mapboxApiAccessToken={MAPBOX_TOKEN}
          position="top-left"

        />
      </MapGL>
    </div>
  );
};


export default GMap;

import React from "react";
import { Link } from "react-router-dom";
import Contact from "../../Contactus/Contact";
import DashboardSliderslide from "../../DashboardSliderslide/DashboardSliderslide";
import Fleet from "../../Fleet/Fleet";
import {
  benefitsbanner,
  check,
  image3,
  gasolinecontactbanner,
  gasolineeffeciency,
  getgassed,
} from "../../Imagesjs/Images";
import Partners from "../../Partners/Partners";
import "./GasOnline.css";
function GasOnline() {
  const type = 9;
  const downLoadPros = [
    "We’re available 24/7 to fit the schedule and needs of your fleet operation",
    "Our user panel reports fuel savings and direct benefit you are making on the environment",
    "Bring optimum productivity to your operation by saving time",
  ];
  const deselDefListingefficien = [
    "Stay on the go",
    "We deliver to you so you can deliver more",
    "High quality fuel brought to you directly from the terminal",
  ];
  const BenefitsList = [
    "One less stop to make on your weekly errands",
    "We bring the fuel directly to you parked car, wherever you are getting things done! ",
    "One less stop to make on your weekly errands",
  ];
  const DashboardSliderslid = [{ image: image3 }];
  const contactbanner = gasolinecontactbanner;
  return (
    <>
      <section className="inner-banner section-banner businessfleet gasoline">
        <div className="background-image">
          <img src={getgassed} alt="get gassed" />
        </div>
        <div className="container position-relative">
          <div className="row align-items-lg-center align-items-end">
            <div className="col-xl-7 col-lg-8 col-12">
              <div className="home-banner-texter text-lg-start text-center">
                <h1>
                  <span
                    className="wow fadeInUp text-white"
                    data-wow-delay="0.2s"
                  >
                    GAS ON{" "}
                  </span>
                  <br />
                  <span
                    className="wow fadeInUp text-white"
                    data-wow-delay="0.4s"
                  >
                    THE{" "}
                    <span
                      className="wow fadeInUp text-orange"
                      data-wow-delay="0.4s"
                    >
                      GO!
                    </span>
                  </span>
                </h1>
                <p className="text-captialized">
                  Bringing optimum productivity to small and medium <br /> fleet
                  vehicles and daily consumer vehicles
                </p>
              </div>
            </div>
            <div className="col-md-6"></div>
          </div>
        </div>
      </section>
      <section className="common-section-all efficient-delivery">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-12 col-sm-12 dashboard-listing">
              <div className="row align-items-center justify-content-center listing-list flex-row-reverse">
                <div className="col-lg-6 col-12 ps-lg-5">
                  <div className="heading-style wow fadeInUp ps-lg-5">
                    <h5 className=" text-start">
                      Efficiency,{" "}
                      <span className="text-orange">Delivered.</span>
                    </h5>
                  </div>
                  <ul className=" ps-lg-5">
                    {deselDefListingefficien.map((deselDefListin, i) => (
                      <li
                        className="wow fadeInRight"
                        data-wow-delay={`0.` + i + `s`}
                        key={i}
                      >
                        <i className="me-3">
                          <img src={check} alt="check" />
                        </i>
                        {deselDefListin}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col-lg-6 col-12 text-center mb-lg-0 mb-3 wow fadeInLeft dots-orange certified-banner">
                  <figure>
                    <img
                      src={gasolineeffeciency}
                      alt="gasoline effeciency"
                      className="img-fluid border-20 w-100"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all pt-lg-5 pt-0">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-12 col-sm-12 dashboard-listing">
              <div className="row align-items-start justify-content-center listing-list">
                <div className="col-lg-6 col-12 pt-4">
                  <div className="heading-style text-center wow fadeInUp">
                    <h5 className="text-start text-capitalize">
                      Fuel Your <span className="text-orange">Fleet</span>
                    </h5>
                  </div>
                  <ul>
                    {downLoadPros.map((benefitlist, i) => (
                      <li
                        className="wow fadeInLeft"
                        data-wow-delay={`0.` + i + `s`}
                        key={i}
                      >
                        <i className="me-3">
                          <img src={check} alt="check" />
                        </i>
                        {benefitlist}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col-lg-6 col-12 text-center mt-lg-0 mt-3 wow fadeInRight gasolinetabs">
                  <DashboardSliderslide
                    DashboardSliderslid={DashboardSliderslid}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Fleet />
      <section className="common-section-all mb-lg-4">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-12 col-sm-12 dashboard-listing">
              <div className="row align-items-center justify-content-center listing-list">
                <div className="col-lg-6 col-12">
                  <div className="heading-style text-center wow fadeInUp">
                    <h5 className="text-start text-capitalize">
                      How Gas It Up can <br />{" "}
                      <span className="text-orange">benefit your vehicle</span>
                    </h5>
                  </div>
                  <ul>
                    {BenefitsList.map((benefitlist, i) => (
                      <li
                        className="wow fadeInLeft"
                        data-wow-delay={`0.` + i + `s`}
                        key={i}
                      >
                        <i className="me-3">
                          <img src={check} alt="check" />
                        </i>
                        {benefitlist}
                      </li>
                    ))}
                  </ul>
                  <Link
                    to="/retail-and-rurface-parking-lots"
                    className="d-inline-flex px-5 btn btn--primary mt-lg-3 mb-3 mb-lg-3"
                  >
                    Read More
                  </Link>
                </div>
                <div className="col-lg-6 col-12 text-center mb-lg-0 mb-5 wow fadeInRight right-circle dots-purple">
                  <figure>
                    <img
                      src={benefitsbanner}
                      alt="benefits banner"
                      className="img-fluid border-20 w-100"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all business-partner py-5">
        <div className="container">
          <div className="col-md-12 px-lg-5 px-2 pb-0">
            <div className="heading-style text-center wow fadeInUp">
              <h5>
                Trusted By the <span className="text-orange">Best</span>
              </h5>
            </div>
          </div>
        </div>
        <Partners />
      </section>
      <section className="common-section-all gasolinecontact">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 pb-0">
              <div className="heading-style text-center wow fadeInUp">
                <h5 className="text-capitalize">
                  Get into contact with a{" "}
                  <span className="text-orange">Gas It Up</span> professional
                  that
                  <br /> can get your{" "}
                  <span className="text-orange"> business</span> set up for{" "}
                  <span className="text-orange"> success</span>
                </h5>
              </div>
            </div>
            <Contact
              type={type}
              contactbanner={contactbanner}
              showBusinessFields={false}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default GasOnline;

import React from "react";
import { Link } from "react-router-dom";
import "./BreadCrumb.css";

function BreadCrumb(props) {
  return (
    <>
      <nav
        aria-label="breadcrumb"
        className="wow fadeInUp"
        data-wow-delay="0.1s"
      >
        <ol className="breadcrumb justify-content-center">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            {props.pagename.name}
          </li>
        </ol>
      </nav>
    </>
  );
}

export default BreadCrumb;

import React from 'react'
import * as Yup from "yup";
import { Formik } from "formik";
// import axios from "axios";
function BlogLeaveComment(props) {
  return (
    <Formik
      initialValues={{
        text: "",
        email: "",
        commentBy: "",
        companyName: "",
        blogId:props.id2
      }}
      onSubmit={(values, action) => {
        action.resetForm();
        props.submitComment(values);
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Please enter a valid email")
          .required("Email required"),
          commentBy: Yup.string()
          .matches(/^[A-Za-z ]*$/, "Please enter valid firstname")
          .required("First name required"),       
        companyName: Yup.string()
          .matches(/^[A-Za-z ]*$/, "Please enter valid companyname")
          .required("Company name required"),
       
          text: Yup.string()
        .required("Message is required"),
      })}
    >
      {(props) => {
        const {
          touched,
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
        } = props;
        return (
          <>
            <div className="">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-sm-6 form-group">
                    <label>First Name*</label>
                    <input
                      type="text"
                      placeholder="Enter First Name"
                      className="form-control"
                      id="commentBy"
                      value={values.commentBy}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                    />
                    <p className="text-danger mb-0">
                      {errors.commentBy && touched.commentBy && (
                        <span className="input-feedback">
                          {errors.commentBy}
                        </span>
                      )}{" "}
                    </p>
                  </div>                  
                  <div className="col-md-6 form-group">
                    <label>Email Address*</label>
                    <input
                      type="email"
                      placeholder="Enter Email Address"
                      className="form-control"
                      id="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                    />
                    <p className="text-danger mb-0">
                      {" "}
                      {errors.email && touched.email && (
                        <span className="input-feedback">{errors.email}</span>
                      )}
                    </p>
                  </div>
                  <div className="col-md-12 form-group">
                    <label>Website Name*</label>
                    <input
                      type="text"
                      placeholder="Enter Company Name"
                      className="form-control"
                      id="companyName"
                      value={values.companyName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                    />
                    <p className="text-danger mb-0">
                      {errors.companyName && touched.companyName && (
                        <span className="input-feedback">
                          {errors.companyName}
                        </span>
                      )}{" "}
                    </p>
                  </div>
                </div>             
                  <div className="col-sm-12 form-group">
                    <label>Leave a Message*</label>
                    <textarea
                      rows={6}
                      cols={3}
                      className="w-100"
                      id="text"
                      value={values.text}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      autoComplete="off"
                    />
                    <p className="text-danger mb-0">
                      {" "}
                      {errors.text && touched.text && (
                        <span className="input-feedback">
                          {errors.text}
                        </span>
                      )}
                    </p>
                  </div>  
                <div className="row">
                  <div className="col-md-12 text-center">
                    <button
                      className="btn btn--primary px-5 mx-auto w-100 mt-3"
                      id="submit"
                      value="Submit"
                    >
                      <span>Submit</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </>
        );
      }}
    </Formik>
  )
}

export default BlogLeaveComment
import React from "react";
import { Link } from "react-router-dom";
import Contact from "../../Contactus/Contact";
import {
  autonomousfleet,
  autonomousfleetbanner,
  autonomuscontactbanner,
  futureprofessionalsbanner,
  trackingbg,
} from "../../Imagesjs/Images";
import "./AtonomonusFleet.css";
function AtonomonusFleet() {
  const type = 10;
  const contactbanner = autonomuscontactbanner;
  return (
    <>
      <section className="inner-banner section-banner autonomous-fleet">
        <div className="container">
          <div className="row align-items-lg-center align-items-end">
            <div className="col-lg-6 col-12">
              <div className="home-banner-texter text-lg-start text-center">
                <h1 className=" text-lg-start text-center">
                  THE ULTIMATE
                  <br />
                  <span
                    className="wow fadeInUp text-orange"
                    data-wow-delay="0.2s"
                  >
                    Autonomous
                  </span>
                  <br />
                  <span
                    className="wow fadeInUp text-white"
                    data-wow-delay="0.2s"
                  >
                    Solution
                  </span>
                </h1>
              </div>
            </div>
            <div className="col-lg-6 text-center">
              <div className="wow zoomIn">
                <figure>
                  <img
                    src={autonomousfleetbanner}
                    alt="autonomousfleet banner"
                    className="img-fluid w-md-100"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all mb-lg-3">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-12 col-sm-12 dashboard-listing">
              <div className="row align-items-center justify-content-center listing-list flex-row-reverse">
                <div className="col-lg-6 col-12 ps-lg-5 ">
                  <div className="heading-style text-start ps-lg-5 ">
                    <h5 className="text-start text-capitalize wow fadeInUp">
                      Autonomous <span className="text-orange">Fleets</span>
                    </h5>
                    <p className="wow fadeInUp" data-wow-delay="0.2s">
                      Our mobile fueling solution for autonomous fleets is
                      unique and necessary for the future of this industry. As
                      more and more autonomous vehicles hit the road, there will
                      be a need for safe, reliable fueling options.
                    </p>
                    <p className="wow fadeInUp" data-wow-delay="0.4s">
                      Gas It Up’s mobile fueling are perfect for this market
                      because they are fully automated, have no moving parts and
                      use high-quality diesel fuel.
                    </p>
                    <p className="wow fadeInUp" data-wow-delay="0.6s">
                      Our solution also provides drivers with real-time
                      reporting and data analytics on their fleet’s usage so
                      that they can make better decisions about where to refuel
                      next.
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-12 text-center mb-lg-0 mb-4 wow fadeInLeft dots-purple right-circle">
                  <figure>
                    <img
                      src={autonomousfleet}
                      alt="autonomous fleet"
                      className="img-fluid border-20 w-100"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all mb-lg-3 pt-0">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-12 col-sm-12 dashboard-listing">
              <div className="row align-items-center justify-content-center listing-list">
                <div className="col-lg-6 col-12 pe-lg-5 ">
                  <div className="heading-style text-start pe-lg-5">
                    <h5 className="text-start text-capitalize wow fadeInUp">
                      TuSimple <span className="text-orange">partnership</span>
                    </h5>
                    <p className=" wow fadeInUp" data-wow-delay="0.2s">
                      Working with the world's trailblazer in autonomous
                      trucking has partnered with Gas It Up for the ultimate
                      fueling solution, and keeping driverless trucks delivering
                      millions of miles per year.
                    </p>
                    <a
                      href="https://www.tusimple.com/"
                      target="_blank"
                      className="d-inline-flex px-5 btn btn--primary wow fadeInUp  mt-lg-3 mt-1 mb-3 mb"
                      data-wow-delay="0.3s"
                      rel="noopener noreferrer"
                    >
                      Read More
                    </a>
                  </div>
                </div>
                <div className="col-lg-6 col-12 text-center mb-lg-0 mb-5 wow fadeInRight dots-purple right-circle">
                  <figure>
                    <img
                      src={futureprofessionalsbanner}
                      alt="tusimple partnership"
                      className="img-fluid border-20 w-100"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all pt-lg-3 pt-0 leadershipbusiness">
        <div className="container">
          <div className="tracking-details">
            <div className="row align-items-center gx-0">
              <div className="col-lg-6 col-12">
                <figure>
                  <img
                    src={trackingbg}
                    alt="trackingbg"
                    className="img-fluid"
                    width="100%"
                  />
                </figure>
              </div>
              <div className="col-lg-6 col-12">
                <div className="heading-style text-start p-lg-5 mb-0">
                  <h5
                    className="text-start text-capitalize wow fadeInUp"
                    data-wow-delay="0.1s"
                  >
                    Autonomous <br />
                    <span className="text-orange">Mobile Fueling</span>
                  </h5>
                  <p className="mb-0 wow fadeInUp" data-wow-delay="0.2s">
                    We are a mobile fueling solution that is necessary for{" "}
                    <br />
                    the future of autonomous fleets.
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all fleet-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7 pb-0">
              <div className="heading-style text-lg-start text-center wow fadeInUp mb-lg-0 mb-3">
                <h5 className="text-lg-start text-center mb-lg-0">
                  Interested in{" "}
                  <span className="text-orange"> Partnering </span> with us?
                </h5>
              </div>
            </div>
            <div className="col-lg-5 pb-0 text-lg-end text-center">
              <Link
                to="/getafleet"
                className="px-5 btn btn--primary d-inline-flex"
              >
                <span>Learn More</span>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all leadershipbusiness contactusmargin">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 pb-0">
              <div className="heading-style text-center wow fadeInUp">
                <h5 className="text-capitalize">
                  Get into contact with a{" "}
                  <span className="text-orange">Gas It Up</span> professional
                  that
                  <br /> can get your{" "}
                  <span className="text-orange"> business</span> set up for{" "}
                  <span className="text-orange"> success</span>
                </h5>
              </div>
            </div>
            <Contact
              type={type}
              contactbanner={contactbanner}
              showBusinessFields={false}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default AtonomonusFleet;

import React, { useEffect } from "react";
import WOW from "wowjs";
import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "./Components/Header/Header.js";
import Main from "./Components/Main.js";
import Footer from "./Components/Footer/Footer.js";
import useLocalStorage from "use-local-storage";
import "./style.css";
import "./responsive.css";
import { arrowup } from "./Components/Imagesjs/Images.js";
// import { light } from "@material-ui/core/styles/createPalette.js";
// import LoadingAnimation from "./Components/LoadingAnimation/LoadingAnimation.js";

const App = () => {
  
  useEffect(() => {
    localStorage.clear();

    window.wow = new WOW.WOW({
      live: false,
    });
    window.wow.init();    
  }, []);

  const defaultDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const [theme, setTheme] = useLocalStorage(
    "theme",
    defaultDark ? "dark" : "light"
  );

  const switchTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
  };

  // const [loading, setLoading] = useState(false);
  //   useEffect(() => {
  //     setLoading(true);
  //     setTimeout(() => {
  //       setLoading(false);
  //     }, 3000);
  //   }, []);
  return (
    <>
      {/* {loading ? (
        <LoadingAnimation display={loading} />
      ) : 
      <div className="gasitup-t" onClick={ handleClick}>
          <div id="progress">
            <span id="progress-value"><i><img src={arrowup} alt="arrowup" height="18px"/></i></span>
          </div>
        <Header />
          <Main />  
        <Footer />
      </div> */}
 {/* */}
      <div className="gasitup-t"  data-theme={theme}>
        <button className="themeselect" onClick={switchTheme}>
          {theme === "light" ? "Dark" : "Light"} Theme
        </button>
        <div id="progress">
          <span id="progress-value">
            <i>
              <img src={arrowup} alt="arrowup" height="18px" />
            </i>
          </span>
        </div>
        {/* <Header />
        <Main /> */}
        <Header theme={theme}/>
        <Main theme={theme} />
        <Footer  theme={theme}/>
      </div>
    </>
  );
};

export default App;

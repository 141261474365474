import React from "react";
import B2c from "../../B2c/B2c";
import CertifiedSupplier from "../../CertifiedSupplier/CertifiedSupplier";
import FuelingFuture from "../../FuelingFuture/FuelingFuture";
import Partners from "../../Partners/Partners";
import Testimonial from "../../Testimonial/Testimonial";
import {
  airport,
  benefitsbanner,
  certifiedbanner,
  certifiedicon,
  certifiedicon2,
  check,
  fleet,
  academicleaderbanner,
  hospitals,
  hotel,
  leadershipcontactbanner,
  officeparks,
  school,
  stadium,
  img6,
  egle2,
  img12,
  img8,
  eagles2ndstadium,
  gamestadium
} from "../../Imagesjs/Images";

import "./CorporateContact.css";
import Contact from "../../Contactus/Contact";
import VideoBlock from "../../VideoBlock/VideoBlock";

function CorporateContact(props) {
  let pathname = window.location.pathname.replace(
    //eslint-disable-next-line
    /[`~!@#$%^&*()_|+\=?;:'",.<>\{\}\[\]\\\/]/gi,
    ""
  );
  const type = 13;
  const contactbanner = leadershipcontactbanner;
  const slidesData = [
    {
      imge: img6,
      testimonialbody:
        "I've been using Gas It Up for my business fleet and I couldn't be happier. Not only does their service save my time and energy, but it's also more affordable than buying fuel from the pump. I'd definitely recommend them to anyone looking for an easy, convenient way to fuel up their business!",
      title: "John Posillico",
      designation: "President, Arizona beverages",
    },
    {
      imge: img8,
      testimonialbody:
        "Blackridge Construction is grateful to have the Gas It Up trucks come out and fuel our fleet. The GIU Fuel management panel has given us tons of insight and data on our refueling process",
      title: "Chris Caroll",
      designation: "Blackridge Construction",
    },
    {
      imge: img12,
      testimonialbody:
        "The efficiency of our ambulance fleet has increased significantly since starting our relationship with GIU. The GIU fuel technicians deliver great customer service and leave us confident, knowing that LifeMed is prepared to assist in emergency situations.",
      title: "Maurice Williams",
      designation: "MedLife Ambulance ",
    },
    {
      imge: img8,
      testimonialbody:
        "Blackridge Construction is grateful to have the Gas It Up trucks come out and fuel our fleet. The GIU Fuel management panel has given us tons of insight and data on our refueling process",
      title: "Chris Caroll",
      designation: "Blackridge Construction",
    },
  ];
  const scenarios = [
    {
      icon: stadium,
      title: "Stadiums",
    },
    {
      icon: hospitals,
      title: "Hospitals",
    },
    {
      icon: officeparks,
      title: "Office Park",
    },
    {
      icon: airport,
      title: "Airport",
    },
    {
      icon: hotel,
      title: "Hotel",
    },
    {
      icon: school,
      title: "School",
    },
    {
      icon: fleet,
      title: "Fleet",
    },
  ];
  const certifiedblock = [
    {
      banner: certifiedbanner,
      isNewline: true,
      title: "Enterprise",
      titlesub: "Certified Supplier",
      list1:
        "Gas it up is one of, if not the only, Minority Business Enterprise Certified Supplier for mobile fueling in the US.",
    },
  ];
  const CertifieIcons = [
    {
      icon1: certifiedicon2,
      width: "130px",
      height: "130px"
    },
    {
      icon1: certifiedicon,
      width: "130px",
      height: "130px"
    },
  ];
  const List = [
    "Adding to the fan experience at select stadiums and arenas across the United States",
    "Sit back, relax, and enjoy the game - Don’t miss a minute of the action, Gas It Up is here to fuel your tank. It’s a win-win!",
    "Customized promos and giveaways for VIP season ticket holders and fans",
  ];
  const Lists = [
    "Order fuel and get home safely after you enjoy a game, concert, or event in MileHigh Stadium at Empower Field or at Lincoln Financial Field 2023",
    "Enjoy customized game day promotional codes and giveaways for Denver Broncos and Philadelphia Eagles home games!",
  ];
  const BenefitsList = [
    "Spend time doing the things you enjoy - you can get your gas tank filled while you’re working and save time getting home",
    "Schedule your fuel order through our app",
    "Ultimate perk for employees in-office! Customized rewards program coming soon for business office parks",
    "We are fully committed to supporting the healthcare and essential workers of our communities, offering custom promos and special memberships",
  ];
  const FuturePerfessionals = [
    "A sustainable, safe solution that brings the gas directly to students and staff on campus ",
    "High-quality fuel delivered through our app with tailored promotions and discounts for college faculty and students",
    "We believe in helping students and inspiring them to lead and change the future of technology and innovation",
    "Check out our careers tab for internship and job opportunities! ",
  ];

  return (
    <>
      <section className="section-banner inner-banner mapscale businessfleet">
        <div className="background-image">
          <VideoBlock path={pathname} />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
              <div className="home-banner-texter text-start ps-lg-5">
                <h5 className="text-md-start text-center">
                  GAS DELIVERED
                  <br /> <span className="text-white">WHEREVER</span>
                  <br /> <span className="text-orange">YOU PARK</span>
                </h5>
                <p className="text-md-start text-center">
                  The Gas Station Should Come to you
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <B2c scenarios={scenarios} Isheadtrue={false} />
      <section className="common-section-all pt-0 mb-3">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-12 col-sm-12 dashboard-listing">
              <div className="row align-items-center justify-content-center listing-list">
                <div className="col-lg-6 col-12">
                  <div className="heading-style text-center wow fadeInUp mb-4">
                    <h5 className="text-start text-capitalize">
                      Bringing the <span className="text-orange">benefits</span>{" "}
                      to you!{" "}
                    </h5>
                  </div>
                  <ul className="mb-lg-0 mb-5">
                    {BenefitsList.map((benefitlist, i) => (
                      <li
                        className="wow fadeInLeft"
                        data-wow-delay={`0.` + i + `s`}
                        key={i}
                      >
                        <i className="me-3">
                          <img src={check} alt="check" />
                        </i>
                        {benefitlist}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="col-lg-6 col-12 text-center mb-lg-0 mb-5 wow fadeInRight dots-purple right-circle">
                  <figure>
                    <img
                      src={benefitsbanner}
                      alt="benefits banner"
                      className="img-fluid border-20 w-100"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all leadershipbusiness bggreydiff">
        <div className="container pb-lg-4">
          <div className="col-md-12 col-sm-12 dashboard-listing">
            <div className="row align-items-center justify-content-center listing-list flex-row-reverse">
              <div className="col-lg-6 col-12 ps-lg-5">
                <div className="heading-style text-center wow fadeInUp mb-4">
                  {/* <h5 className="text-start  text-capitalize">
                    Fueling the <span className="text-orange">Fandom</span>{" "}
                  </h5> */}
                    <h5 className="text-start  text-capitalize">
                    Changing The <span className="text-orange">Game</span>{" "}
                  </h5>
                </div>
                {/* <ul className="mb-lg-0 mb-5">
                  {List.map((listing, i) => (
                    <li
                      className="wow fadeInRight"
                      data-wow-delay={`0.` + i + "s"}
                      key={i}
                    >
                      <i className="me-3">
                        <img src={check} alt="check" />
                      </i>
                      {listing}
                    </li>
                  ))}
                </ul> */}
                 <ul className="mb-lg-0 mb-5">
                  {Lists.map((listing, i) => (
                    <li
                      className="wow fadeInLeft"
                      data-wow-delay={`0.` + i + "s"}
                      key={i}
                    >
                      <i className="me-3">
                        <img src={check} alt="check" />
                      </i>
                      {listing}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-lg-6 col-12 text-center mb-lg-0 mb-5 wow fadeInLeft dots-purple right-circle">
                <figure>
                  <img
                    src={gamestadium}
                    alt="freedom banner"
                    className="img-fluid border-20 w-100"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all py-4">
        <div className="container">
          <div className="col-md-12 col-sm-12">
            <div className="row align-items-center justify-content-center">
              {/* <div className="col-lg-2 col-3 text-center mb-lg-0 mb-5 wow fadeInLeft">
                <img src={egle1} alt="egle" className="img-fluid" />
              </div> */}
              <div className="col-lg-8 col-6 text-center mb-lg-0 mb-5 wow fadeInUp heading-style">
              <div className="col-lg-12 col-12 text-center mb-lg-0 mb-5 wow fadeInRight">
                <img src={egle2} alt="egle" className="img-fluid" />
              </div>
                <h4
                  className="fw-bold"
                  style={{ fontFamily: "var(--FuturaBold)" }}
                >
                  <span className="text-orange">
                    Official Mobile Fueling And 
                    Mobile Electric Vehicle <br /> Charging Provider Of The
                  </span>
                  {/* <br />  */}
                  {/* Denver Broncos And  */}
                  <span> Philadelphia Eagles</span>
                 
                </h4>
              </div>
              
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all leadershipbusiness bggreydiff">
        <div className="container pb-md-4">
          <div className="col-md-12 col-sm-12 dashboard-listing">
            <div className="row align-items-center justify-content-center listing-list flex-row-reverse">
              <div className="col-lg-6 col-12 text-center mb-lg-0 mb-5 wow fadeInRight dots-purple right-circle">
                <figure>
                  <img
                    src={eagles2ndstadium}
                    alt="egle stadium"
                    className="img-fluid border-20 w-100"
                  />
                </figure>
              </div>
              <div className="col-lg-6 col-12 ps-lg-5">
                <div className="heading-style text-center wow fadeInUp mb-4">
                  {/* <h5 className="text-start  text-capitalize">
                    Changing The <span className="text-orange">Game</span>{" "}
                  </h5> */}
                   <h5 className="text-start  text-capitalize">
                    Fueling the <span className="text-orange">Fandom</span>{" "}
                  </h5>
                </div>
                {/* <ul className="mb-lg-0 mb-5">
                  {Lists.map((listing, i) => (
                    <li
                      className="wow fadeInLeft"
                      data-wow-delay={`0.` + i + "s"}
                      key={i}
                    >
                      <i className="me-3">
                        <img src={check} alt="check" />
                      </i>
                      {listing}
                    </li>
                  ))}
                </ul> */}
                <ul className="mb-lg-0 mb-5">
                  {List.map((listing, i) => (
                    <li
                      className="wow fadeInRight"
                      data-wow-delay={`0.` + i + "s"}
                      key={i}
                    >
                      <i className="me-3">
                        <img src={check} alt="check" />
                      </i>
                      {listing}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all mb-3">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-12 col-sm-12 dashboard-listing">
              <div className="row align-items-center justify-content-center listing-list">
                <div className="col-lg-6 col-12 text-center mb-lg-0 mb-5 wow fadeInLeft dots-purple right-circle">
                  <figure>
                    <img
                      src={academicleaderbanner}
                      alt="academic leader banner"
                      className="img-fluid border-20 w-100"
                    />
                  </figure>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="heading-style text-center wow fadeInUp mb-4 ps-lg-5">
                    <h5 className="text-start  text-capitalize">
                      Pumping Up{" "}
                      <span className="text-orange">Academic leaders</span> and{" "}
                      <span className="text-orange">
                        future professionals!{" "}
                      </span>
                    </h5>
                  </div>
                  <ul className="mb-lg-0 mb-5 ps-lg-5">
                    {FuturePerfessionals.map((futurelist, i) => (
                      <li
                        className="wow fadeInRight"
                        data-wow-delay={`0.` + i + `s`}
                        key={i}
                      >
                        <i className="me-3">
                          <img src={check} alt="check" />
                        </i>
                        {futurelist}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all business-partner py-5 leadershipbusiness mt-lg-5">
        <div className="container">
          <div className="col-md-12 px-lg-5 px-2 pb-0">
            <div className="heading-style text-center wow fadeInUp">
              <h5>
                Fueling the <span className="text-orange">Community</span>
              </h5>
            </div>
          </div>
        </div>
        <Partners />
      </section>
      <section className="common-section-all pb-0">
        <FuelingFuture Istitlehaed={true} />
      </section>
      <section className="common-section-all py-4">
        <CertifiedSupplier
          certifiedblock={certifiedblock}
          CertifieIcons={CertifieIcons}
        />
      </section>
      <section className="common-section-all pt-4 mb-3 mb-lg-0 leadershipbusiness">
        <div className="container">
          <div className="col-md-12 px-lg-5 px-2 pb-0">
            <div className="heading-style text-center wow fadeInUp mb-3">
              <h5>Customer Testimonies</h5>
            </div>
          </div>
        </div>
        <Testimonial slidesData={slidesData} />
      </section>
      <section className="common-section-all pt-0 telematicdashboardcontact">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 pb-0">
              <div className="heading-style text-center wow fadeInUp">
                <h5 className="text-capitalize">
                  Get into contact with a{" "}
                  <span className="text-orange">Gas It Up </span>professional
                  that
                  <br /> can get your{" "}
                  <span className="text-orange"> business</span> set up for{" "}
                  <span className="text-orange"> success</span>
                </h5>
              </div>
            </div>
            <Contact
              contactbanner={contactbanner}
              type={type}
              showBusinessFields={true}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default CorporateContact;

import React from "react";
import CertifiedSupplier from "../../CertifiedSupplier/CertifiedSupplier";
import Contact from "../../Contactus/Contact";
import DashboardSliderslide from "../../DashboardSliderslide/DashboardSliderslide";
import {
  geoofencingcontactbannernew,
  geoofencingtechnology,
  geoofensingbanner,
  geoofencing,
} from "../../Imagesjs/Images";
import Partners from "../../Partners/Partners";
import Playstoreicons from "../../Playstoreicons/Playstoreicons";
import "./Geofencing.css";
function Geofencing() {
  const type = 7;
  const contactbanner = geoofencingcontactbannernew;
  const certifiedblock = [
    {
      banner: geoofencingtechnology,
      title: "Our",
      titlesub: "Technology",
      isNewLine: false,
      list1:
        "Our technology uses a custom created location-based Geofencing tactics, that allows Gas It Up to specifically section out and pinpoint the exact areas and vehicles that are approved for fueling.",
    },
  ];
  const DashboardSliderslid = [{ image: geoofencing }];

  const CertifieIcons = [];
  return (
    <>
      <section className="inner-banner section-banner geofencing">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="home-banner-texter text-md-start text-center">
                <h1 className="wow fadeInUp" data-wow-delay="0.2s">
                  <span
                    className="wow fadeInUp text-white"
                    data-wow-delay="0.4s"
                  >
                    We’re On The
                  </span>{" "}
                  <br />
                  <span
                    className="wow fadeInUp text-orange"
                    data-wow-delay="0.4s"
                  >
                    Way To You!
                  </span>
                </h1>
                <p>
                  Our Geofencing capabilities were created to make your life
                  easier
                  <br /> and our technology even better by bringing the fuel to
                  your vehicle
                  <br /> or fleet's exact location!
                </p>
                <Playstoreicons />
              </div>
            </div>
            <div className="col-md-6">
              <div className="wow zoomIn text-center">
                <figure>
                  <img
                    src={geoofensingbanner}
                    alt="geoofensing banner"
                    className="img-fluid"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="pt-lg-5">
        <CertifiedSupplier
          certifiedblock={certifiedblock}
          CertifieIcons={CertifieIcons}
        />
      </div>

      <section className="common-section-all efficient-delivery geofencing position-relative leadershipbusiness pt-0 pt-md-5">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-12 col-sm-12 dashboard-listing">
              <div className="row align-items-center justify-content-center listing-list">
                <div className="col-lg-6 col-12 pe-lg-5">
                  <div className="heading-style wow fadeInUp pe-lg-5">
                    <h5 className=" text-start">
                      Geofencing
                      <span className="text-orange"> Technology</span>
                    </h5>
                    <h4 className="text-capitalize">
                      <small>Gas It Up owns all geofencing technology</small>
                    </h4>
                    <p>
                      Gas It Up offers consumers a more convenient and
                      environmentally friendly option for refueling as well as
                      corporate locations, who are seeking greater control over
                      the fueling process through our Geofencing tactics. The
                      ability to direct fuel in specific areas will reduce fleet
                      costs.{" "}
                    </p>
                  </div>
                  <div className=" banner-actions d-flex align-items-center justify-content-start mt-2 pe-lg-5 mb-lg-0 mb-4"></div>
                </div>
                <div className="col-lg-6 col-12 text-center mb-lg-0 mb-5 wow fadeInRight">
                  <DashboardSliderslide
                    DashboardSliderslid={DashboardSliderslid}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all business-partner py-5 leadershipbusiness">
        <div className="container">
          <div className="col-md-12 px-lg-5 px-2 pb-0">
            <div className="heading-style text-center wow fadeInUp">
              <h5>
                Trusted By The <span className="text-orange">Best</span>
              </h5>
            </div>
          </div>
        </div>
        <Partners />
      </section>
      <section className="common-section-all">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 pb-0">
              <div className="heading-style text-center wow fadeInUp">
                <h5 className="text-capitalize">
                  Get into contact with a{" "}
                  <span className="text-orange">Gas It Up</span> professional
                  that
                  <br /> can get your{" "}
                  <span className="text-orange"> business</span> set up for{" "}
                  <span className="text-orange"> success</span>
                </h5>
              </div>
            </div>
            <Contact
              contactbanner={contactbanner}
              type={type}
              showBusinessFields={false}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default Geofencing;

import React from "react";
import { Link } from "react-router-dom";
import Contact from "../../Contactus/Contact";
import {
  ourfueltechcontactbanner,
  driverappgallons,
  oshalogo,
  safety,
  safetybanner,
  ourfueltechbg,
  ourfueltechthumb,
  lytx,
  ourfueltechnicians,
  ourfueltechbgwhite,
} from "../../Imagesjs/Images";
import Partners from "../../Partners/Partners";
import "./OurFuelTecsSefaty.css";
function OurFuelTecsSefaty(props) {
  const type = 11;
  const contactbanner = ourfueltechcontactbanner;
  return (
    <>
      <section className="inner-banner section-banner fueltechsafety">
        <div className="background-image">
          <img
            src={props.theme === "dark" ? ourfueltechbg : ourfueltechbgwhite}
            alt="our fuel tech are here"
          />
        </div>
        <div className="container position-relative">
          <div className="row align-items-lg-center align-items-end">
            <div className="col-xl-6 col-lg-6 col-12">
              <div className="home-banner-texter text-start">
                <h1 className="text-start">
                  <span
                    className="wow fadeInUp text-white"
                    data-wow-delay="0.2s"
                  >
                    Our Fuel{" "}
                  </span>
                  <br />
                  <span
                    className="wow fadeInUp text-white bg-grey-content"
                    data-wow-delay="0.2s"
                  >
                    <small>Techs are here</small>
                  </span>
                  <br />
                  <span
                    className="wow fadeInUp text-orange"
                    data-wow-delay="0.4s"
                  >
                    for You
                  </span>
                </h1>
              </div>
            </div>
            <div className="col-md-6">
              <figure className="ourfueltechthumbbanner">
                <img
                  src={ourfueltechthumb}
                  alt="Our Fuel Techs are here"
                  className="img-fluid  wow fadeInRight"
                  width="100%"
                />
              </figure>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all mb-lg-5">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-12 col-sm-12 dashboard-listing">
              <div className="row align-items-center justify-content-center listing-list">
                <div className="col-lg-6 col-12 pe-lg-5">
                  <div className="heading-style text-center wow fadeInUp pe-lg-5">
                    <h5 className="text-start text-capitalize">
                      Our Fuel <span className="text-orange">Technicians </span>
                    </h5>
                    <p className="text-start ">
                      Our Fuel Technicians are trained, certified, and
                      rigorously follow all local, state, municipal, and
                      international fire codes. They always wear certified
                      personal protective equipment (PPE), and do not have to
                      come into contact with any individual. Each technician has
                      been trained in working safely around fuel storage tanks
                      and pumps to ensure that they are kept safe during
                      operation.{" "}
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-12 text-center mb-lg-0 mb-5 wow fadeInRight dots-purple right-circle">
                  <figure>
                    <img
                      src={ourfueltechnicians}
                      alt=" Our Fuel Technicians"
                      className="img-fluid border-20 w-100"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all pt-0">
        <div className="container">
          <div className="tracking-details">
            <div className="row align-items-center gx-0">
              <div className="col-lg-6 col-12">
                <figure>
                  <img
                    src={driverappgallons}
                    alt="driver app gallons"
                    className="img-fluid"
                    width="100%"
                  />
                </figure>
              </div>
              <div className="col-lg-6 col-12">
                <div className="heading-style text-start p-lg-5 mb-0">
                  <h5
                    className="text-start text-capitalize wow fadeInUp"
                    data-wow-delay="0.1s"
                  >
                    Driver app <span className="text-orange">technology</span>
                  </h5>
                  <p className="mb-0 wow fadeInUp" data-wow-delay="0.2s">
                    We are happy to announce the release of our new driver app{" "}
                    <br /> technology.
                    <br /> <br />
                    This new driver app technology allows us to see the fuel
                    tech <br />
                    how many gallons are being pumped on the app cloud.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all fleet-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7 pb-0">
              <div className="heading-style text-lg-start text-center wow fadeInUp mb-lg-0 mb-3">
                <h5 className="text-lg-start text-center  mb-lg-0">
                  Are You Interested In{" "}
                  <span className="text-orange">GIU career opportunities?</span>
                </h5>
              </div>
            </div>
            <div className="col-lg-5 pb-0 text-lg-end text-center">
              <Link
                to="/careers"
                className="px-5 btn btn--primary d-inline-flex"
              >
                <span>Read More</span>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all mb-lg-5 safety-dots">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-12 col-sm-12 dashboard-listing">
              <div className="row align-items-center justify-content-center listing-list">
                <div className="col-lg-6 col-12 pe-lg-5">
                  <div className="heading-style text-start wow fadeInUp pe-lg-5">
                    <div className="d-flex align-items-center mb-3">
                      <img src={safety} alt="safety" />
                      <h5 className="text-start text-capitalize ps-3 mb-0">
                        Safety
                      </h5>
                    </div>
                    <p className="text-start">
                      Safety is critical to our business and we've invested
                      heavily in ensuring that each of our units are safe and
                      fully compliant with local laws and regulations. We are
                      proud to say that all of our units have been inspected and
                      approved by local fire marshals in every active GIU
                      market. Our units are equipped with safety features like
                      vapor recovery units, kill switches, overflow protection,
                      grounding cables and more to ensure your safety as well as
                      the environment.
                    </p>
                    <p>
                      GIU's entire operation is contactless. Users can stay
                      safe, keep their hands clean, and fill up their tank with
                      the touch of a button.
                    </p>
                    <p
                      className="bg-white d-inline-block py-2 px-3  wow zoomIn me-3 mt-3"
                      style={{ borderRadius: "10px", width: "150px" }}
                    >
                      <img
                        src={oshalogo}
                        alt="oshalogo"
                        width="120px"
                        height={39}
                      />{" "}
                    </p>
                    <p
                      className="bg-white d-inline-block py-2 px-3  wow zoomIn mt-3 text-center"
                      data-wow-delay="0.5s"
                      style={{ borderRadius: "10px", width: "150px" }}
                    >
                      <img
                        src={lytx}
                        alt="lytx"
                        className="img-fluid"
                        height="49px"
                      />
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-12 text-center mb-lg-0 mb-5 wow fadeInRight dots-purple right-circle">
                  <figure>
                    <img
                      src={safetybanner}
                      alt="safety banner"
                      className="img-fluid border-20 w-100"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all business-partner py-5">
        <div className="container">
          <div className="col-md-12 px-lg-5 px-2 pb-0">
            <div className="heading-style text-center wow fadeInUp">
              <h5>
                Trusted By <span className="text-orange">The Best</span>
              </h5>
            </div>
          </div>
        </div>
        <Partners />
      </section>
      <section className="common-section-all ourtechfuelcontact">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 pb-0">
              <div className="heading-style text-center wow fadeInUp">
                <h5 className="text-capitalize">
                  contact a <span className="text-orange"> Gas It Up</span>{" "}
                  professional
                </h5>
              </div>
            </div>
            <Contact
              type={type}
              contactbanner={contactbanner}
              showBusinessFields={false}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default OurFuelTecsSefaty;

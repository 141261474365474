import React from "react";
import { Link } from "react-router-dom";
import Contact from "../../Contactus/Contact";
import DashboardSlider from "../../DashboardSlider/DashboardSlider";
import {
  telematicbanner,
  check,
  futureprofessionalsbanner,
  telematiccontactbanner,
  arrow,
  image8,
  wex,
  esri,
  voyager,
  comdata,
  image6,
} from "../../Imagesjs/Images";
import Partners from "../../Partners/Partners";
import TabSlider from "../../TabSlider/TabSlider";
import "./TelematicDashboard.css";

function TelematicDashboard() {
  const slideimag = [{ image1: image8 }, { image1: image6 }];
  const type = 5;
  const contactbanner = telematiccontactbanner;
  const beyondpump = [
    "Manage all Delivery Locations and Vehicles",
    "Connect Your Telematics",
    "Transaction & Order History and Insights",
    "View Total Savings on Fuel",
    <span>
      CO<sub>2</sub> Emissions Covered
    </span>,
    "Mileage Saved",
    "Real-time Insights",
    "Immediate Invoicing",
    "The ability to use approved fuel cards",
    "A user panel that has access to a range of data and metrics from your fueling history",
  ];
  return (
    <>
      <section className="section-banner inner-banner build-pump telematicdashboard">
        <div className="banner-images">
          <i className="">
            <img
              className="for-one"
              src={telematicbanner}
              alt="telematic banner"
            />
          </i>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
              <div className="home-banner-texter text-lg-start text-center ps-lg-5">
                <h1 className="text-lg-start text-center">
                  <span className="text-white">
                    Beyond
                    <br />
                  </span>
                  <span className="f-75">
                    the
                    <span className="text-orange"> Pump</span>
                  </span>
                </h1>
                <p className="pt-2">The ultimate insights for your fleet</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all scroll-listing">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-12 col-sm-12 dashboard-listing">
              <div className="heading-style text-center">
                <h5 className="wow fadeInUp">
                  Manage your entire{" "}
                  <span className="text-orange">business fleet</span> through
                  your
                  <br /> personalized Gas It Up{" "}
                  <span className="text-orange">Fuel Management Platform</span>
                </h5>
              </div>
              <div className="row align-items-center justify-content-center listing-list flex-row-reverse">
                <div className="col-lg-6 col-12 text-center mb-lg-0 mb-5 wow fadeInRight">
                  <DashboardSlider />
                </div>
                <div className="col-lg-6 col-12 pe-lg-5">
                  <ul>
                    {beyondpump.map((beyondpumpList, i) => (
                      <li
                        className="wow fadeInLeft"
                        data-wow-delay={`0.` + i + `s`}
                        key={i}
                      >
                        <i className="me-lg-3 me-2">
                          <img src={check} alt="check" />
                        </i>
                        {beyondpumpList}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all py-0">
        <div className="container">
          <div className="optimun-productivity">
            <div className="col-md-12">
              <ul className="optimumlogos mb-5 mt-lg-5">
                <li>
                  <img src={wex} alt="wex" height={42} />
                </li>
                <li>
                  <img src={comdata} alt="verizonconnect" height={36} />
                </li>
                <li>
                  <img src={voyager} alt="lytx" height={28} />
                </li>
                <li>
                  <img src={esri} alt="esri" height={39} />
                </li>
              </ul>
              <div className="heading-style text-center">
                <h5 className=" wow FadeInUp" data-wow-delay="0.2s">
                  Gas It Up {" "}
                  <span className="text-orange">Fuel Management Software</span>{" "}
                </h5>
                <p className="wow FadeInUp" data-wow-delay="0.3s">
                  The Gas It Up Fuel Management Software is a comprehensive
                  solution that allows businesses to manage their entire fleet
                  of
                  <br /> vehicles and delivery locations. The platform provides
                  real-time insights into fuel usage, CO<sub>2</sub> emissions,
                  and mileage saved.
                  <br /> Businesses can connect their telematics devices to the
                  platform, view their transaction and order history, and
                  download various
                  <br /> custom fuel reports.
                </p>
              </div>
            </div>
            <div
              className="col-md-10 col-12 mx-auto wow FadeInUp arrow-top"
              data-wow-delay="0.2s"
            >
              <TabSlider slideimages={slideimag} />
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all mb-lg-3">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-12 col-sm-12 dashboard-listing">
              <div className="row align-items-center justify-content-center listing-list">
                <div className="col-lg-6 col-12  pe-lg-5">
                  <div className="heading-style text-lg-start text-center wow fadeInUp pe-lg-5">
                    <h5 className="text-lg-start text-center text-capitalize mb-lg-2">
                      Innovating for{" "}
                      <span className="text-orange">the Future</span>
                    </h5>
                    <p className="text-lg-start text-center">
                      In the continuing evolution of our technology, Gas It Up
                      is always striving to advance and lead the mobile fueling
                      industry. We are developing the capability to connect with
                      vendors to deliver real time or near real-time decision
                      points regarding the truck's fuel or charge status.
                    </p>
                    <Link
                      to="/getafleet"
                      className="btn btn--primary clickcustomform wow animate fadeInUp d-inline-flex mt-3"
                      data-wow-delay="0.8s"
                    >
                      <span>Get a Fleet Quote </span>
                      <i className="ms-3">
                        <img
                          src={arrow}
                          alt="arrow"
                          width="10px"
                          height={18}
                          className="d-flex wow"
                        />
                      </i>
                    </Link>
                  </div>
                </div>
                <div className="col-lg-6 col-12 text-center mb-lg-0 mb-5 wow fadeInRight dots-purple right-circle">
                  <figure>
                    <img
                      src={futureprofessionalsbanner}
                      alt="future professionals banner"
                      className="img-fluid border-20 w-100"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all business-partner py-5 mt-lg-5">
        <div className="container">
          <div className="col-md-12 px-lg-5 px-2 pb-0">
            <div className="heading-style text-center wow fadeInUp">
              <h5>
                Trusted by <span className="text-orange">the best</span>
              </h5>
            </div>
          </div>
        </div>
        <Partners />
      </section>
      <section className="common-section-all telematicdashboardcontact">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 pb-0">
              <div className="heading-style text-center wow fadeInUp">
                <h5 className="text-capitalize">
                  contact a <span className="text-orange">Gas It Up</span>{" "}
                  professional & set your{" "}
                  <span className="text-orange"> business</span> <br />
                  up for <span className="text-orange"> success</span>
                </h5>
              </div>
            </div>
            <Contact
              contactbanner={contactbanner}
              type={type}
              showBusinessFields={true}
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default TelematicDashboard;

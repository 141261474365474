import React from "react";
import { contactus } from "../Imagesjs/Images";
import "./GetAFleetQoute.css";
import GetAFleetQoute from "./GetAFleetQoute";

function GetAFleetqout(props) {
  const fleetbanner = contactus;
  return (
    <>
      <div className="col-12 ">
        <div className="row align-items-start contact-form mx-0 getfleetqouteblock">
          <div className="col-xl-6 col-lg-12 wow fadeInLeft px-0">
            <figure>
              <img
                src={props.fleetbanner ? props.fleetbanner : fleetbanner}
                alt="contactus"
                className="img-fluid w-100"
              />
            </figure>
          </div>
          <div className="col-xl-6 col-lg-12 wow fadeInRight px-md-5 px-3 getfleetqouteform">
            <GetAFleetQoute />
          </div>
        </div>
      </div>
    </>
  );
}

export default GetAFleetqout;

import React, { useEffect, useRef,useState } from "react";
// import b2bfleetsrender from "../assets/images/b2bfleetsrender.mp4";
import {
    b2bfleetsrender
  } from "../Imagesjs/Images";
import { b2bfleetsrenderweb, Videoblock, Videowebm, corporatestadiumsweb, corporatestadiums } from "../Imagesjs/Images";

// import { mute, unmute } from "../Imagesjs/Images";
import "./VideoBlock.css";

function VideoBlock(props) {
  // const [image, SetImage] = useState(mute);
// const [webvideo,setwebvideo] = useState(false)
const [b2bvideopath,setb2bvideopath ] = useState()
const [corporatepath,setcorporatepath] = useState()
const [videopath,setvideopath] = useState()
// const [safarivideo,setsafarivideo] = useState(false)
  // const [images, SetImages] = useState(play);
  const videoRef = useRef(null);
  // const handleToggleMute = () => {
  //   var myAudio = document.getElementById("audio");
  //   if (image === mute) {
  //     SetImage(unmute);
  //   } else {
  //     SetImage(mute);
  //   }
  //   myAudio.muted = !myAudio.muted;
  // };

  // const videoaction = () => {
  //   var myAudio = document.getElementById("audio");
  //   var PlayButton = document.getElementById("Play_Pause");
  //   if (myAudio.paused === true) {
  //     myAudio.play();
  //     PlayButton.innerHTML = '<img src="' + play + '"width="25px"  />';
  //     // SetImages(play);
  //   } else {
  //     myAudio.pause();
  //     PlayButton.innerHTML ='<img src="' + pause + '"width="25px" />';
  //     // SetImages(pause);
  //   }
  // };

  useEffect(() => {
    let options = {
      rootMargin: "0px",
      threshold: [0.25, 0.75],
    };

    let handlePlay = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          videoRef.current.play();
        } else if (entry.isIntersecting) {
          videoRef.current.pause();
        }
      });
    };

    let observer = new IntersectionObserver(handlePlay, options);
    observer.observe(videoRef.current);

    const browserName = navigator.userAgent;
    let browser =  getBrowserName(browserName);
    localStorage.setItem('browser_name', browser);

    if(browser === "Apple Safari"){
      setb2bvideopath(b2bfleetsrenderweb)
      setcorporatepath(corporatestadiumsweb)
      setvideopath(Videowebm)
    }



  },[]);

  function getBrowserName(userAgent) {
    if (userAgent.includes("Firefox")) {
   
      return "Mozilla Firefox";
    } else if (userAgent.includes("SamsungBrowser")) {
      
      return "Samsung Internet";
    } else if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
    
      return "Opera";
    } else if (userAgent.includes("Trident")) {
   
      return "Microsoft Internet Explorer";
    } else if (userAgent.includes("Edge")) {
   
      return "Microsoft Edge (Legacy)";
    } else if (userAgent.includes("Edg")) {
     
      return "Microsoft Edge (Chromium)";
    } else if (userAgent.includes("Chrome")) {
      
      return "Google Chrome or Chromium";
    } else if (userAgent.includes("Safari")) {
      
      return "Apple Safari";
    } else {
      return "unknown";
    }
  }

  return (
    <>
      <div className="video_block border-rounded position-relative">
        {props.path ===
        "business-fleets-trucking-and-construction-companies" ? (
          <video id="audio" autoPlay loop playsInline ref={videoRef} muted>
           <source src={b2bfleetsrender} data-wf-ignore="true" />
           <track src={b2bfleetsrender} kind="captions" srclang="en" label="english_captions"></track>
           {b2bvideopath && <source src={b2bvideopath} data-wf-ignore="true" />}
          </video>
        ) : props.path === "our-process" ? (
          <video id="audio" autoPlay loop playsInline ref={videoRef} muted>
            <source src={corporatestadiums} data-wf-ignore="true" />
            <track src={corporatestadiums} kind="captions" srclang="en" label="english_captions"></track>
           { corporatepath && <source src={corporatepath} data-wf-ignore="true" />}
          </video>
        ) : props.path === "retail-and-rurface-parking-lots" ? (
          <video id="audio" autoPlay loop playsInline ref={videoRef} muted>
            {/* <source src={trackingbg} data-wf-ignore="true" /> */}
            <source src={Videoblock} data-wf-ignore="true" />
            <track src={Videoblock} kind="captions" srclang="en" label="english_captions"></track>
           {videopath && <source src={videopath} data-wf-ignore="true" />}
          </video>
        ) : props.path ===
          "corporate-and-college-campuses-stadiums-and-arenas" ? (
          <video id="audio" autoPlay loop playsInline ref={videoRef} muted>
            <source src={corporatestadiums} data-wf-ignore="true" />
            <track src={corporatestadiums} kind="captions" srclang="en" label="english_captions"></track>
           {corporatepath && <source src={corporatepath} data-wf-ignore="true" />}
          </video>
        ) : (
          <video id="audio" autoPlay loop playsInline ref={videoRef} muted>
            <source src={b2bfleetsrender} data-wf-ignore="true" />
            <track src={b2bfleetsrender} kind="captions" srclang="en" label="english_captions"></track>
           {b2bvideopath && <source src={b2bvideopath} data-wf-ignore="true" />}
          </video>
        )}

        {/* <button onClick={handleToggleMute} className="control btn btn--primary">
          <img src={image} alt="ToggleMute" width="25px" />
        </button> */}
        {/* <button
          onClick={videoaction}
          id="Play_Pause"
          className=" btn btn--primary "
        >
          <img src={images} alt="Play_Pause" width="25px" />
        </button> */}
      </div>
    </>
  );
}

export default VideoBlock;

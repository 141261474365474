import React from "react";
import { Range, getTrackBackground } from "react-range";
import "./Calculator.css";

const STEP = 2;
const MIN = 0;
const MAX = 200;
const STEP1 = 200;
const MIN1 = 0;
const MAX1 = 20000;
const STEP2 = 1;
const MIN2 = 0;
const MAX2 = 100;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      values: [0],
      values1: [0],
      values2: [0],
      numberOfVehicles: [0],
      volume: [0],
      totalSavings: [0],
      updatetotalsaving: [0],
      averageAnnualSavings: [0],
      updateaverageannualsaving: [0],
      values3: [0],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChange1 = this.handleChange1.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.handleChange3 = this.handleChange3.bind(this);
    this.reset = this.reset.bind(this);
  }
  handleChange(e) {
    this.setState({ values: e });
    this.setState({ numberOfVehicles: e / 2 }, () => this.updatedata());
  }
  handleChange1(e) {
    this.setState({ values1: e });
    this.setState(
      {
        volume: e / 200,
      },
      () => this.updatedata()
    );
  }

  handleChange2(e) {
    this.setState({ values2: e }, () => this.updatedata());
  }

  handleChange3(e) {
    var s = e.target.value;
    setTimeout(() => {
      if (s > 200) {
        return;
      }
      this.setState({ values: [s] }, () => this.updatedata());
    }, 0);
  }

  handleChange4(e) {
    var t = e.target.value;
    setTimeout(() => {
      if (t > 20000) {
        return;
      }
      this.setState({ values1: [t] }, () => this.updatedata());
    }, 0);
  }

  handleChange5(e) {
    var w = e.target.value;
    setTimeout(() => {
      if (w > 100) {
        return;
      }
      this.setState({ values2: [w] }, () => this.updatedata());
    }, 0);
  }
  updatedata() {
    const totalSavings = this.state.values * this.state.values2 * 4 * 12;
    const averageAnnualSavings = (totalSavings * 12) / 100;

    this.setState(
      {
        totalSavings,
        updatetotalsaving: Math.round(totalSavings).toLocaleString(),
        averageAnnualSavings: Math.round(averageAnnualSavings).toLocaleString(),
      },
      () => {}
    );
  }

  reset() {
    this.setState({
      updatetotalsaving: "0",
      averageAnnualSavings: "0",
      values: [0],
      values1: [0],
      values2: [0],
    });
  }
  render() {
    return (
      <section className="common-section-all pb-0">
        <div className="container">
          <div className="heading-style text-center wow fadeInUp">
            <h5>
              Saving <span className="text-orange"> Calculator</span>
            </h5>
            <p>Enter amounts below to calculate your savings.</p>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="calcu-wrapper">
                <div className="row">
                  <div className="col-lg-7">
                    <div className="frame">
                      <div className="center">
                        <div
                          className="slider-item"
                          style={{ marginBottom: "50px" }}
                        >
                          <h6>Number of vehicles in your fleet</h6>
                          <div className="rang-outer">
                            <div className="d-flex align-items-center justify-content-between">
                              <span className="t-20"></span>
                              <input
                                id=""
                                className="input-control"
                                type="text"
                                value={this.state.values}
                                onChange={(e) => this.handleChange3(e)}
                              />
                            </div>
                          </div>
                          <Range
                            values={this.state.values}
                            step={STEP}
                            min={MIN}
                            max={MAX}
                            onChange={(e) => this.handleChange(e)}
                            renderTrack={({ props, children }) => (
                              <div
                                onMouseDown={props.onMouseDown}
                                onTouchStart={props.onTouchStart}
                                style={{
                                  ...props.style,
                                  height: "36px",
                                  display: "flex",
                                  width: "100%",
                                }}
                              >
                                <div
                                  ref={props.ref}
                                  style={{
                                    height: "3px",
                                    width: "100%",
                                    borderRadius: "4px",
                                    background: getTrackBackground({
                                      values: this.state.values,
                                      colors: [
                                        "#ff7900",
                                        this.props.theme === "dark"
                                          ? "rgba(255, 255, 255, 0.11)"
                                          : "rgba(0, 0, 0, 0.11)",
                                      ],
                                      min: MIN,
                                      max: MAX,
                                    }),
                                    alignSelf: "center",
                                  }}
                                >
                                  {children}
                                </div>
                              </div>
                            )}
                            renderThumb={({ props, isDragged }) => (
                              <div
                                {...props}
                                style={{
                                  ...props.style,
                                  height: "20px",
                                  width: "20px",
                                  borderRadius: "50px",
                                  backgroundColor: "#ff7900",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    height: "25px",
                                    width: "12px",
                                    backgroundColor: isDragged
                                      ? "#548BF4"
                                      : "#CCC",
                                  }}
                                />
                                <output id="output">
                                  {this.state.values[0] !== ""
                                    ? this.state.values
                                    : 0}
                                </output>
                              </div>
                            )}
                          />
                        </div>
                        <div
                          className="slider-item"
                          style={{ marginBottom: "50px" }}
                        >
                          <h6>
                            Total volume (gallons) of fuel purchased per month
                          </h6>
                          <div className="rang-outer">
                            <div className="d-flex align-items-center justify-content-between">
                              <span className="t-20"></span>
                              <input
                                id=""
                                className="input-control"
                                type="text"
                                value={this.state.values1}
                                onChange={(e) => this.handleChange4(e)}
                              />
                            </div>
                          </div>

                          <Range
                            values={this.state.values1}
                            step={STEP1}
                            min={MIN1}
                            max={MAX1}
                            onChange={(e) => this.handleChange1(e)}
                            renderTrack={({ props, children }) => (
                              <div
                                onMouseDown={props.onMouseDown}
                                onTouchStart={props.onTouchStart}
                                style={{
                                  ...props.style,
                                  height: "36px",
                                  display: "flex",
                                  width: "100%",
                                }}
                              >
                                <div
                                  ref={props.ref}
                                  style={{
                                    height: "3px",
                                    width: "100%",
                                    borderRadius: "4px",
                                    background: getTrackBackground({
                                      values: this.state.values1,
                                      colors: [
                                        "#ff7900",
                                        this.props.theme === "dark"
                                          ? "rgba(255, 255, 255, 0.11)"
                                          : "rgba(0, 0, 0, 0.11)",
                                      ],
                                      min: MIN1,
                                      max: MAX1,
                                    }),
                                    alignSelf: "center",
                                  }}
                                >
                                  {children}
                                </div>
                              </div>
                            )}
                            renderThumb={({ props, isDragged }) => (
                              <div
                                {...props}
                                style={{
                                  ...props.style,
                                  height: "20px",
                                  width: "20px",
                                  borderRadius: "50px",
                                  backgroundColor: "#ff7900",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    height: "25px",
                                    width: "12px",
                                    backgroundColor: isDragged
                                      ? "#548BF4"
                                      : "#CCC",
                                  }}
                                />
                                <output id="output">
                                  {this.state.values1[0] !== ""
                                    ? this.state.values1
                                    : 0}
                                </output>
                              </div>
                            )}
                          />
                        </div>
                        <div
                          className="slider-item"
                          style={{ marginBottom: "50px" }}
                        >
                          <h6>Number of times each vehicle refuels per week</h6>
                          <div className="rang-outer">
                            <div className="d-flex align-items-center justify-content-between">
                              <span className="t-20"></span>
                              <input
                                id=""
                                className="input-control"
                                type="text"
                                value={this.state.values2}
                                onChange={(e) => this.handleChange5(e)}
                              />
                            </div>
                            <Range
                              values={this.state.values2}
                              step={STEP2}
                              min={MIN2}
                              max={MAX2}
                              onChange={(e) => this.handleChange2(e)}
                              renderTrack={({ props, children }) => (
                                <div
                                  onMouseDown={props.onMouseDown}
                                  onTouchStart={props.onTouchStart}
                                  style={{
                                    ...props.style,
                                    height: "36px",
                                    display: "flex",
                                    width: "100%",
                                  }}
                                >
                                  <div
                                    ref={props.ref}
                                    style={{
                                      height: "3px",
                                      width: "100%",
                                      borderRadius: "4px",
                                      background: getTrackBackground({
                                        values: this.state.values2,
                                        colors: [
                                          "#ff7900",
                                          this.props.theme === "dark"
                                            ? "rgba(255, 255, 255, 0.11)"
                                            : "rgba(0, 0, 0, 0.11)",
                                        ],
                                        min: MIN2,
                                        max: MAX2,
                                      }),
                                      alignSelf: "center",
                                    }}
                                  >
                                    {children}
                                  </div>
                                </div>
                              )}
                              renderThumb={({ props, isDragged }) => (
                                <div
                                  {...props}
                                  style={{
                                    ...props.style,
                                    height: "20px",
                                    width: "20px",
                                    borderRadius: "50px",
                                    backgroundColor: "#ff7900",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      height: "25px",
                                      width: "12px",
                                      backgroundColor: isDragged
                                        ? "#548BF4"
                                        : "#CCC",
                                    }}
                                  />
                                  <output id="output">
                                    {this.state.values2[0] !== ""
                                      ? this.state.values2
                                      : 0}
                                  </output>
                                </div>
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 mt-3 mt-lg-0">
                    <div className="annual-saving justify-content-between">
                      <h4 className="text-center border-0 pb-4 w-100">
                        Estimated Annual Savings
                      </h4>
                      <div className="row">
                        <div className="col-lg-6 mb-3 mb-lg-0 text-center borderend pb-4 pb-lg-0 mb-4 mb-lg-0">
                          <div className="f-12">
                            TOTAL <br />
                            SAVINGS
                          </div>
                          <div className="f-34">
                            {this.state.updatetotalsaving}
                          </div>
                          <p className="f-12">
                            Assumes a $52/hour labor cost (includes wages and
                            benefits)
                          </p>
                        </div>
                        <div className="col-lg-6 mb-3 mb-lg-0 text-center">
                          <div className="f-12">
                            AVERAGE ANNUAL <br />
                            SAVINGS
                          </div>
                          <div className="f-34 text-orange">
                            {this.state.averageAnnualSavings}
                          </div>
                          <p className="f-12">Per Vehicle</p>
                        </div>
                      </div>
                      <button
                        className="btn btn--primary px-5"
                        href="#"
                        onClick={this.reset}
                      >
                        <span>Re-Calculate Fuel Savings</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default App;

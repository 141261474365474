import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import BreadCrumb from "../BreadCrumb/BreadCrumb";
import Contact from "../Contactus/Contact";
import axios from "axios";
import moment from "moment";
import InstagramFeed from "react-ig-feed";
import {
  careersbanner,

  calendar,
  newsbanner,
  facebookicon,
  twittericon,
  instaicon,
  leadershipcontactbanner,
  moblogo,
} from "../Imagesjs/Images";
import "./News.css";

function News() {
  const pagename = {
    name: "News",
  };
  const contactbanner = leadershipcontactbanner;

  const [data, setData] = useState([]);
  const [upcomingblog, setupcomingblog] = useState([]);
  const [relatedblog, setuprelatedblog] = useState([]);
  const [links, setlinks] = useState("Related Articles");
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_base_KEY}/getData2?flag=4&limit=9&isPublished=true&isArticle=true`
      )
      .then((res) => {
        setuprelatedblog(res.data.data.data);
      })
      .catch((err) => { });
  }, []);

  const tabselection = (index) => {
    setlinks(index);

    if (index === "Related Articles") {
      axios
        .get(
          `${process.env.REACT_APP_API_base_KEY}/getData2?flag=4&limit=9&isPublished=true&isArticle=true`
        )
        .then((res) => {
          setuprelatedblog(res.data.data.data);
        })
        .catch((err) => { });
    }
    if (index === "Blog Posts") {
      axios
        .get(
          `${process.env.REACT_APP_API_base_KEY}/getData2?flag=4&limit=9&isPublished=true`
        )
        .then((res) => {
          setData(res.data.data.data);
        })
        .catch((err) => { });
    }
    if (index === "Upcoming Events") {
      axios
        .get(
          `${process.env.REACT_APP_API_base_KEY}/getData2?flag=4&limit=9&isPublished=false`
        )
        .then((res) => {
          setupcomingblog(res.data.data.data);
        })
        .catch((err) => { });
    }
  };
  return (
    <>
      <section className="inner-banner section-banner faq">
        <div className="background-image">
          <img src={careersbanner} alt="careers banner" />
        </div>
        <div className="container position-relative">
          <div className="row align-items-lg-center align-items-end">
            <div className="col-12  text-center">
              <BreadCrumb pagename={pagename} />
            </div>
            <div className="col-12">
              <div className="home-banner-texter text-center">
                <h1 className="wow fadeInUp" data-wow-delay="0.2s">
                  <span>News </span>
                </h1>
                <p className="wow fadeInUp" data-wow-delay="0.3s">
                  Read more about Gas It Up Energy Innovation in the press,
                  blogs, events, and
                  <br /> more, below!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all pb-0">
        <div className="container">
          <div className="fueling-details wow fadeInUp">
            <div className="row align-items-center gx-0">
              <div className="col-md-6 col-12 ps-lg-5">
                <div className="heading-style text-lg-start text-center px-lg-5 mb-0">
                  <h5
                    className="text-lg-start text-center text-capitalize wow fadeInUp"
                    data-wow-delay="0.1s"
                  >
                    <span className="text-white">Fueling</span>
                    <span className="text-orange">
                      SUCCESS <br />
                    </span>
                  </h5>
                  <p>Creating a better enviornment for our communities</p>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <figure>
                  <img
                    src={newsbanner}
                    alt="newsbanner"
                    className="img-fluid"
                    width="100%"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common-section-all blog-details tabing-list">
        <div className="container">
          <div className="heading-style text-center wow fadeInUp">
            <h5>
              Beyond The
              <span className="text-orange"> Tank</span>
            </h5>
            <p>
              We strive to change the way you think of fueling and inspire our peers through the technology Gas It Up <br />has created. Read more about GIU news, upcoming events, blogs, and more.

            </p>
          </div>
          <div className="row align-items-lg-center align-items-end">
            <Tabs
              defaultActiveKey="Related Articles"
              id="uncontrolled-tab-example"
              className="mb-5 pb-0 wow fadeInUp"
              onSelect={(index) => tabselection(index)}
            >

              <Tab eventKey="Related Articles" title="Related Articles">
                <div className="row align-items-start justify-content-center">
                  {relatedblog.map((NewsList, i) => (
                    <div className="blogblock col-md-4 mb-5" key={i}>
                      <figure>
                        {NewsList.imageUrl.thumbnail &&
                          (NewsList.imageUrl.thumbnail
                            .split(/[#?]/)[0]
                            .split(".")
                            .pop()
                            .trim() === "jpg" ||
                            NewsList.imageUrl.thumbnail
                              .split(/[#?]/)[0]
                              .split(".")
                              .pop()
                              .trim() === "jpeg" ||
                            NewsList.imageUrl.thumbnail
                              .split(/[#?]/)[0]
                              .split(".")
                              .pop()
                              .trim() === "png") ? (
                          <a href={NewsList.articleUrl} target="_blank" rel="noopener noreferrer"> <img
                            src={NewsList.imageUrl.thumbnail}
                            alt="NewsList"
                            className="img-fluid w-100"
                          /></a>
                        ) : (
                          <iframe
                            width="100%"
                            height="250"
                            src={NewsList.imageUrl.thumbnail}
                            frameBorder="0"
                            allowFullScreen={true}
                            title="image"
                          ></iframe>
                        )}
                      </figure>

                      <h4 ><a href={NewsList.articleUrl} target="_blank" rel="noopener noreferrer">{NewsList.title}  </a></h4>
                      <div className="meta-details">
                        <div className="row align-items-center justify-content-between">
                          <div className="col-md-6 admin-details">
                            <i>
                              <img src={moblogo} alt="client" />
                            </i>
                            <span>
                              Posted By:
                              <span className="text-orange"> Admin</span>
                            </span>
                          </div>
                          <div className="col-md-6 posted-details d-flex align-items-center justify-content-end text-end">
                            <i>
                              <img src={calendar} alt="calendar" />
                            </i>
                            <span>
                              {moment(NewsList?.publishedBy).format(
                                "MM/DD/YYYY"
                              )
                                ? moment(NewsList?.publishedBy).format(
                                  "MM/DD/YYYY"
                                )
                                : moment(NewsList?.createdOn).format(
                                  "MM/DD/YYYY"
                                )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <p className="pt-3">
                        {NewsList.subTitle.substring(0, 121) + "...."}
                      </p>
                      <a
                        href={NewsList.articleUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn--primary--outline d-inline-flex px-4"

                      >
                        <span>Read More</span>
                      </a>
                    </div>
                  ))}
                  {!relatedblog.length ? <span class="text-center">Coming Soon</span> : null}
                </div>
              </Tab>

              <Tab eventKey="Upcoming Events" title="Upcoming Events">
                <div className="row align-items-start justify-content-center">
                  {upcomingblog.map((NewsList, i) => (
                    <div
                      className="wow fadeInUp blogblock col-md-4 mb-lg-5 mb-3"
                      data-wow-delay={`0.` + i + `s`}
                      key={i}
                    >
                      <figure>
                        <img
                          src={NewsList.imageUrl.thumbnail}
                          alt="NewsList banner"
                          className="img-fluid w-100"
                        />
                      </figure>
                      <h4>{NewsList.title}</h4>
                      <div className="meta-details">
                        <div className="row align-items-center justify-content-between">
                          <div className="col-6 admin-details">
                            <i>
                              <img src={moblogo} alt="client" />
                            </i>
                            <span>
                              Posted By:
                              <span className="text-orange"> Admin</span>
                            </span>
                          </div>
                          <div className="col-6 posted-details  d-flex align-items-center justify-content-end text-end">
                            <i className="d-flex">
                              <img src={calendar} alt="calendar" />
                            </i>
                            <span>
                              {moment(NewsList.createdOn).format("MM/DD/YYYY")}
                            </span>
                          </div>
                        </div>
                      </div>
                      <p className="pt-3">
                        {/* {NewsList.description.substring(0, 121) + "...."} */}
                        {NewsList.subTitle.substring(0, 121) + "...."}
                      </p>
                      {/* <Link
                        to={`/blogdetail/${NewsList._id}`}
                        className="btn btn--primary--outline d-inline-flex px-4"
                      >
                        <span>Read More</span>
                      </Link> */}
                    </div>
                  ))}
                  <div className="heading-style mb-0">
                    {!upcomingblog.length ? <p class="text-center">Coming Soon</p> : null}
                  </div>

                </div>
              </Tab>
              <Tab eventKey="Blog Posts" title="Blog Posts">
                <div className="row align-items-start justify-content-center">
                  {data.map((NewsList, i) => (
                    <div className="blogblock col-md-4 mb-5" key={i}>
                      <figure>
                        <img
                          src={NewsList.imageUrl.thumbnail}
                          alt="NewsList"
                          className="img-fluid w-100"
                        />
                      </figure>
                      <h4>{NewsList.title}</h4>
                      <div className="meta-details">
                        <div className="row align-items-center justify-content-between">
                          <div className="col-md-6 admin-details">
                            <i>
                              <img src={moblogo} alt="client" />
                            </i>
                            <span>
                              Posted By:
                              <span className="text-orange"> Admin</span>
                            </span>
                          </div>
                          <div className="col-md-6 posted-details d-flex align-items-center justify-content-end text-end">
                            <i>
                              <img src={calendar} alt="calendar" />
                            </i>
                            <span>
                              {moment(NewsList.createdOn).format("MM/DD/YYYY")}
                            </span>
                          </div>
                        </div>
                      </div>
                      <p className="pt-3">
                        {NewsList.subTitle.substring(0, 121) + "...."}
                      </p>
                      <Link
                        to={`/blogdetail/${NewsList._id}`}
                        className="btn btn--primary--outline d-inline-flex px-4"
                      >
                        <span>Read More</span>
                      </Link>
                    </div>
                  ))}
                  {!data.length ? <span class="text-center">Coming Soon</span> : null}
                </div>
              </Tab>
            </Tabs>
          </div>
          {upcomingblog.length >= 9 && links === 'Upcoming Events' && <div className="text-center wow fadeInUp" data-wow-delay="0.2s">
            <Link to="blog?sort=upcoming" className="d-inline-flex btn btn--primary px-5">
              <span>View More</span>
            </Link>
          </div>}
          {relatedblog.length >= 9 && links === 'Related Articles' && <div className="text-center wow fadeInUp" data-wow-delay="0.2s">
            <Link to="blog?sort=related" className="d-inline-flex btn btn--primary px-5">
              <span>View More</span>
            </Link>
          </div>}
          {data.length >= 9 && links === 'Blog Posts' && <div className="text-center wow fadeInUp" data-wow-delay="0.2s">
            <Link to="blog?sort=blog" className="d-inline-flex btn btn--primary px-5">
              <span>View More</span>
            </Link>
          </div>}
        </div>
      </section>
      <section className="common-section-all blog-details tabing-list pt-0">
        <div className="container">
          <div className="heading-style text-center wow fadeInUp">
            <h5>
              Stay <span className="text-orange"> Connected</span>. Get{" "}
              <span className="text-orange"> Pumped</span>.
            </h5>
            <p>
              Follow our social platforms to stay updated on discounts, customer
              rewards, app updates, events, <br />
              fuel facts & tips, and more!
            </p>
          </div>
          <div className="social-fields">
            <ul className="row align-items-center ">
              <li className="col-md-4 col-12 mb-md-0 mb-3">
                <div className="new-brd">
                  <div className="social-head">
                    <h3>
                      <i>
                        <img src={facebookicon} alt="facebook" />
                      </i>
                      Facebook
                    </h3>
                  </div>
                  <div className="social-body">
                    <iframe
                      src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FGasItUpFuel&tabs=timeline&width=415&height=550&small_header=true&adapt_container_width=true&hide_cover=true&show_facepile=true&appId=398868972298475"
                      width="415"
                      height="415"
                      title="Facebook fields"
                      scrolling="no"
                      frameBorder="0"
                      allowFullScreen={true}
                      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                    ></iframe>
                  </div>
                </div>
              </li>
              <li className="col-md-4 col-12 mb-md-0 mb-3">
                <div className="new-brd">
                  <div className="social-head">
                    <h3>
                      <i>
                        <img src={twittericon} alt="twitter" />
                      </i>
                      Twitter
                    </h3>
                  </div>
                  <div className="social-body">
                    <iframe
                      src="https://syndication.twitter.com/srv/timeline-profile/screen-name/GasItUp_"
                      width="100%"
                      height="415px"
                      title="Twitter fields"
                    ></iframe>
                  </div>
                </div>
              </li>
              <li className="col-md-4 col-12 mb-md-0 mb-3">
                <div className="new-brd instagram-feed">
                  <div className="social-head">
                    <h3>
                      <i>
                        <img src={instaicon} alt="insta" />
                      </i>
                      Instagram
                    </h3>
                  </div>

                  <div className="social-body">
                    <div
                      style={{
                        width: "100%",
                        height: "415px",
                        overflow: "auto",
                      }}
                    >
                      <InstagramFeed
                        token="IGQVJXSmZAOZAkM5WmsyOVlFRHBJdWIwWFk3dDVzZA2hacUJKdWR0c19Wai1IVVhmbFpjc1MxNVBiWGZAOc2pfQVZA2OHA1VWlqZAk"
                        counter="6"
                        width="100%"
                        height="415px"
                      />
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="common-section-all pt-0">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 pb-0">
              <div className="heading-style text-center wow fadeInUp">
                <h5 className="text-capitalize">
                  Get into contact with a{" "}
                  <span className="text-orange">Gas It Up</span> professional
                  that
                  <br /> can get your{" "}
                  <span className="text-orange"> business</span> set up for{" "}
                  <span className="text-orange"> success</span>
                </h5>
              </div>
            </div>
            <Contact contactbanner={contactbanner} />
          </div>
        </div>
      </section>
    </>
  );
}

export default News;
